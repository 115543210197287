import { ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { createLogger } from '@/utils/log';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { isSucceeded, useGetNotifications, useToggleNotificationRead } from '../../../usecases';
import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import { clearDialogQuery } from '../../utils/DialogQueryUtils';
import { BaseDialogConfirm } from '../molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '../molecules/BaseDialogFullScreenComposable';
import { DialogAnchorConfirm } from './DialogAnchorConfirmComposable';
import {
  NotificationItem,
  NotificationsClickAnchorPayload,
  NotificationsMovePayload,
} from './NotificationsComposable';

const logger = createLogger({ boundedContext: 'base', name: 'DialogNotifications' });

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  return { dialog, dialogFullScreen, opened };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: NotificationsClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export type DialogNotificationsTarget = Optional<{
  id: string;
}>;

export type PropsDialogNotifications = {
  adminMode: boolean;
};

export function useDialogNotifications(
  props: PropsDialogNotifications,
  emit: (name: string, args: boolean) => void
) {
  const msgs = useMessages({ prefix: 'base.organisms.dialogNotifications' });

  const { notificationTimer } = useGlobalStore();
  const router = useRouter();
  const route = useRoute();

  const { confirmDialog, confirm } = useConfirmDialog();

  const { dialog, dialogFullScreen, opened } = useDialogFullScreen();
  const loading = ref(false);
  const notifications = ref<NotificationItem[]>([]);
  const unReads = ref<{ id: string; visible: boolean }[]>([]);

  const getNotifications = useGetNotifications();
  async function fetch() {
    notifications.value = [];
    loading.value = true;
    const res = await getNotifications.execute({});
    if (isSucceeded(res)) {
      notifications.value = res.notifications.map((notification) => ({
        notification,
        hide: false,
      }));
      unReads.value = res.notifications
        .filter((n) => n.read === 'unread')
        .map((n) => ({ id: n.id, visible: false }));
    }
    loading.value = false;
  }

  const toggleNotificationRead = useToggleNotificationRead();
  async function readNotification(id: string) {
    const res = await toggleNotificationRead.execute({ id });
    if (isSucceeded(res)) {
      const index = unReads.value.findIndex((read) => read.id);
      if (index !== -1) unReads.value.splice(index, 1);
    }
  }

  const area = ref<Element>();
  function onScroll() {
    const ids = unReads.value.filter((n) => !n.visible).map((n) => n.id);
    if (ids.length === 0) return;
    assertIsDefined(area.value);

    const areaRect = area.value.getBoundingClientRect();
    Array.from(area.value.getElementsByClassName('base-notification-bar--unread'))
      .filter((el) => {
        if (!ids.includes(el.id)) return false;
        const { top } = el.getBoundingClientRect();
        return areaRect.top <= top && top <= areaRect.top + areaRect.height;
      })
      .forEach((el) => {
        const unread = unReads.value.find((item) => item.id === el.id);
        if (unread) {
          unread.visible = true;
          readNotification(unread.id);
        }
      });
  }

  function findAndScroll(id: string) {
    const e = document.getElementById(id);
    if (!e) return;
    e.scrollIntoView();
  }

  function close() {
    dialog.value = { display: false };
    notificationTimer.fetch();
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  async function open(target: DialogNotificationsTarget) {
    dialog.value = { display: true };
    await fetch();
    if (target) {
      useTimeoutFn(() => findAndScroll(target.id), 1000);
    } else {
      useTimeoutFn(onScroll, 1000);
    }
  }

  function next(payload: NotificationsMovePayload) {
    close();
    useTimeoutFn(() => {
      router.push(payload.to, undefined, (e) => {
        logger.debug({ message: e.message });
      });
    }, 1000);
  }

  function move(payload: NotificationsMovePayload) {
    if (props.adminMode) {
      confirm(msgs.of('confirmChangeAdminMode').value, () => {
        emit('change-admin-mode', false);
        next(payload);
      });
      return;
    }
    next(payload);
  }

  return {
    dialogFullScreen,
    confirmDialog,
    dialog,
    loading,
    notifications,
    area,
    labelNotification: msgs.of('notification'),
    onScroll,
    close,
    open,
    opened,
    move,
    refresh: fetch,
    ...useAnchorDialog(),
  };
}

export type DialogNotifications = ReturnType<typeof useDialogNotifications>;
