


















import { defineComponent, SetupContext } from '@vue/composition-api';

import { PropsMenuWrapper, useMenuWrapper } from './MenuWrapperComposable';

type Props = PropsMenuWrapper;

export default defineComponent({
  name: 'BaseMenuWrapper',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, default: undefined },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useMenuWrapper(props, emit);
  },
});
