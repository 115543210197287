




















































import { defineComponent, PropType } from '@vue/composition-api';

import { Role } from '@/base/domains';

import { GlobalStoreLimitationName } from '../../store/types';
import LogoButton from '../atoms/LogoButton.vue';
import {
  FrameMainMenuListAssignedGroup,
  FrameMainMenuListGroup,
  PropsFrameMainMenuList,
  useFrameMainMenuList,
} from './FrameMainMenuListComposable';

type Props = PropsFrameMainMenuList;

export default defineComponent({
  name: 'BaseFrameMainMenuList',
  components: { LogoButton },
  props: {
    tenantName: { type: String, required: true },
    version: { type: String, required: true },
    menusAvailable: { type: Array as PropType<GlobalStoreLimitationName[]>, default: () => [] },
    role: { type: String as PropType<Role>, default: 'general' },
    group: { type: Object as PropType<FrameMainMenuListGroup>, default: undefined },
    adminMode: { type: Boolean, default: false },
    assignedGroups: { type: Array as PropType<FrameMainMenuListAssignedGroup[]>, required: true },
  },
  setup(props: Props) {
    return useFrameMainMenuList(props);
  },
});
