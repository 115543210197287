

































import { defineComponent, PropType } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useRoute, useRouter } from '@/utils/VueUtils';

import { useColor } from '../../utils/ColorUtils';

type Props = {
  go: number | undefined;
  to?: Location;
};

export default defineComponent({
  name: 'BaseLogoButton',
  inheritAttrs: false,
  props: {
    icon: { type: Boolean, default: false },
    go: { type: Number, default: undefined },
    to: { type: Object as PropType<Location>, default: undefined },
    height: { type: [String, Number], default: 96 },
    size: { type: Number, default: 34 },
  },
  setup(props: Props) {
    const router = useRouter();
    const route = useRoute();
    function move() {
      if (props.go !== undefined) router.go(props.go);
      else if (props.to !== undefined) router.push(props.to);
      else if (route.name !== 'home') router.push({ name: 'home' });
    }
    const { dark } = useColor();
    return { move, dark };
  },
});
