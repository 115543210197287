import { computed, ref } from '@vue/composition-api';

import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { QuestionnaireByAnswerer, QuestionnaireId } from '../../../domains';
import { isFailed, useAnswerQuestionnaire, useGetQuestionnaireByAnswerer } from '../../../usecases';
import { useMessages } from '../..';
import { useGlobalStore } from '../../store';
import { clearDialogQuery } from '../../utils/DialogQueryUtils';
import { toQuestionnaireOptions } from '../../utils/QuestionnaireUtils';
import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';
import { BaseDialogConfirm } from '../molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '../molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '../molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from './DialogAnchorConfirmComposable';

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  return { dialogFullScreen, dialog, opened, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export type DialogQuestionnaireAnswerTarget = {
  id: QuestionnaireId;
};

export function useDialogQuestionnaireAnswer() {
  const msgs = useMessages({ prefix: 'base.organisms.dialogQuestionnaireAnswer' });
  const { findGroup, findUser, checkLimitation } = useGlobalStore();
  const route = useRoute();
  const router = useRouter();

  const { dialogFullScreen, dialog, opened, error } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const questionnaire = ref<QuestionnaireByAnswerer>();
  const selected = ref<number>(0);
  const pageLoading = ref(false);
  const buttonLoading = ref(false);

  const options = computed(() => {
    if (!questionnaire.value) return [];
    return toQuestionnaireOptions(questionnaire.value.options);
  });

  function close(answered = false) {
    dialog.value = { display: false };
    questionnaire.value = undefined;
    selected.value = 0;
    const to = clearDialogQuery(route);
    if (!to) return;
    if (answered && (to.name === 'home' || to.name === 'groupQuestionnaires')) {
      to.query = to.query ? { ...to.query, f: '' } : { f: '' };
    }
    router.replace(to);
  }

  const getQuestionnaire = useGetQuestionnaireByAnswerer();
  async function fetch(target: DialogQuestionnaireAnswerTarget) {
    const res = await getQuestionnaire.execute({ id: target.id });
    if (isFailed(res) || !res.questionnaire) {
      error([msgs.of('noData').value]);
      close();
      return;
    }
    const { questionnaire: q } = res;
    if (q.answered) {
      error([msgs.of('alreadyAnswered', { title: res.questionnaire.title }).value]);
      close(true);
      return;
    }
    questionnaire.value = q;
  }

  function validateLimitation(gId: string) {
    return checkLimitation('questionnaire', false, gId) === 'enabled';
  }

  async function open(target: DialogQuestionnaireAnswerTarget) {
    dialog.value = { display: true };
    pageLoading.value = true;
    await fetch(target);
    pageLoading.value = false;
    if (!questionnaire.value) return;
    if (!validateLimitation(questionnaire.value.groupId)) close();
  }

  const answerQuestionnaire = useAnswerQuestionnaire();
  async function submit() {
    assertIsDefined(questionnaire.value, 'questionnaire');
    const { id: questionnaireId, title } = questionnaire.value;
    confirm(msgs.of('confirmSubmit', { title }).value, async () => {
      buttonLoading.value = true;
      const res = await answerQuestionnaire.execute({
        questionnaireId,
        selectedIndex: selected.value,
      });
      buttonLoading.value = false;
      if (isFailed(res)) {
        error(res.errors);
        return;
      }
      close(true);
    });
  }

  function change(index: number) {
    selected.value = index;
  }

  const title = computed(() => {
    const id = questionnaire.value?.groupId;
    if (!id) return '';
    const group = findGroup(id);
    if (!group) return '';
    return msgs.of('title', { groupName: group.name }).value;
  });

  const createdBy = computed(() => {
    const id = questionnaire.value?.createdBy;
    if (!id) return undefined;
    return findUser(id)?.name ?? msgs.of('unknown', { id }).value;
  });

  return {
    dialog,
    dialogFullScreen,
    confirmDialog,
    pageLoading,
    buttonLoading,
    selected,
    title,
    questionnaire,
    createdBy,
    options,
    labelClose: msgs.of('close'),
    labelSubmit: msgs.of('submit'),
    close,
    open,
    opened,
    submit,
    change,
    ...useAnchorDialog(),
  };
}

export type DialogQuestionnaireAnswer = ReturnType<typeof useDialogQuestionnaireAnswer>;
