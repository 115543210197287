




























import { computed, defineComponent, PropType } from '@vue/composition-api';

import { useMessages } from '../../Messages';

type ColorItem = {
  value: string;
  label: string;
  color: string;
};

type Props = {
  value?: string;
  label?: string;
  items: ColorItem[];
  itemValue: string;
};

export default defineComponent({
  name: 'BaseColorSelect',
  inheritAttrs: true,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    label: { type: String, default: undefined },
    items: { type: Array as PropType<ColorItem[]>, default: () => [] },
    itemValue: { type: String, default: 'value' },
  },
  emits: ['change'],
  setup(props: Props) {
    function toColor(value: string) {
      const color = props.items.find((item) => item.value === value);
      return color?.color;
    }
    const msgs = useMessages({ prefix: 'base.atoms.colorSelect' });
    const veeName = computed(() => props.label || msgs.of('defaultLabel').value);
    return { toColor, veeName };
  },
});
