import { Optional } from '@/base/types';
import { config } from '@/config';
import { requestDownload } from '@/utils/AndroidUtils';
import { createLogger } from '@/utils/log';

const logger = createLogger({ boundedContext: 'base', name: 'FileUtils' });

const DOWNLOAD_EXTENSIONS = ['zip', 'txt', 'csv', 'log', 'json'];

type FileAttribute = {
  url: URL;
  fileName: string;
  extension: string;
  inFilesServer: boolean;
  download: boolean;
};

export function tryUrl(s: string, base?: string | URL) {
  try {
    return new URL(s, base);
  } catch (e) {
    if (e instanceof TypeError) return undefined;
    throw e;
  }
}

function validateHost(url: URL): boolean {
  const { hostname } = window.location;
  const hostnameFiles = hostname.replace(/^app\./, 'files.');
  return (
    url.hostname === hostnameFiles ||
    (config().local && url.hostname.startsWith('files.education.knowte'))
  );
}

export function getFileAttrs(url: URL): Optional<FileAttribute> {
  const p = url.pathname.split(/[#?]/)[0];
  if (!p) return undefined;
  const filename = p.split('/').pop();
  const extension = filename?.split('.').slice(-1)[0].trim().toLocaleLowerCase();
  if (!filename || !extension) return undefined;
  return {
    url,
    fileName: decodeURI(filename),
    extension,
    inFilesServer: validateHost(url),
    download: DOWNLOAD_EXTENSIONS.includes(extension),
  };
}

export async function downloadFromFilesServer({ url, fileName, inFilesServer }: FileAttribute) {
  logger.debug({ message: 'download start', url: url.href, fileName });

  if (!inFilesServer) {
    logger.debug({ message: 'download end. invalid host.', host: url.hostname });
    return false;
  }

  if (window.$androidWebAppBridge) {
    return requestDownload(url.toString());
  }

  const downloadUrl = await fetch(url.href, {
    mode: 'cors',
    credentials: 'include',
  })
    .then((r) => {
      if (!r.ok) throw new Error(`response status=${r.status} ${r.statusText} type=${r.type}`);
      return r.blob();
    })
    .then((b) => URL.createObjectURL(b))
    .catch((e) => {
      logger.error({ message: `download fail: ${e.message}`, url: url.href, fileName });
      return undefined;
    });
  if (!downloadUrl) return false;
  const a = document.createElement('a');
  a.download = fileName;
  a.href = downloadUrl;
  a.click();
  URL.revokeObjectURL(downloadUrl);
  logger.debug({ message: 'download end' });
  return true;
}

export async function getImageWidth(file: Blob) {
  return new Promise<number | undefined>((resolve) => {
    if (!file.type.startsWith('image/')) {
      resolve(undefined);
      return;
    }
    const fr = new FileReader();
    fr.onload = () => {
      if (!fr.result || typeof fr.result !== 'string') {
        resolve(undefined);
        return;
      }
      const img = new Image();
      img.onload = () => resolve(img.width);
      img.src = fr.result;
    };
    fr.readAsDataURL(file);
  });
}
