import { useTimeoutFn } from '@vueuse/core';

import { config } from '@/config';

export function waitTransition(fn: () => void): Promise<void> {
  return new Promise<void>((resolve) => {
    if (config().app.transitionDelay <= -1) {
      fn();
      resolve();
      return;
    }
    useTimeoutFn(() => {
      fn();
      resolve();
    }, config().app.transitionDelay * 1000);
  });
}

export function waitTransitionLong(fn: () => void): Promise<void> {
  return new Promise<void>((resolve) => {
    if (config().app.transitionDelayLong <= -1) {
      fn();
      resolve();
      return;
    }
    useTimeoutFn(() => {
      fn();
      resolve();
    }, config().app.transitionDelayLong * 1000);
  });
}
