











































import { computed, defineComponent, nextTick, readonly, watch } from '@vue/composition-api';

import { GroupRole, Role, SignedInUser } from '@/base/domains';
import { Optional } from '@/base/types';
import { signedIn, signedOut } from '@/utils/AndroidUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { createLogger } from '@/utils/log';
import { useLocalStorage, useRoute, useRouter, useSessionStorage } from '@/utils/VueUtils';

import { useGlobalStore } from '../store';
import { CurrentUserStatus } from '../store/UserData';
import { isDialogQueryString } from '../utils/DialogQueryUtils';
import { useVuetify } from '../utils/VuetifyUtils';
import FrameRoot from './organisms/FrameRoot.vue';
import { FrameRootChangePayload, FrameRootValue } from './organisms/FrameRootComposable';

type Props = {
  noAuth: boolean;
  forceNoAuth: boolean;
  forceResetFrame: boolean;
  groupId?: string;
  authRules?: string;
  frameRules?: string;
  limitation?: string;
};

const logger = createLogger({ boundedContext: 'base', name: 'MyApp' });

export default defineComponent({
  name: 'BaseMyApp',
  components: { FrameRoot },
  inheritAttrs: false,
  props: {
    noAuth: { type: Boolean, default: false },
    forceNoAuth: { type: Boolean, default: false },
    forceResetFrame: { type: Boolean, default: false },
    /**
     * globalStoreに設定するグループId
     */
    groupId: { type: String, default: undefined },
    /**
     * コンポーネントが有効になるルール。未設定の場合は、権限のチェックを行わない。
     * チェックNGの場合は、データがありませんが表示され、コンポーネントは無効になる。
     * 現在利用可能なルールは次の通り。
     * - 参照可能なRole supervisor, admin, general
     * - 参照可能なGroupRole trainer, trainee, mentor
     *
     * 複数ある場合は | で区切って書く。OR条件。
     *
     * 例
     * supervisor|admin => supervisorまたはadminのユーザーだけ参照できる
     * trainer|mentor => trainerまたはmentorのユーザーだけ参照できる
     */
    authRules: { type: String, default: undefined },
    /**
     * コンポーネントが有効になるルール。未設定の場合は、モードのチェックを行わない。
     * 現在利用可能なルールは次の通り。
     * - admin
     *
     * 複数ある場合は | で区切って書く。AND条件。
     *
     */
    frameRules: { type: String, default: undefined },
    /**
     * 必要な利用権限の名前
     * 現在利用可能な権限名は次の通り。
     * - question
     * - schedule
     * - questionnaire
     * - tenantTermsOfService
     */
    limitation: { type: String, default: undefined },
  },
  setup(props: Props) {
    const route = useRoute();
    const router = useRouter();

    const {
      currentUserStatus,
      isSignedIn,
      tenant,
      user,
      group,
      tenantDataLoaded,
      changeGroupId,
      onReady,
      onTenantDataFirstFetched,
      isLimitationName,
      checkLimitation,
    } = useGlobalStore();
    const { mobile } = useVuetify();

    const frame = useSessionStorage<Optional<FrameRootValue>>('base.myApp.frameRoot', undefined);
    const latestFrameMode = useLocalStorage<Optional<Omit<FrameRootValue, 'side'>>>(
      'base.myApp.latestFrameMode',
      undefined
    );

    function saveFrame() {
      if (!frame.value) return;
      const v = { mode: frame.value.mode, admin: frame.value.admin };
      latestFrameMode.value = v;
    }

    function changeFrame(v: FrameRootValue | FrameRootChangePayload) {
      const changed = frame.value && (v.mode !== frame.value.mode || v.admin !== frame.value.admin);
      logger.debug({ message: 'changeFrame', newValue: v, oldValue: frame.value, changed });
      frame.value = v;
      saveFrame();
      if (route.name === 'home' || !changed) return;
      if ('keepRoute' in v && v.keepRoute) return;
      nextTick(() => router.push({ name: 'home' }));
    }

    function validateAdminMode(v?: boolean) {
      if (!v) return true;
      if (window.$androidWebAppBridge) return false;
      return user.value?.role === 'supervisor' || user.value?.role === 'admin';
    }

    function resetFrame(force = false) {
      logger.debug({ message: 'resetFrame', force });
      if (!force && frame.value) {
        if (frame.value.mode === 'desktop' && mobile.value)
          changeFrame({ ...frame.value, side: false });
        return;
      }
      if (!force && latestFrameMode.value && validateAdminMode(latestFrameMode.value.admin)) {
        changeFrame({
          side: !mobile.value,
          mode: latestFrameMode.value.mode,
          admin: latestFrameMode.value.admin,
        });
        return;
      }
      changeFrame({
        side: !mobile.value,
        mode: navigator.maxTouchPoints > 0 && mobile.value ? 'mobile' : 'desktop',
        admin:
          !window.$androidWebAppBridge &&
          (user.value?.role === 'supervisor' || user.value?.role === 'admin'),
      });
    }
    watch(
      () => frame.value?.admin,
      (newVal) => {
        if (!validateAdminMode(newVal)) resetFrame(true);
      }
    );

    let lastSignUpType: Optional<string>;

    function redirectRouteQuery(q?: Record<string, string | null | undefined | (string | null)[]>) {
      let query = q;
      if (!query) {
        // サインイン前に確保していたpath(route)にリダイレクト
        const { to: t } = route.query;
        // Androidの場合はdelayしない
        const d = window.$androidWebAppBridge ? '0' : undefined;
        query = { d, t, resetFrame: props.forceResetFrame ? 'true' : undefined };
      }
      router.replace({ name: 'redirect', query }, undefined, (e: Error) => {
        logger.debug({ message: e.message });
      });
    }

    function allocate(newStatus?: CurrentUserStatus, oldStatus?: CurrentUserStatus) {
      logger.debug({
        message: 'allocate',
        newStatus,
        oldStatus,
        routeName: route.name,
        routePath: route.fullPath,
        props,
      });
      switch (newStatus) {
        case 'signedIn':
          resetFrame(props.forceResetFrame);
          changeGroupId(props.groupId ?? '');
          if (tenant.value) {
            const { signUpType } = tenant.value;
            lastSignUpType = signUpType === 'user_code' ? 'code' : 'email';
          }
          if (route.name === 'home') {
            signedIn(user.value?.id ?? '');
            if (!tenantDataLoaded.value) {
              // ホームのリロード時
              const dq = isDialogQueryString(route);
              redirectRouteQuery({ dq });
            }
          } else if (props.forceNoAuth) {
            redirectRouteQuery();
          }
          break;
        case 'notSignedIn':
          resetFrame();
          changeGroupId('');
          if (oldStatus === 'signedIn' && signedOut()) {
            // Androidの場合、signedOutを表示する
            router.push({ name: 'signedOut' });
            return;
          }
          if (!props.noAuth && !props.forceNoAuth && route.name !== 'signIn') {
            let to: Optional<string>;
            if (oldStatus !== 'signedIn') {
              // サインアウト以外の場合にリダイレクト用にpathを確保
              to = JSON.stringify({ path: route.fullPath });
            }
            router.push({ name: 'signIn', query: { type: lastSignUpType, to } });
          }
          break;
        default:
          changeGroupId('');
      }
    }
    onReady(() => allocate(currentUserStatus.value));
    watch(currentUserStatus, allocate);
    watch(
      () => props.groupId,
      (newVal) => changeGroupId(newVal ?? '')
    );

    function isRole(rule: string): rule is Role {
      return ['supervisor', 'admin', 'general'].includes(rule);
    }
    function isGroupRole(rule: string): rule is GroupRole {
      return ['trainer', 'mentor', 'trainee'].includes(rule);
    }

    function validate(rule: string, u: SignedInUser) {
      if (isRole(rule)) {
        return u.role === rule;
      }
      if (isGroupRole(rule)) {
        if (!props.groupId) return false;
        return u.groups.some((g) => g.id === props.groupId && g.role === rule);
      }
      return false;
    }

    function validateFrame(rule: string) {
      if (!frame.value) return false;
      if (rule === 'admin') {
        return frame.value.admin === true;
      }
      return false;
    }

    const visible = computed(() => {
      if (currentUserStatus.value === 'unchecked') return false;
      if (props.noAuth) return true;
      if (props.forceNoAuth) return !isSignedIn.value;
      if (!isSignedIn.value) return false;
      if (props.groupId && props.groupId !== group.value?.id) return false;
      const u = user.value;
      assertIsDefined(u, 'user');
      if (props.authRules) {
        if (!props.authRules.split('|').some((r) => validate(r, u))) return false;
      }
      if (props.frameRules) {
        if (!props.frameRules.split('|').every((r) => validateFrame(r))) return false;
      }
      if (props.limitation && isLimitationName(props.limitation)) {
        const adminMode = frame.value?.admin ?? false;
        if (checkLimitation(props.limitation, adminMode) === 'disabled') return false;
      }
      return true;
    });

    const inPreparation = computed(() => {
      if (currentUserStatus.value === 'unchecked') return true;
      if (props.noAuth || props.forceNoAuth) return false;
      return !tenantDataLoaded.value;
    });

    onTenantDataFirstFetched(() => {
      if (visible.value) return;
      if (route.name === 'signIn' && isSignedIn.value) {
        // allocateより先に処理される場合の対応
        redirectRouteQuery();
        return;
      }
      router.replace({ name: 'notFound' });
    });

    return { visible, inPreparation, frame: readonly(frame), changeFrame };
  },
});
