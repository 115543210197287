var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-table"},[_c('v-row',{staticClass:"mt-1 mb-3",attrs:{"align":"end","no-gutters":""}},[_vm._t("start"),(_vm.showMenus)?_c('v-col',{staticClass:"pr-5",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('base-button',_vm._g(_vm._b({attrs:{"height":"39","disabled":_vm.loading || _vm.menus.length === 0,"data-cy":"base.baseTable.menu"}},'base-button',attrs,false),on),[_c('v-icon',{class:{ 'icon--rotate-half': _vm.menu },attrs:{"size":"24"}},[_vm._v("mdi-menu-down")]),_c('span',{staticClass:"px-5"},[_vm._v(_vm._s(_vm.labelAction))])],1)]}}],null,false,1754144588),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menus),function(m,i){return _c('v-list-item',{key:i,attrs:{"disabled":(m.required && !_vm.required) || (m.single && !_vm.single),"data-cy":("base.baseTable.listItem." + (m.value))},on:{"click":function($event){return _vm.selectMenu(m.value)}}},[_vm._v(_vm._s(m.label))])}),1)],1)],1):_vm._e(),_vm._t("center"),(_vm.showSearch)?_c('v-col',{staticClass:"d-none d-md-flex pr-5",attrs:{"cols":"4"}},[_c('base-text',{attrs:{"placeholder":_vm.labelSearch,"maxlength":"100","height":"40","prepend-inner-icon":"mdi-magnify","clearable":"","hide-details":"","data-cy":"base.baseTable.search"},on:{"input":function($event){_vm.search = $event}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_vm._t("end")],2),_vm._t("default",function(){return [_c('v-data-table',_vm._g({attrs:{"headers":_vm.showDockWindow
          ? [{ value: 'show', text: '', width: 60, sortable: false } ].concat( _vm.headers)
          : _vm.headers,"items":_vm.items,"item-key":_vm.itemKey,"loading":_vm.loading,"search":_vm.search,"show-select":_vm.showMenus,"height":_vm.tableHeight,"custom-filter":_vm.or,"options":_vm.options,"fixed-header":""},scopedSlots:_vm._u([{key:"item.show",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-dock-window")])],1)]},proxy:true},{key:"item.email",fn:function(ref){
          var value = ref.value;
return [_c('toggle-mask',{attrs:{"text":value}})]}},{key:"item.preview",fn:function(ref){
          var item = ref.item;
return [_vm._t("item-preview",null,null,{ item: item })]}}],null,true),model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},_vm.on))]},null,{
      attrs: { height: _vm.tableHeight, loading: _vm.loading, search: _vm.search, customFilter: _vm.or, options: _vm.options },
      on: _vm.on,
    })],2)}
var staticRenderFns = []

export { render, staticRenderFns }