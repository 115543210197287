





















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import {
  PropsUpdateStatusIcon,
  UpdateStatusIcon,
  useUpdateStatusIcon,
} from './UpdateStatusIconComposable';

type Props = PropsUpdateStatusIcon;

export default defineComponent({
  name: 'BaseUpdateStatusIcon',
  model: { prop: 'status', event: 'change' },
  props: {
    status: { type: String as PropType<UpdateStatusIcon>, default: undefined },
    readonly: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useUpdateStatusIcon(props, emit);
  },
});
