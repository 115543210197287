






























import { defineComponent } from '@vue/composition-api';

import { useScreen } from '../../utils/DomUtils';

export default defineComponent({
  name: 'BaseBaseButtonIcon',
  inheritAttrs: false,
  props: {
    icon: { type: Boolean, default: true },
    buttonClass: { type: String, default: undefined },
    buttonStyle: { type: [String, Object], default: undefined },
    tooltipAttrs: { type: Object, default: () => ({ bottom: true }) },
  },
  emits: ['click'],
  setup() {
    return useScreen();
  },
});
