


















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { useBaseDialogOk } from './BaseDialogOkComposable';
import ErrorMessages from './ErrorMessages.vue';

export default defineComponent({
  name: 'BaseBaseDialogOk',
  components: { BaseButton, BaseMarkdown, ErrorMessages },
  inheritAttrs: false,
  emits: ['ok', 'click-anchor'],
  setup(_, { emit }: SetupContext) {
    return useBaseDialogOk(emit);
  },
});
