





import { defineComponent, PropType } from '@vue/composition-api';

import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import {
  ErrorMessage,
  PropsErrorMessages,
  ReplaceError,
  useErrorMessages,
} from './ErrorMessagesComposable';

type Props = PropsErrorMessages;

export default defineComponent({
  name: 'BaseErrorMessages',
  components: { BaseMarkdown },
  props: {
    errors: { type: Array as PropType<ErrorMessage[]>, default: () => [] },
    replace: { type: Array as PropType<ReplaceError[]>, default: () => [] },
    errorColor: { type: Boolean, default: true },
  },
  setup(props: Props) {
    return useErrorMessages(props);
  },
});
