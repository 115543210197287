import { computed, getCurrentInstance } from '@vue/composition-api';

import { requiredNonNull } from '@/utils/TsUtils';

export function useVuetify() {
  const instance = getCurrentInstance();
  const vuetify = computed(() => instance?.proxy.$vuetify);

  const mobile = computed(() => vuetify.value?.breakpoint.mobile);
  const mobileXS = computed(() => vuetify.value?.breakpoint.xs);
  const breakpoint = computed(() => vuetify.value?.breakpoint);
  const theme = computed(() => vuetify.value?.theme);

  function getVuetifyInstance() {
    return requiredNonNull(instance?.proxy.$vuetify, 'instance.proxy.$vuetify');
  }

  function changeDark(value: boolean) {
    getVuetifyInstance().theme.dark = value;
  }

  function changePrimary(value: string) {
    const vInstance = getVuetifyInstance();
    if (theme.value?.dark) {
      vInstance.theme.themes.dark.primary = value;
    } else {
      vInstance.theme.themes.light.primary = value;
    }
  }

  function changeLocale(v: string) {
    getVuetifyInstance().lang.current = v;
  }

  return {
    mobile,
    mobileXS,
    breakpoint,
    theme,
    changeDark,
    changePrimary,
    changeLocale,
  };
}
