




import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Optional } from '@/base/types';

import { PropsBaseMarkdown, useBaseMarkdown } from './BaseMarkdownComposable';
import { BaseMarkdownMentionUser } from './BaseMarkdownMention';

type Props = PropsBaseMarkdown;

export default defineComponent({
  name: 'BaseBaseMarkdown',
  props: {
    body: { type: String, required: true },
    marker: { type: Boolean, default: false },
    markerPosition: { type: [String, Number], default: undefined },
    permalink: { type: Boolean, default: false },
    findUser: {
      type: Function as PropType<(userId: string) => Optional<BaseMarkdownMentionUser>>,
      default: undefined,
    },
  },
  emits: ['click-anchor', 'click-mention', 'mark'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseMarkdown(props, emit);
  },
});
