



















































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import GroupIconBelong from '../atoms/GroupIconBelong.vue';
import ReturnButton from '../atoms/ReturnButton.vue';
import FrameAccountMenuDialog from '../molecules/FrameAccountMenuDialog.vue';
import FrameMainMenuDialog from '../molecules/FrameMainMenuDialog.vue';
import { PropsFrameTopProminent, useFrameTopProminent } from './FrameTopProminentComposable';

type Props = PropsFrameTopProminent;

export default defineComponent({
  name: 'BaseFrameTopProminent',
  components: {
    BaseButtonIcon,
    FrameAccountMenuDialog,
    FrameMainMenuDialog,
    GroupIconBelong,
    ReturnButton,
  },
  inheritAttrs: false,
  props: {
    mode: { type: String, required: true },
    admin: { type: Boolean, required: true },
    side: { type: Boolean, default: false },
    sideOverlay: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    showTop: { type: Boolean, default: false },
    showProminent: { type: Boolean, default: false },
    showExtension: { type: Boolean, default: false },
    showSwitchMobile: { type: Boolean, default: false },
    hideSide: { type: Boolean, default: false },
    hideReturn: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
    sideWidth: { type: Number, default: 300 },
    shrinkLimit: { type: Number, default: 16 },
  },
  emits: ['toggle-frame-mode', 'toggle-frame-admin', 'open-side'],
  setup(props: Props, { emit }: SetupContext) {
    return useFrameTopProminent(props, emit);
  },
});
