import { Optional } from '@/base/types';

import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownClickMentionPayload,
  BaseMarkdownMarkSelection,
} from '../atoms/BaseMarkdownComposable';
import { BaseMarkdownMentionUser } from '../atoms/BaseMarkdownMention';

const MENTION_DEFAULTS = ['group', 'trainer', 'mentor', 'trainee'];

export type MentionMarkdownClickAnchorPayload = BaseMarkdownClickAnchorPayload;
export type MentionMarkdownClickMentionPayload = BaseMarkdownClickMentionPayload;
export type MentionMarkdownMarkSelection = BaseMarkdownMarkSelection;

export function useMentionMarkdown(
  emit: (
    name: string,
    arg:
      | MentionMarkdownClickAnchorPayload
      | MentionMarkdownClickMentionPayload
      | MentionMarkdownMarkSelection
  ) => void
) {
  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickMention(payload: BaseMarkdownClickMentionPayload) {
    emit('click-mention', payload);
  }

  function mark(selection: BaseMarkdownMarkSelection) {
    emit('mark', selection);
  }

  const msgs = useMessages({ prefix: 'base.organisms.mentionMarkdown' });
  const { findUser } = useGlobalStore();
  function findUserAndDefault(id: string): Optional<BaseMarkdownMentionUser> {
    const u = findUser(id);
    if (u) return u;
    if (MENTION_DEFAULTS.includes(id)) return { id, name: msgs.of(id).value };
    return undefined;
  }
  return { clickAnchor, clickMention, mark, findUser: findUserAndDefault };
}
