import 'cropperjs/dist/cropper.min.css';

import { nextTick, ref, watch } from '@vue/composition-api';
import CropperJs from 'cropperjs';

import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../../Messages';

export type CropperReadyPayload = boolean;

export type PropsCropper = {
  options: Cropper.Options<HTMLImageElement>;
};

export function useCropper(
  props: PropsCropper,
  emit: (name: string, args: CropperReadyPayload) => void
) {
  let cropper: CropperJs | undefined;
  const cropperImg = ref<HTMLImageElement>();
  const fileName = ref<string>();
  const isCropperReady = ref(false);
  watch(isCropperReady, (newVal) => emit('ready', newVal));

  async function setup() {
    isCropperReady.value = false;
    return new Promise<void>((resolve) => {
      nextTick(() => {
        assertIsDefined(cropperImg.value);
        cropper = new CropperJs(cropperImg.value, {
          ...props.options,
          ready: () => {
            isCropperReady.value = true;
          },
        });
        resolve();
      });
    });
  }

  const file = ref<string>();
  async function replaceFile(url: string) {
    file.value = url;
    if (cropper) cropper.replace(url);
    else setup();
  }

  const inputFile = ref<HTMLInputElement>();
  function openFileInput() {
    assertIsDefined(inputFile.value);
    inputFile.value.click();
  }

  const loading = ref(false);
  function onLoadStart() {
    loading.value = true;
  }
  function onLoad(evt: ProgressEvent<FileReader>) {
    loading.value = false;
    if (!evt.target?.result || typeof evt.target.result !== 'string') return;
    replaceFile(evt.target.result);
  }
  function onLoadError() {
    file.value = undefined;
    loading.value = false;
    if (cropper) cropper.replace('');
  }

  function onChangeInputFile() {
    assertIsDefined(inputFile.value);
    if (!inputFile.value.files) return;
    const f = inputFile.value.files[0];
    if (!f) return;

    fileName.value = f.name;

    const reader = new FileReader();
    reader.onloadstart = onLoadStart;
    reader.onload = onLoad;
    reader.onerror = onLoadError;
    reader.readAsDataURL(f);

    inputFile.value.value = '';
  }

  function crop(option?: CropperJs.GetCroppedCanvasOptions) {
    assertIsDefined(cropper);
    assertIsDefined(fileName.value);
    return { canvas: cropper.getCroppedCanvas(option), name: fileName.value };
  }

  function destroy() {
    if (cropper) cropper.destroy();
    cropper = undefined;
    isCropperReady.value = false;
    file.value = undefined;
    fileName.value = undefined;
  }

  const msgs = useMessages({ prefix: 'base.molecules.cropper' });
  return {
    cropperImg,
    fileName,
    isCropperReady,
    file,
    loading,
    inputFile,
    labelSelect: msgs.of('selectImageFile'),
    replaceFile,
    openFileInput,
    onChangeInputFile,
    crop,
    destroy,
  };
}

export type Cropper = ReturnType<typeof useCropper>;
