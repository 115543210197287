import { MemoContentReference, QuestionContentReference } from '@/base/domains';
import { Optional } from '@/base/types';
import { isObject } from '@/utils/TsUtils';

import { KNOWTE_MARKER_COLORS } from './ColorUtils';
import { getElementByXPath, getXPathByElement } from './DomUtils';

const MARKER_EXCLUDE_TAGS = ['table', 'thead', 'tbody', 'ul', 'ol', 'blockquote'];

type MarkerSelectionPosition = {
  xpath: string;
  offset: number;
};

export type MarkerSelection = {
  start: MarkerSelectionPosition;
  end: MarkerSelectionPosition;
  position?: string | number;
  caption: string;
  color: string;
};

export type MarkerText = {
  selection: MarkerSelection;
};

export type MarkerProblem = {
  problemIndex: number;
  selection: MarkerSelection;
};

export type MarkerRect = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export type TipPosition =
  | (MarkerText & { type: 'text-marker' })
  | (MarkerProblem & { type: 'problem-marker' })
  | { type: 'text-no-marker' }
  | { type: 'problem-no-marker'; problemIndex: number };

export function isMarkerSelection(x: unknown): x is MarkerSelection {
  if (!isObject(x)) return false;
  return 'start' in x && 'end' in x && 'caption' in x && 'color' in x;
}

export function toMarker(
  referTo?: QuestionContentReference | MemoContentReference
): Optional<MarkerText | MarkerProblem> {
  if (!referTo || !('selection' in referTo)) return undefined;
  if (isMarkerSelection(referTo.selection)) {
    if ('problemIndex' in referTo)
      return { problemIndex: referTo.problemIndex, selection: referTo.selection };
    return { selection: referTo.selection };
  }
  return undefined;
}

export function toTipPosition(
  referTo?: QuestionContentReference | MemoContentReference
): TipPosition {
  if (!referTo) return { type: 'text-no-marker' };
  if ('selection' in referTo) {
    const m = toMarker(referTo);
    if (!m) {
      if ('problemIndex' in referTo)
        return { type: 'problem-no-marker', problemIndex: referTo.problemIndex };
      return { type: 'text-no-marker' };
    }
    if ('problemIndex' in m) return { type: 'problem-marker', ...m };
    return { type: 'text-marker', ...m };
  }
  if ('problemIndex' in referTo) {
    return { type: 'problem-no-marker', problemIndex: referTo.problemIndex };
  }
  return { type: 'text-no-marker' };
}

export function createTipPosition(marker: MarkerText | MarkerProblem): TipPosition {
  if ('problemIndex' in marker) return { type: 'problem-marker', ...marker };
  return { type: 'text-marker', ...marker };
}

export function createMarkerSelection(
  range: Range,
  contextNode: Node,
  position?: string | number
): Optional<MarkerSelection> {
  if (range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE) {
    const ancestor = range.commonAncestorContainer as Element;
    if (ancestor === contextNode || ancestor.contains(contextNode)) return undefined;
    if (MARKER_EXCLUDE_TAGS.includes(ancestor.tagName.toLocaleLowerCase())) return undefined;
  }
  const startXPath = getXPathByElement(range.startContainer, contextNode);
  const endXPath = getXPathByElement(range.endContainer, contextNode);
  if (!startXPath || !endXPath) return undefined;
  const color = KNOWTE_MARKER_COLORS[0];

  let caption = range.toString();
  const copied = range.cloneContents();
  const rubies = copied.querySelectorAll('rt');
  if (rubies) {
    [...rubies].forEach((r) => r.parentElement?.removeChild(r));
    caption = copied.textContent ?? '';
  }

  return {
    position,
    color,
    start: {
      xpath: startXPath,
      offset: range.startOffset,
    },
    end: {
      xpath: endXPath,
      offset: range.endOffset,
    },
    caption: caption.slice(0, 50),
  };
}

export function getMarkerRects(
  root: Node,
  selection: MarkerSelection,
  offsetLeft: number,
  offsetTop: number
): Optional<MarkerRect[]> {
  if (!selection.start || !selection.end) return undefined;
  const rootXPath = getXPathByElement(root) ?? '';
  const start = getElementByXPath(rootXPath + selection.start.xpath, root);
  const end = getElementByXPath(rootXPath + selection.end.xpath, root);
  if (!start || !end) {
    return undefined;
  }

  const range = document.createRange();
  range.setStart(start, selection.start.offset);
  range.setEnd(end, selection.end.offset);
  if (range.collapsed) return undefined;
  const clientRects = range.getClientRects();
  range.detach();

  const rects: { left: number; top: number; width: number; height: number }[] = [];
  for (let i = 0; i < clientRects.length; i += 1) {
    const item = clientRects[i];
    if (item)
      rects.push({
        left: item.left + offsetLeft,
        top: item.top + offsetTop,
        width: item.width,
        height: item.height,
      });
  }
  return rects;
}

export function toStyle(
  rect: MarkerRect,
  def: Record<string, string> = {}
): { style: Record<string, string> } {
  return {
    style: Object.keys(rect).reduce((p, key) => Object.assign(p, { [key]: `${rect[key]}px` }), def),
  };
}
