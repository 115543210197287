import { computed, ComputedRef, nextTick, reactive, Ref, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import getCaretCoordinates from 'textarea-caret';

import { Optional } from '@/base/types';

import { useMessages } from '../../Messages';
import { GlobalStoreGroup, GlobalStoreUser } from '../../store/types';
import { useVuetify } from '../../utils/VuetifyUtils';
import {
  BaseMarkdownMentionUser,
  findMentions,
  MENTION_KEYWORDS,
  MENTION_MARK,
} from '../atoms/BaseMarkdownMention';
import { Mention } from './TextEditorMentionsDialogComposable';
import { escapeRegExp, replaceAt } from './TextEditorUtils';

export type PropsTextEditorMention = {
  group?: GlobalStoreGroup;
  priorityMentions: ComputedRef<Optional<string[]>>;
  findUser?: (id: string) => Optional<GlobalStoreUser>;
  input: Ref<Optional<string>>;
  getTextArea: () => Optional<HTMLTextAreaElement>;
  getListMentions: () => Optional<Element>;
};

export function useTextEditorMention(
  props: PropsTextEditorMention,
  emit: (name: 'change', args: Optional<string>) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.textEditor' });

  const { mobile } = useVuetify();
  const forceShowDialog = computed(
    () => mobile.value && ('ontouchstart' in window || navigator.maxTouchPoints > 0)
  );

  const menu = reactive<{
    value: boolean;
    x?: number;
    y?: number;
    filter: string;
    selected?: string;
  }>({ value: false, filter: '', selected: undefined });

  const areaPosition = ref<number>();
  function savePosition(pos?: number) {
    areaPosition.value = pos;
  }

  function getPosition(n: number) {
    return areaPosition.value ?? n;
  }

  const enabled = computed(() => !!props.findUser);
  const createKeyword = (id: string) => ({
    id,
    name: msgs.of(id).value,
    description: msgs.of(`${id}Description`).value,
  });
  const mentions = computed<Mention[]>(() => {
    if (!enabled.value) return [];
    const users =
      props.group?.users
        .map((u) => ({ id: u.id, name: u.name }))
        .sort((a, b) => {
          if (a.name === b.name) return a.id < b.id ? -1 : 1;
          return a.name < b.name ? -1 : 1;
        }) ?? [];
    return [...MENTION_KEYWORDS.map(createKeyword), ...users];
  });
  const priorities = computed<Mention[]>(() => {
    if (!enabled.value) return [];
    return (
      (props.priorityMentions.value
        ?.map((id) => mentions.value.find((m) => m.id === id))
        .filter((m) => !!m) as Mention[]) ?? []
    );
  });

  function findBaseMarkdownMentionBy(name: string): Optional<BaseMarkdownMentionUser> {
    return mentions.value.find((m) => m.name === name);
  }

  function findBaseMarkdownMention(id: string): Optional<BaseMarkdownMentionUser> {
    if (!props.findUser) return undefined;
    const u = props.findUser(id);
    if (u) return u;
    if (MENTION_KEYWORDS.includes(id)) return createKeyword(id);
    return undefined;
  }

  const findMention = computed(() => {
    if (!enabled.value) return undefined;
    return findBaseMarkdownMention;
  });

  const selectableMentions = computed(() => {
    if (!enabled.value) return [];
    const list = [...priorities.value, ...mentions.value].filter(
      (m, i, arr) => arr.findIndex((item) => item.id === m.id) === i
    );
    const text = menu.filter;
    if (!text) return list;
    return list.filter((m) => m.name.includes(text));
  });

  function toMentionLabel(v?: string) {
    if (!v) return undefined;
    if (!enabled.value) return v;
    const matches = findMentions(v, findBaseMarkdownMention);
    if (matches.length === 0) return v;
    // @{{id}} -> @name
    return matches
      .sort((a, b) => a.start - b.start)
      .reduce((p, c, i, arr) => {
        const pre = arr[i - 1];
        p.push(v.slice(pre?.end ?? 0, c.start) ?? '');
        p.push(`${MENTION_MARK}${c.user.name}`);
        if (i === arr.length - 1) p.push(v.slice(c.end) ?? '');
        return p;
      }, [] as string[])
      .join('');
  }

  function toMentionId(v?: string) {
    if (!v) return undefined;
    if (!enabled.value) return v;
    const str = mentions.value.map((m) => `${MENTION_MARK}${escapeRegExp(m.name)}\\s`).join('|');
    const regexp = new RegExp(str, 'g');
    if (v.search(regexp) === -1) return v;
    const matches = Array.from(v.matchAll(regexp), (m) => ({
      text: m[0],
      user: findBaseMarkdownMentionBy(m[0].slice(1, m[0].length - 1)),
      start: m.index ?? 0,
      end: (m.index ?? 0) + m[0].length,
    })).filter((item) => item.user) as {
      text: string;
      user: BaseMarkdownMentionUser;
      start: number;
      end: number;
    }[];
    if (matches.length === 0) return v;
    // @name -> @{{id}}
    return matches
      .sort((a, b) => a.start - b.start)
      .reduce((p, c, i, arr) => {
        const pre = arr[i - 1];
        p.push(v.slice(pre?.end ?? 0, c.start) ?? '');
        p.push(`${MENTION_MARK}{{${c.user.id}}} `);
        if (i === arr.length - 1) p.push(v.slice(c.end) ?? '');
        return p;
      }, [] as string[])
      .join('');
  }

  function closeMentionMenu() {
    menu.value = false;
    menu.filter = '';
    menu.selected = undefined;
    const area = props.getTextArea();
    if (!area) return;
    area.removeEventListener('scroll', closeMentionMenu);
    area.focus();
  }

  function openMentionMenu(area: HTMLTextAreaElement) {
    area.addEventListener('scroll', closeMentionMenu);
    const { x, y } = area.getBoundingClientRect();
    const { left, top, height } = getCaretCoordinates(area, area.selectionEnd);
    menu.x = x + left;
    menu.y = y + top + height - area.scrollTop;
    menu.filter = '';
    menu.selected = selectableMentions.value[0]?.id;
    menu.value = true;
  }

  function changeMentionMenu(v: boolean) {
    if (v) return;
    closeMentionMenu();
  }

  function addMention(m: Mention) {
    const area = props.getTextArea();
    if (!area) return;
    const name = `${MENTION_MARK}${m.name} `;
    const { value, index } = replaceAt(
      props.input.value,
      name,
      getPosition(area.selectionEnd),
      area.selectionEnd
    );
    emit('change', toMentionId(value));
    nextTick(() => {
      area.selectionStart = index;
      area.selectionEnd = index;
      area.focus();
    });
  }

  function addMentionBy(id: string) {
    const m = findBaseMarkdownMention(id);
    if (!m) return;
    addMention(m);
  }

  function selectMention(m?: Mention, evt?: MouseEvent | KeyboardEvent) {
    if (evt instanceof KeyboardEvent) return;
    if (m) addMention(m);
    closeMentionMenu();
  }

  function onInput(v: Optional<string>, openDialog: (n: number) => void) {
    if (!enabled.value) return true;
    const area = props.getTextArea();
    if (!area) return true;
    const pos = area.selectionEnd;
    if (menu.value) {
      const s = getPosition(0);
      if (pos <= s) {
        closeMentionMenu();
        return true;
      }
      menu.filter = v?.slice(s + 1, pos) ?? '';
      menu.selected = selectableMentions.value[0]?.id;
      return true;
    }
    const char = v?.slice(pos - 1, pos);
    if (char !== MENTION_MARK) return true;
    if (forceShowDialog.value) {
      openDialog(pos - 1);
      return false;
    }
    savePosition(pos - 1);
    openMentionMenu(area);
    return true;
  }

  function changeMentionSelect(value: number) {
    const id = menu.selected;
    if (!id) {
      menu.selected = selectableMentions.value[0]?.id;
      return;
    }
    let pos = selectableMentions.value.findIndex((item) => item.id === id);
    pos += value;
    if (pos < 0) pos = selectableMentions.value.length - 1;
    else if (pos > selectableMentions.value.length - 1) pos = 0;
    menu.selected = selectableMentions.value[pos]?.id;
    nextTick(() =>
      useTimeoutFn(() => {
        const list = props.getListMentions();
        if (!list) return;
        const div = list.closest('.base-text-editor-menu');
        if (!div) return;
        const [activeItem] = list.getElementsByClassName('v-list-item--active');
        if (!activeItem) return;
        const { top: baseTop } = div.getBoundingClientRect();
        const { top } = activeItem.getBoundingClientRect();
        const y = top - baseTop + div.scrollTop;
        div.scrollTo({ top: y });
      }, 50)
    );
  }

  function onKeyDown(evt: KeyboardEvent) {
    if (!menu.value) return;
    switch (evt.key) {
      case 'ArrowDown':
        if (evt.altKey || evt.shiftKey || evt.ctrlKey) break;
        if (selectableMentions.value.length === 0) break;
        evt.preventDefault();
        evt.stopPropagation();
        changeMentionSelect(1);
        break;
      case 'ArrowUp':
        if (evt.altKey || evt.shiftKey || evt.ctrlKey) break;
        if (selectableMentions.value.length === 0) break;
        evt.preventDefault();
        evt.stopPropagation();
        changeMentionSelect(-1);
        break;
      case 'Enter': {
        if (evt.altKey || evt.shiftKey || evt.ctrlKey) break;
        if (!menu.selected) break;
        evt.preventDefault();
        evt.stopPropagation();
        addMentionBy(menu.selected);
        closeMentionMenu();
        break;
      }
      case 'Escape': {
        evt.preventDefault();
        evt.stopPropagation();
        closeMentionMenu();
        break;
      }
      default:
    }
  }

  function clear() {
    closeMentionMenu();
  }

  return {
    forceShowDialog,
    mentionMenu: menu,
    mentionEnabled: enabled,
    selectableMentions,
    mentions,
    priorities,
    findMention,
    savePosition,
    changeMentionMenu,
    selectMention,
    addMentionBy,
    toMentionLabel,
    toMentionId,
    onInputMention: onInput,
    onKeyDownMention: onKeyDown,
    clearMention: clear,
  };
}
