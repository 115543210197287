































































































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Optional } from '@/base/types';
import { UploadFileType } from '@/base/usecases';

import { GlobalStoreGroup, GlobalStoreUser } from '../../store/types';
import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import BaseTextarea from '../atoms/BaseTextarea.vue';
import { PropsTextEditor, useTextEditor } from './TextEditorComposable';
import TextEditorFileUploadItem from './TextEditorFileUploadItem.vue';
import TextEditorMentionsDialog from './TextEditorMentionsDialog.vue';

type Props = PropsTextEditor;

export default defineComponent({
  name: 'BaseTextEditor',
  components: {
    BaseTextarea,
    BaseButtonIcon,
    BaseMarkdown,
    TextEditorFileUploadItem,
    TextEditorMentionsDialog,
  },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    noDataText: { type: String, default: undefined },
    uploadFileType: { type: String as PropType<UploadFileType>, default: 'default' },
    disabled: { type: Boolean, default: false },
    hidePreview: { type: Boolean, default: false },
    hideUpload: { type: Boolean, default: false },
    hideTutorial: { type: Boolean, default: false },
    height: { type: String, default: '120px' },
    maxHeight: { type: String, default: undefined },
    maxlength: { type: Number, default: undefined },
    veeRules: { type: String, default: undefined },
    veeVid: { type: String, default: undefined },
    veeLabel: { type: String, default: undefined },
    group: { type: Object as PropType<GlobalStoreGroup>, default: undefined },
    priorityMentions: { type: Array as PropType<string[]>, default: undefined },
    findUser: {
      type: Function as PropType<(id: string) => Optional<GlobalStoreUser>>,
      default: undefined,
    },
    displayDebug: { type: Boolean, default: false },
  },
  emits: ['change', 'click-anchor', 'click-user'],
  setup(props: Props, { emit }: SetupContext) {
    return useTextEditor(props, emit);
  },
});
