import { computed } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import { useMessages } from '../..';
import { BaseSelectSearchItemSelection } from '../atoms/BaseSelectSearchComposable';
import { UserSelectItem } from './UserSelectComposable';

export type UserSelectListItem = BaseSelectSearchItemSelection<UserSelectItem>;

export type PropsUserSelectList = {
  users: UserSelectListItem[];
  roles: GroupRole[];
};

export function useUserSelectList(
  props: PropsUserSelectList,
  emit: (name: string, args: string) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.userSelectList' });
  const list = computed(() =>
    props.roles.map((role) => ({
      role,
      label: msgs.of(role).value,
      items: props.users.filter((item) => item.item.role === role),
    }))
  );

  const labelNoData = computed(() =>
    list.value.some((rItem) => rItem.items.length > 0) ? undefined : msgs.of('noData').value
  );

  function toggle(item: UserSelectListItem) {
    emit('toggle', item.item.id);
  }

  return { list, labelNoData, toggle };
}
