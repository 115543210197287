import Cookies from 'js-cookie';

import { Optional } from '@/base/types';
import { requiredVerCodeOfAndroidApp } from '@/config/env';

import { uuid } from './UniqueIdGenerator';

export type AndroidUtilsCallbackFn = (params: string[]) => void;

export function isAndroidOS() {
  return /android/i.test(navigator.userAgent);
}

export function getAndroidAccount(userId: string, done: AndroidUtilsCallbackFn): boolean {
  if (!window.$androidWebAppBridge) return false;
  const callbackId = window.$webAppAndroidBridge.addListener('callbackAccount', done);
  try {
    const ret = window.$androidWebAppBridge.invoke(
      'getAccount',
      [callbackId, userId],
      requiredVerCodeOfAndroidApp
    );
    if (!ret) window.$webAppAndroidBridge.removeListener(callbackId);
    return ret;
  } catch (e) {
    window.$webAppAndroidBridge.removeListener(callbackId);
    return false;
  }
}

export function waitSignIn(): boolean {
  if (!window.$androidWebAppBridge) return false;
  return window.$androidWebAppBridge.invoke('waitSignIn', [], requiredVerCodeOfAndroidApp);
}

export function signedIn(id: string): boolean {
  if (!window.$androidWebAppBridge) return false;
  return window.$androidWebAppBridge.invoke('signedIn', [id], requiredVerCodeOfAndroidApp);
}

export function signedOut(): boolean {
  if (!window.$androidWebAppBridge) return false;
  return window.$androidWebAppBridge.invoke('signedOut', [], requiredVerCodeOfAndroidApp);
}

export function createAndroidAccountByCode(
  id: string,
  tenantCode: string,
  userCode: string,
  pwd: string,
  getDeviceToken: AndroidUtilsCallbackFn
): boolean {
  if (!window.$androidWebAppBridge) return false;
  const callbackId = window.$webAppAndroidBridge.addListener('callbackDeviceToken', getDeviceToken);
  try {
    const ret = window.$androidWebAppBridge.invoke(
      'signedIn',
      [callbackId, id, tenantCode, userCode, pwd],
      requiredVerCodeOfAndroidApp
    );
    if (!ret) window.$webAppAndroidBridge.removeListener(callbackId);
    return ret;
  } catch (e) {
    window.$webAppAndroidBridge.removeListener(callbackId);
    return false;
  }
}

export function createAndroidAccountByEmail(
  id: string,
  email: string,
  pwd: string,
  getDeviceToken: AndroidUtilsCallbackFn
): boolean {
  if (!window.$androidWebAppBridge) return false;
  const callbackId = window.$webAppAndroidBridge.addListener('callbackDeviceToken', getDeviceToken);
  try {
    const ret = window.$androidWebAppBridge.invoke(
      'signedIn',
      [callbackId, id, email, pwd],
      requiredVerCodeOfAndroidApp
    );
    if (!ret) window.$webAppAndroidBridge.removeListener(callbackId);
    return ret;
  } catch (e) {
    window.$webAppAndroidBridge.removeListener(callbackId);
    return false;
  }
}

export function requestDownload(url: string): boolean {
  if (!window.$androidWebAppBridge) return false;
  const cookies = [
    'CloudFront-Policy',
    'CloudFront-Signature',
    'CloudFront-Key-Pair-Id',
    'CloudFront-Expire',
  ]
    .map((key) => `${key}=${Cookies.get(key)}`)
    .join(';');
  return window.$androidWebAppBridge.invoke(
    'requestDownload',
    [url, cookies],
    requiredVerCodeOfAndroidApp
  );
}

class AndroidUtilWebAppBridge implements WebAppBridge<AndroidUtilsCallbackFn> {
  private callbackMap = new Map<string, AndroidUtilsCallbackFn>();

  addListener(name: string, callback: AndroidUtilsCallbackFn): string {
    const id = `${name}-${uuid()}`;
    this.callbackMap.set(id, callback);
    return id;
  }

  removeListener(id: string): Optional<AndroidUtilsCallbackFn> {
    const fn = this.callbackMap.get(id);
    this.callbackMap.delete(id);
    return fn;
  }

  invoke(id: string, params: string[]): void {
    const fn = this.removeListener(id);
    if (fn) fn(params);
  }
}
window.$webAppAndroidBridge = new AndroidUtilWebAppBridge();
