








import { defineComponent, PropType } from '@vue/composition-api';
import { DoughnutChart } from 'vue-chart-3';

import { Questionnaire } from '@/base/domains';

import {
  PropsQuestionnaireAnswerBreakdownDoughnutChart,
  useQuestionnaireAnswerBreakdownDoughnutChart,
} from './QuestionnaireAnswerBreakdownDoughnutChartComposable';

type Props = PropsQuestionnaireAnswerBreakdownDoughnutChart;

export default defineComponent({
  name: 'BaseQuestionnaireAnswerBreakdownDoughnutChart',
  components: {
    DoughnutChart,
  },
  props: {
    questionnaire: { type: Object as PropType<Questionnaire>, required: true },
  },
  setup(props: Props) {
    return useQuestionnaireAnswerBreakdownDoughnutChart(props);
  },
});
