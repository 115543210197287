




















import { defineComponent, SetupContext } from '@vue/composition-api';

import RefreshButton from '../atoms/RefreshButton.vue';
import FrameMainMenuList from '../molecules/FrameMainMenuList.vue';
import { PropsFrameSide, useFrameSide } from './FrameSideComposable';

type Props = PropsFrameSide;

export default defineComponent({
  name: 'BaseFrameSide',
  components: { RefreshButton, FrameMainMenuList },
  model: { prop: 'side', event: 'change' },
  props: {
    side: { type: Boolean, default: false },
    sideWidth: { type: Number, default: 300 },
    admin: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useFrameSide(props, emit);
  },
});
