import { render, staticRenderFns } from "./DialogUserAvatar.vue?vue&type=template&id=d5a67ff4&"
import script from "./DialogUserAvatar.vue?vue&type=script&lang=ts&"
export * from "./DialogUserAvatar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VContainer,VIcon,VSheet})
