import { computed, ref } from '@vue/composition-api';

import { Locale } from '@/base/domains';
import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  isSucceeded,
  useChangeUserDisplaySettings,
  useChangeUserLocale,
  useChangeUserName,
  useRemoveUserAvatar,
} from '../../../usecases';
import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import { getDefaultColor, getPrimaryOrDefaultColor, useColor } from '../../utils/ColorUtils';
import { clearDialogQuery, DialogName, useDialogQuery } from '../../utils/DialogQueryUtils';
import { useVuetify } from '../../utils/VuetifyUtils';
import { UserAvatar } from '../atoms/UserComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '../molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '../molecules/ErrorMessagesComposable';
import LOCALES from './DialogSettingsLocales.json';

type Theme = {
  theme: string;
  primary: string;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  return { dialog, dialogFullScreen, opened, error };
}

export function useDialogSettings() {
  const route = useRoute();
  const router = useRouter();
  const { user, tenant } = useGlobalStore();
  const { moveTo: moveToUserAvatar } = useDialogQuery(DialogName.BASE_USER_AVATAR);

  const { dialogFullScreen, dialog, opened, error } = useDialogFullScreen();
  const { changeDark: changeDarkVuetify, changePrimary: changePrimaryVuetify } = useVuetify();
  const { dark: vuetifyDark, colors } = useColor();

  const name = ref<string>('');
  const locale = ref<Locale>('ja');
  const theme = ref<Theme>({ theme: '', primary: '' });

  function close() {
    dialog.value = { display: false };
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  function open() {
    assertIsDefined(user.value, 'user');
    name.value = user.value.name;
    locale.value = user.value.locale;
    if (user.value.displaySettings) {
      theme.value = {
        theme: user.value.displaySettings.theme,
        primary: user.value.displaySettings.color,
      };
    } else {
      theme.value = {
        theme: vuetifyDark.value === true ? 'dark' : 'light',
        primary: getPrimaryOrDefaultColor(),
      };
    }
    dialog.value = { display: true };
  }

  const updateName = useChangeUserName();
  async function changeName(v?: string) {
    if (!v) return;
    assertIsDefined(user.value, 'user');
    dialog.value = { ...dialog.value, status: 'updating' };
    const { id } = user.value;
    const res = await updateName.execute({ id, name: v });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  const updateLocale = useChangeUserLocale();
  async function changeLocale(v?: string) {
    if (!v) return;
    assertIsDefined(user.value, 'user');
    dialog.value = { ...dialog.value, status: 'updating' };
    const { id } = user.value;
    const res = await updateLocale.execute({ id, locale: v as Locale });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  const updateDisplaySettings = useChangeUserDisplaySettings();
  async function changeDisplaySettings() {
    assertIsDefined(user.value, 'user');
    assertIsDefined(theme.value, 'theme');
    dialog.value = { ...dialog.value, status: 'updating' };
    const { id } = user.value;
    const displaySettings = {
      theme: theme.value.theme,
      color: theme.value.primary,
    };
    const res = await updateDisplaySettings.execute({ id, displaySettings });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  function changePrimary(primary: string) {
    assertIsDefined(theme.value, 'theme');
    theme.value.primary = primary;
    changePrimaryVuetify(primary);
    changeDisplaySettings();
  }

  function changeDark(v: string) {
    assertIsDefined(theme.value, 'theme');
    theme.value.theme = v;
    const dark = v === 'dark';
    changeDarkVuetify(dark);
    changePrimary(getDefaultColor(dark));
  }

  const removeAvatar = useRemoveUserAvatar();
  async function clearAvatar() {
    assertIsDefined(user.value, 'user');
    dialog.value = { ...dialog.value, status: 'updating' };
    const res = await removeAvatar.execute({ id: user.value.id });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
    } else {
      dialog.value = { ...dialog.value, status: 'changed' };
      error(res.errors);
    }
  }

  function openAvatar() {
    moveToUserAvatar();
  }

  const userAvatar = computed<Optional<UserAvatar>>(() => {
    if (!user.value) return undefined;
    return { id: user.value.id, name: user.value.name, avatar: user.value.avatar };
  });

  const multilingualSupport = computed(
    () => tenant.value?.limitations.multilingualSupport === 'enabled'
  );

  const msgs = useMessages({ prefix: 'base.organisms.dialogSettings' });
  return {
    dialogFullScreen,
    dialog,
    user: userAvatar,
    name,
    locale,
    theme,
    colors,
    multilingualSupport,
    labelSetting: msgs.of('setting'),
    labelNoImage: msgs.of('noImage'),
    labelNoAvatar: msgs.of('noAvatar'),
    labelRemoveAvatar: msgs.of('removeAvatar'),
    labelChangeAvatar: msgs.of('changeAvatar'),
    labelName: msgs.of('name'),
    labelLocale: msgs.of('locale'),
    labelTheme: msgs.of('theme'),
    locales: LOCALES,
    themes: msgs.listOf('themes'),
    close,
    open,
    opened,
    changeName,
    changeLocale,
    changePrimary,
    changeDark,
    clearAvatar,
    openAvatar,
  };
}

export type DialogSettings = ReturnType<typeof useDialogSettings>;
