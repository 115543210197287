import { ref } from '@vue/composition-api';

import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../../Messages';
import { downloadFromFilesServer, getFileAttrs, tryUrl } from '../../utils/FileUtils';
import { BaseDialogConfirm } from '../molecules/BaseDialogConfirmComposable';

export function useDialogAnchorConfirm() {
  const msgs = useMessages({ prefix: 'base.organisms.dialogAnchorConfirm' });

  const confirmDialog = ref<BaseDialogConfirm>();
  function open(key: string, func: () => void) {
    assertIsDefined(confirmDialog.value);
    const msg = msgs.of(key).value;
    confirmDialog.value.open(msg, func);
  }

  function ok() {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.close('ok');
  }

  function openWindow(url: URL) {
    window.open(url.href, '_blank');
  }

  const loading = ref(false);
  async function confirm(event: Event): Promise<Optional<string>> {
    loading.value = false;
    if (!event.target || !(event.target instanceof Element)) return undefined;

    const e = event.target as Element;
    const href = e.getAttribute('href');
    if (!href) return undefined;

    const url = tryUrl(href);
    if (!url) return href;

    const { hostname } = window.location;
    const fileAttrs = getFileAttrs(url);

    if (fileAttrs?.inFilesServer) {
      loading.value = true;
      const ret = await downloadFromFilesServer(fileAttrs);
      loading.value = false;
      if (ret) return undefined;
    }
    if (url.hostname !== hostname) {
      const key = fileAttrs?.download ? 'confirmDownload' : 'confirmOpenWindow';
      open(key, () => openWindow(url));
      return undefined;
    }
    window.location.href = url.href;
    return undefined;
  }

  return { confirmDialog, loading, confirm, ok };
}

export type DialogAnchorConfirm = ReturnType<typeof useDialogAnchorConfirm>;
