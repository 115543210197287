import { ref } from '@vue/composition-api';
import CropperJs from 'cropperjs';

import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { isFailed, useChangeUserAvatar } from '../../../usecases';
import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import { useColor } from '../../utils/ColorUtils';
import { clearDialogQuery } from '../../utils/DialogQueryUtils';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '../molecules/BaseDialogFullScreenComposable';
import { Cropper } from '../molecules/CropperComposable';
import { ErrorMessage } from '../molecules/ErrorMessagesComposable';

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg);
  }
  return { dialog, dialogFullScreen, opened, error, info };
}

export function useDialogUserAvatar() {
  const msgs = useMessages({ prefix: 'base.organisms.dialogUserAvatar' });
  const route = useRoute();
  const router = useRouter();
  const { user } = useGlobalStore();
  const { dialogFullScreen, dialog, opened, error, info } = useDialogFullScreen();
  const { dark } = useColor();

  const cropper = ref<Cropper>();
  function crop(option?: CropperJs.GetCroppedCanvasOptions) {
    assertIsDefined(cropper.value);
    return cropper.value.crop(option);
  }

  function destroy() {
    if (!cropper.value) return;
    cropper.value.destroy();
  }

  const ready = ref(false);
  const loading = ref(false);
  const src = ref<string>();

  function changeReady(v: boolean) {
    ready.value = v;
  }

  function close() {
    dialog.value = { display: false };
    ready.value = false;
    src.value = undefined;
    destroy();
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  function open() {
    dialog.value = { display: true };
  }

  const changeAvatar = useChangeUserAvatar();
  async function save() {
    assertIsDefined(user.value);
    dialog.value.status = 'updating';
    loading.value = true;
    const cropped = crop({ width: 48, height: 48, fillColor: dark.value ? '#1e1e1e' : '#ffffff' });
    const { canvas } = cropped;
    src.value = canvas.toDataURL();
    const file = await new Promise<Optional<Blob>>((resolve) => {
      canvas.toBlob((b) => resolve(b || undefined), 'image/jpeg');
    });
    if (!file) {
      dialog.value.status = 'changed';
      loading.value = false;
      info(msgs.of('failedImageConversion').value);
      return;
    }
    const { id } = user.value;
    const res = await changeAvatar.execute({ id, file });
    if (isFailed(res)) {
      dialog.value.status = 'changed';
      loading.value = false;
      error(res.errors);
      return;
    }
    dialog.value.status = 'updated';
    loading.value = false;
  }

  return {
    dialogFullScreen,
    dialog,
    cropper,
    loading,
    ready,
    src,
    labelSetting: msgs.of('setting'),
    labelAvatar: msgs.of('avatar'),
    labelChangeAvatar: msgs.of('changeAvatar'),
    changeReady,
    close,
    open,
    opened,
    save,
  };
}

export type DialogUserAvatar = ReturnType<typeof useDialogUserAvatar>;
