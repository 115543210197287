








import {
  computed,
  defineComponent,
  onMounted,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';

type Props = {
  value: boolean;
  text?: string;
};

export default defineComponent({
  name: 'BaseToggleMask',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Boolean, default: true },
    text: { type: String, default: undefined },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    const mask = ref(true);
    function init() {
      mask.value = props.value;
    }
    onMounted(init);
    watch(() => props.value, init);

    function toggle() {
      mask.value = !mask.value;
      emit('change', mask.value);
    }

    const displayText = computed(() => (mask.value ? props.text?.replace(/./g, '*') : props.text));
    return { mask, displayText, toggle };
  },
});
