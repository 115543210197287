


























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { NotificationReference } from '@/base/domains';

import BaseLink from './BaseLink.vue';
import { PropsNotificationBarMemo, useNotificationBarMemo } from './NotificationBarMemoComposable';

type Props = PropsNotificationBarMemo;

export default defineComponent({
  name: 'BaseNotificationBarMemo',
  components: { BaseLink },
  props: {
    notification: { type: Object as PropType<NotificationReference>, required: true },
    groupName: { type: String, default: undefined },
    omit: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useNotificationBarMemo(props, emit);
  },
});
