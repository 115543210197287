import { nextTick, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { googlePlayUrl } from '@/config/env';
import { isAndroidOS } from '@/utils/AndroidUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { uuid } from '@/utils/UniqueIdGenerator';
import { useLocalStorage, useRoute } from '@/utils/VueUtils';

import { useGlobalStore } from '../../store';
import { DialogName, useDialogQuery } from '../../utils/DialogQueryUtils';
import { DialogNotifications } from './DialogNotificationsComposable';
import { DialogQuestionnaireAnswer } from './DialogQuestionnaireAnswerComposable';
import { DialogQuestionnaire } from './DialogQuestionnaireComposable';
import { DialogSettings } from './DialogSettingsComposable';
import { DialogUserAvatar } from './DialogUserAvatarComposable';
import { DialogUserDetail, DialogUserDetailTarget } from './DialogUserDetailComposable';
import { ToastNotifications } from './ToastNotificationsComposable';

export type FrameDialogTarget = {
  event: string;
  params?: DialogUserDetailTarget;
};

type OpenUserPayload = {
  event: 'user';
  params: DialogUserDetailTarget;
};

type FrameDialogsOpenPayload = OpenUserPayload;

function useSettingsDialog() {
  const settingsDialog = ref<DialogSettings>();
  const { getQuery, existsQuery } = useDialogQuery(DialogName.BASE_SETTINGS);
  function watchSettingsDialogQuery() {
    if (!settingsDialog.value) return;
    const q = getQuery();
    if (q) {
      settingsDialog.value.open();
    } else if (!q && !existsQuery() && settingsDialog.value.opened()) {
      settingsDialog.value.close();
    }
  }
  return { settingsDialog, watchSettingsDialogQuery };
}

function useUserAvatarDialog() {
  const userAvatarDialog = ref<DialogUserAvatar>();
  const { getQuery } = useDialogQuery(DialogName.BASE_USER_AVATAR);
  function watchUserAvatarDialogQuery() {
    if (!userAvatarDialog.value) return;
    const q = getQuery();
    if (q) {
      userAvatarDialog.value.open();
    } else if (!q && userAvatarDialog.value.opened()) {
      userAvatarDialog.value.close();
    }
  }
  return { userAvatarDialog, watchUserAvatarDialogQuery };
}

function useNotificationsDialog() {
  const notificationsDialog = ref<DialogNotifications>();
  const { getQuery, moveTo: moveToNotifications } = useDialogQuery(DialogName.BASE_NOTIFICATIONS);
  function watchNotificationsDialogQuery() {
    if (!notificationsDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'id' in q.params) {
      notificationsDialog.value.open({ id: q.params.id });
    } else if (q) {
      notificationsDialog.value.open(undefined);
    } else if (!q && notificationsDialog.value.opened()) {
      notificationsDialog.value.close();
    }
  }
  return { notificationsDialog, moveToNotifications, watchNotificationsDialogQuery };
}

function useQuestionnaireDialog() {
  const questionnaireDialog = ref<DialogQuestionnaire>();
  const { getQuery } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE);
  function watchQuestionnaireDialogQuery() {
    if (!questionnaireDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'id' in q.params) {
      questionnaireDialog.value.openRefer({ id: q.params.id });
    } else if (q && q.params && 'groupId' in q.params) {
      questionnaireDialog.value.openCreate({ groupId: q.params.groupId });
    } else if (!q && questionnaireDialog.value.opened()) {
      questionnaireDialog.value.close();
    }
  }
  return { questionnaireDialog, watchQuestionnaireDialogQuery };
}

function useQuestionnaireAnswerDialog() {
  const questionnaireAnswerDialog = ref<DialogQuestionnaireAnswer>();
  const { getQuery } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE_ANS);
  function watchQuestionnaireAnswerDialogQuery() {
    if (!questionnaireAnswerDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'id' in q.params) {
      questionnaireAnswerDialog.value.open({ id: q.params.id });
    } else if (!q && questionnaireAnswerDialog.value.opened()) {
      questionnaireAnswerDialog.value.close();
    }
  }
  return { questionnaireAnswerDialog, watchQuestionnaireAnswerDialogQuery };
}

export function useFrameDialogs(emit: (name: string, args: boolean) => void) {
  const route = useRoute();
  const { isSignedIn, tenantDataLoaded, onReady, onTenantDataFirstFetched } = useGlobalStore();

  const userDetailDialog = ref<DialogUserDetail>();
  function openUserDetail(payload: DialogUserDetailTarget) {
    assertIsDefined(userDetailDialog.value);
    userDetailDialog.value.open(payload);
  }

  function open(payload: FrameDialogsOpenPayload) {
    switch (payload.event) {
      case 'user':
        openUserDetail(payload.params);
        break;
      default:
    }
  }

  const { settingsDialog, watchSettingsDialogQuery } = useSettingsDialog();
  const { userAvatarDialog, watchUserAvatarDialogQuery } = useUserAvatarDialog();
  const { notificationsDialog, moveToNotifications, watchNotificationsDialogQuery } =
    useNotificationsDialog();
  const { questionnaireDialog, watchQuestionnaireDialogQuery } = useQuestionnaireDialog();
  const { questionnaireAnswerDialog, watchQuestionnaireAnswerDialogQuery } =
    useQuestionnaireAnswerDialog();

  const toastNotifications = ref<ToastNotifications>();
  const storeToast = useLocalStorage('base.frameDialogs.toast', { onGP: false });
  function noticeOnGooglePlay() {
    if (
      !toastNotifications.value ||
      !isSignedIn.value ||
      storeToast.value.onGP ||
      !isAndroidOS() ||
      !!window.$androidWebAppBridge ||
      !googlePlayUrl
    ) {
      return;
    }
    storeToast.value = { ...storeToast.value, onGP: true };
    toastNotifications.value.notice({
      notification: { id: uuid(), type: 'SYSTEM_ON_GOOGLE_PLAY' },
      timeout: 30,
    });
  }

  function init() {
    nextTick(() => {
      watchUserAvatarDialogQuery();
      watchSettingsDialogQuery();
      watchNotificationsDialogQuery();
      watchQuestionnaireDialogQuery();
      watchQuestionnaireAnswerDialogQuery();
      useTimeoutFn(noticeOnGooglePlay, 3000);
    });
  }
  onReady(() => {
    if (!tenantDataLoaded.value) return;
    init();
  });
  onTenantDataFirstFetched(init);
  watch(() => route.query, init);

  function changeAdminMode(v: boolean) {
    emit('change-admin-mode', v);
  }

  return {
    isSignedIn,
    settingsDialog,
    userAvatarDialog,
    userDetailDialog,
    notificationsDialog,
    questionnaireDialog,
    questionnaireAnswerDialog,
    toastNotifications,
    open,
    changeAdminMode,
    moveToNotifications,
  };
}

export type FrameDialogs = ReturnType<typeof useFrameDialogs>;
