








































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseTextareaAutoGrow from './BaseTextareaAutoGrow.vue';
import { PropsBaseTextarea, useBaseTextarea } from './BaseTextareaComposable';

type Props = PropsBaseTextarea;

export default defineComponent({
  name: 'BaseBaseTextarea',
  components: { BaseTextareaAutoGrow },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    label: { type: String, default: undefined },
    dense: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    height: { type: String, default: undefined },
    maxHeight: { type: String, default: undefined },
    autoGrow: { type: Boolean, default: false },
    veeRules: { type: String, default: undefined },
    veeVid: { type: String, default: undefined },
    veeLabel: { type: String, default: undefined },
    on: { type: Object, default: () => ({}) },
  },
  emits: ['change', 'input', 'valid'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseTextarea(props, emit);
  },
});
