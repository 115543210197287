export function or(value: unknown, search?: string) {
  if (!search || search === '*') return true;
  if (value === undefined || value === null) return false;
  let target: string;
  if (typeof value === 'string') target = value.toLowerCase();
  else if (typeof value === 'number') target = value.toString(10);
  else if (typeof value === 'boolean') target = value ? 'true' : 'false';
  else return false;
  return search
    .split(/\s/g)
    .filter((t) => !!t)
    .map((t) => t.toLowerCase())
    .some((t) => target.includes(t));
}

export function includes(value: unknown, search?: string) {
  if (!search) return true;
  if (value === undefined || value === null) return false;
  let target: string;
  if (typeof value === 'string') target = value.toLowerCase();
  else if (typeof value === 'number') target = value.toString(10);
  else if (typeof value === 'boolean') target = value ? 'true' : 'false';
  else return false;
  return target.includes(search.toLowerCase());
}
