import { computed } from '@vue/composition-api';
import { DataOptions } from 'vuetify';

import { useSessionStorage } from '@/utils/VueUtils';

type Options = {
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
};

export function useTableOption(id: string) {
  const storeOptions = useSessionStorage<Record<string, Options>>('base.tableUtils.options', {});

  function updateOptions(dataOptions: DataOptions) {
    if (!id) return;
    storeOptions.value = {
      ...storeOptions.value,
      [id]: {
        itemsPerPage: dataOptions.itemsPerPage,
        sortBy: dataOptions.sortBy,
        sortDesc: dataOptions.sortDesc,
      },
    };
  }

  const options = computed(() => storeOptions.value[id]);

  return { options, updateOptions };
}
