




























































































import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseRadioGroup from '../atoms/BaseRadioGroup.vue';
import BaseSelectSingle from '../atoms/BaseSelectSingle.vue';
import BaseText from '../atoms/BaseText.vue';
import ColorSelect from '../atoms/ColorSelect.vue';
import User from '../atoms/User.vue';
import BaseDialogFullScreen from '../molecules/BaseDialogFullScreen.vue';
import { useDialogSettings } from './DialogSettingsComposable';

export default defineComponent({
  name: 'BaseDialogSettings',
  components: {
    BaseDialogFullScreen,
    BaseText,
    BaseButtonIcon,
    BaseSelectSingle,
    BaseRadioGroup,
    ColorSelect,
    User,
  },
  emits: ['closed'],
  setup() {
    return useDialogSettings();
  },
});
