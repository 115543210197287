import { computed } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import {
  BaseTextareaAutoGrowInputPayload,
  BaseTextareaAutoGrowValidPayload,
} from './BaseTextareaAutoGrowComposable';

export type BaseTextareaValidPayload = BaseTextareaAutoGrowValidPayload;

export type BaseTextareaInputPayload = BaseTextareaAutoGrowInputPayload;

export type PropsBaseTextarea = {
  value?: string;
  label?: string;
  dense: boolean;
  outlined: boolean;
  veeRules?: string;
  veeVid?: string;
  veeLabel?: string;
};

export function useBaseTextarea(
  props: PropsBaseTextarea,
  emit: (name: string, arg: BaseTextareaValidPayload | BaseTextareaInputPayload) => void
) {
  const required = computed(() => props.veeRules && props.veeRules.split('|').includes('required'));
  const msgs = useMessages({ prefix: 'base.atoms.baseTextarea' });
  const veeName = computed(() => props.veeLabel || props.label || msgs.of('defaultLabel').value);
  function valid(payload: BaseTextareaAutoGrowValidPayload) {
    emit('valid', payload);
  }
  function input(payload: BaseTextareaAutoGrowInputPayload) {
    emit('input', payload);
  }
  return { required, veeName, valid, input };
}
