import { computed, nextTick, onMounted } from '@vue/composition-api';
import { useRafFn, useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';
import { createLogger } from '@/utils/log';

type EventHandler = (e: Event) => void;
type Position = { x: number; y: number };

const logger = createLogger({ boundedContext: 'base', name: 'DomUtils' });
const isSkip = process.env.VUE_APP_MODE === 'test';

export function parseStyleSize(x: number | string): number {
  if (typeof x === 'number') return x;
  if (x.endsWith('px')) return parseFloat(x.replace('px', ''));
  return 0;
}

export function toStyleSize(x: number | string) {
  return typeof x === 'string' ? x : `${x}px`;
}

export function escapeId(v: string) {
  if (isSkip) return '#id';
  return `#${CSS.escape(v.replace(/^#/, ''))}`;
}

export function getElement(selector: string, isUnescapedId = false) {
  if (isSkip) return null;
  let s = selector;
  if (isUnescapedId) s = escapeId(selector);
  const e = document.querySelector(s);
  if (!e) return undefined;
  return e;
}

export function getElementRect(selector: string, isUnescapedId = false) {
  if (isSkip) return null;
  const e = getElement(selector, isUnescapedId);
  if (!e) return undefined;
  return e.getBoundingClientRect();
}

export function getElements(selection: string) {
  if (isSkip) return [];
  return Array.from(document.querySelectorAll(selection));
}

const AREA_PROPS_EMPTY = {
  areaPaddingTop: 0,
  areaPaddingBottom: 0,
  areaPaddingLeft: 0,
  areaPaddingRight: 0,
  areaTop: 0,
  areaLeft: 0,
  areaHeight: 0,
  areaWidth: 0,
  scrollTop: 0,
  scrollLeft: 0,
  type: 'window' as 'window' | 'element',
};
type AreaProps = typeof AREA_PROPS_EMPTY;

export function getAreaProps(area: Element | null): AreaProps {
  if (isSkip) return AREA_PROPS_EMPTY;
  if (area) {
    const rect = area.getBoundingClientRect();
    const { paddingLeft, paddingRight, paddingTop, paddingBottom } = window.getComputedStyle(area);
    const areaPaddingTop = parseStyleSize(paddingTop);
    const areaPaddingBottom = parseStyleSize(paddingBottom);
    const areaPaddingLeft = parseStyleSize(paddingLeft);
    const areaPaddingRight = parseStyleSize(paddingRight);
    return {
      areaPaddingTop,
      areaPaddingBottom,
      areaPaddingLeft,
      areaPaddingRight,
      areaTop: rect.top,
      areaLeft: rect.left,
      areaHeight: rect.height,
      areaWidth: rect.width,
      scrollTop: area.scrollTop,
      scrollLeft: area.scrollLeft,
      type: 'element',
    };
  }
  return {
    areaPaddingTop: 0,
    areaPaddingBottom: 0,
    areaPaddingLeft: 0,
    areaPaddingRight: 0,
    areaTop: 0,
    areaLeft: 0,
    areaHeight: window.innerHeight,
    areaWidth: window.innerWidth,
    scrollTop: window.scrollY,
    scrollLeft: window.scrollX,
    type: 'window',
  };
}

export function getAreaPropsBy(selector: string): Optional<AreaProps> {
  if (isSkip) return AREA_PROPS_EMPTY;
  const e = document.querySelector(selector);
  if (!e) {
    logger.debug({ message: `element is not found. ${selector}` });
    return undefined;
  }
  return getAreaProps(e);
}

export function getXPathByElement(node: Node, topNode?: Node): string | undefined {
  const paths: string[] = [];
  for (
    let n: Node | null = node;
    n && (n.nodeType === Node.ELEMENT_NODE || n.nodeType === Node.TEXT_NODE);
    n = n.parentNode
  ) {
    let index = 0;
    if (n === topNode) break;

    for (let sibling = n.previousSibling; sibling; sibling = sibling.previousSibling) {
      if (sibling.nodeType !== Node.DOCUMENT_TYPE_NODE) {
        if (sibling.nodeName === n.nodeName) index += 1;
      }
    }

    const tagName = n.nodeType === Node.ELEMENT_NODE ? n.nodeName.toLocaleLowerCase() : 'text()';
    const pathIndex = index ? `[${index + 1}]` : '';
    paths.splice(0, 0, tagName + pathIndex);
  }
  return paths.length ? `/${paths.join('/')}` : undefined;
}

export function getElementByXPath(expression: string, contextNode: Node): Node | null {
  const x = document.evaluate(expression, contextNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE);
  return x.singleNodeValue;
}

export function mergeHandler(ons: Record<string, EventHandler>[]) {
  const handlers = ons.reduce((p, c) => {
    Object.keys(c).forEach((key) => {
      const list = p.get(key);
      if (list) list.push(c[key]);
      else p.set(key, [c[key]]);
    });
    return p;
  }, new Map<string, EventHandler[]>());
  return Array.from(handlers).reduce((p, c) => {
    const [key, value] = c;
    const func = (e: Event) => value.forEach((f) => f(e));
    return Object.assign(p, { [key]: func });
  }, {} as Record<string, EventHandler>);
}

export async function smoothScroll(toX: number, area: HTMLDivElement) {
  if (isSkip) return Promise.resolve();

  const { scrollLeft: sx } = area;
  const { x: areaX } = area.getBoundingClientRect();
  const targetX = toX + sx - areaX;

  function getEaseOut(limit: number) {
    const f = (i: number) => {
      const t = (i + 1) / limit;
      return 2 * t * t;
    };
    return [...Array(limit)].map((_, i) => f(i));
  }

  function getPositions(current: Position): Position[] {
    const amount: Position = { x: 0, y: 0 };
    const xDirection = targetX - current.x > 0 ? 1 : -1;
    const xAmount = Math.abs(targetX - current.x) / 5;
    amount.x = xAmount * xDirection;
    const ret = getEaseOut(5)
      .map((d) => ({ x: d * amount.x, y: d * amount.y }))
      .reduce((p, c) => {
        const [last] = p.slice(-1);
        const x = targetX ? c.x + (last?.x ?? current.x) : current.x;
        p.push({ x, y: current.y });
        return p;
      }, [] as Position[]);
    ret.push({ x: targetX ?? current.x, y: current.y });
    return ret;
  }

  return new Promise<void>((resolve) => {
    const { scrollLeft: currentLeft, scrollTop: currentTop } = area;
    const values = getPositions({ x: currentLeft, y: currentTop });
    const { stop } = useRafFn(() => {
      const pos = values.shift();
      if (!pos) {
        stop();
        return;
      }
      logger.debug({ message: 'smoothScroll', pos });
      area.scrollTo(pos.x, pos.y);
    });
    resolve();
  });
}

type DelayScrollOption = {
  delay?: number;
  times?: number;
  offsetY?: number;
  scrollArea?: Element | { selector: string; isUnescapedId: boolean };
};

export function getFrameHeaderProps() {
  const [e] = document.getElementsByTagName('header');
  const props = getAreaProps(e);
  if (!props) return { height: 0, top: 0 };
  const [p] = document.getElementsByClassName('frame-root-top-prominent-prominent');
  const [x] = document.getElementsByClassName('frame-root-top-prominent-extension');
  return { height: props.areaHeight, top: !p && x ? 20 : 0 };
}

function areaScroll(to: number | string | Element, scrollArea: Element, offsetY = 0) {
  let top = 0;
  if (to === 'top') {
    scrollArea.scrollTo({ top });
  } else if (typeof to === 'number') {
    top = to + offsetY;
    scrollArea.scrollTo({ top });
  } else {
    const props = typeof to === 'string' ? getAreaPropsBy(to) : getAreaProps(to);
    if (!props) {
      logger.debug({ message: 'div.scroll failed. element not found.', to });
      return -1;
    }
    const areaProps = getAreaProps(scrollArea);
    if (!areaProps) return -1;
    top = props.areaTop - areaProps.areaTop + areaProps.scrollTop + offsetY;
    scrollArea.scrollTo({ top });
  }
  return top;
}

function winScroll(to: number | string | Element, offsetY = 0) {
  let top = 0;
  if (to === 'top') {
    const header = getFrameHeaderProps();
    top = header.top;
    window.scrollTo({ top });
  } else if (typeof to === 'number') {
    top = to + offsetY;
    window.scrollTo({ top });
  } else {
    const props = typeof to === 'string' ? getAreaPropsBy(to) : getAreaProps(to);
    if (!props) {
      logger.debug({ message: 'window.scroll failed. element not found.', to });
      return -1;
    }
    const header = getFrameHeaderProps();
    top = props.areaTop + props.areaPaddingTop - header.height + window.scrollY + offsetY;
    window.scrollTo({ top });
  }
  return top;
}

export async function delayScroll(to: number | string | Element, option: DelayScrollOption = {}) {
  if (isSkip) return Promise.resolve();
  return new Promise<void>((resolve) => {
    const delay = option.delay ?? 0;
    const limit = option.times ?? 3;
    useTimeoutFn(() => {
      logger.debug({ message: `delayScroll started. delay=${delay} limit=${limit}`, to, option });

      let area: Element | null | undefined = null;
      if (option.scrollArea) {
        if (option.scrollArea instanceof Element) {
          area = option.scrollArea;
        } else if (option.scrollArea) {
          area = getElement(option.scrollArea.selector, option.scrollArea.isUnescapedId);
        }
        if (!area)
          logger.debug({
            message: `delayScroll scrollArea(${option.scrollArea}) not found.`,
            to,
            option,
          });
      }

      let count = 0;
      let prevY = Math.round(area ? area.scrollTop : window.scrollY);
      const exec = () => {
        count += 1;
        let nowY = -1;
        let top = -1;
        if (area) {
          top = Math.round(areaScroll(to, area, option.offsetY));
          nowY = Math.round(area.scrollTop);
        } else {
          top = Math.round(winScroll(to, option.offsetY));
          nowY = Math.round(window.scrollY);
        }
        logger.debug({
          message: `${to}:[${count}]=${top} ${prevY} > ${nowY}`,
          area: area?.tagName,
        });
        if ((top !== -1 && (top === nowY || prevY === nowY)) || limit <= count) {
          resolve();
          return;
        }
        prevY = nowY;
        useTimeoutFn(exec, 300);
      };
      exec();
    }, delay);
  });
}

export function useScreen() {
  const isTouchScreen = computed(() => navigator.maxTouchPoints > 0);
  const overflowY = computed(() => (isTouchScreen.value ? 'overflow-y-auto' : 'scroll-hover'));
  return { isTouchScreen, overflowY };
}

export type FuncSaveScrollPositionPageChanged = (
  newPage: Optional<string>,
  nowPage: Optional<string>
) => void;

export function useSaveScrollPosition(
  props: { disabledWindowScroll?: boolean },
  options: { className: string; saveScrollPosition: boolean },
  onChange?: (fn: FuncSaveScrollPositionPageChanged) => void
) {
  const positions: { page: string; y: number }[] = [];
  let skip = false;

  function preventScroll() {
    skip = true;
  }
  function allowScroll() {
    skip = false;
  }

  function clearScrollPositions() {
    positions.splice(0);
  }

  function set(newPage: Optional<string>, nowPage: Optional<string>) {
    if (!options.saveScrollPosition) {
      if (props.disabledWindowScroll || skip) return;
      nextTick(() => delayScroll('top'));
      return;
    }
    if (nowPage) {
      const y = Math.round(window.scrollY);
      logger.debug({ message: 'useSaveScrollPosition.save', nowPage, y });
      const p = positions.find((item) => item.page === nowPage);
      if (p) p.y = y;
      else positions.push({ page: nowPage, y });
    }
    if (newPage && !props.disabledWindowScroll && !skip) {
      const p = positions.find((item) => item.page === newPage);
      logger.debug({ message: 'useSaveScrollPosition.scroll', newPage, p });
      if (p) delayScroll(p.y, { delay: 100 });
      else delayScroll('top', { delay: 100 });
    }
  }

  onMounted(() => {
    if (onChange) onChange(set);
  });

  return { clearScrollPositions, preventScroll, allowScroll };
}
