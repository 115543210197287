






































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import NotificationBar from '../molecules/NotificationBar.vue';
import MentionMarkdown from './MentionMarkdown.vue';
import { NotificationItem, PropsNotifications, useNotifications } from './NotificationsComposable';

type Props = PropsNotifications;

export default defineComponent({
  name: 'BaseNotifications',
  components: { MentionMarkdown, NotificationBar },
  props: {
    notifications: { type: Array as PropType<NotificationItem[]>, default: () => [] },
    omit: { type: Boolean, default: false },
    hideNoData: { type: Boolean, default: false },
    width: { type: String, default: 'min(600px, 90vw)' },
  },
  emits: ['click', 'click-anchor', 'timeout', 'move'],
  setup(props: Props, { emit }: SetupContext) {
    return useNotifications(props, emit);
  },
});
