import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';

export type BaseTextareaAutoGrowValidPayload = boolean;

export type BaseTextareaAutoGrowInputPayload = Optional<string>;

export type PropsBaseTextareaAutoGrow = {
  value?: string;
  height?: string;
  maxHeight?: string;
  errorMessages: string[];
  hideDetails: string | boolean;
  overflowDetails: boolean;
};

export function useBaseTextareaAutoGrow(
  props: PropsBaseTextareaAutoGrow,
  emit: (
    name: string,
    arg: BaseTextareaAutoGrowValidPayload | BaseTextareaAutoGrowInputPayload
  ) => void
) {
  const textareaAutoGrow = ref<HTMLDivElement>();
  function findTextArea(className: string) {
    if (!textareaAutoGrow.value) return undefined;
    const [vTextarea] = textareaAutoGrow.value.getElementsByClassName(className);
    if (!vTextarea) return undefined;
    const [textarea] = vTextarea.getElementsByTagName('textarea');
    return textarea;
  }

  function setupHeight(delay = 1) {
    nextTick(() =>
      useTimeoutFn(() => {
        const hidden = findTextArea('base-textarea-auto-grow-hidden');
        const main = findTextArea('base-textarea-auto-grow-main');
        if (!hidden || !main) return;
        const fit = `calc(${hidden.style.height} + 2px)`;
        let ret = '';
        if (!props.height && !props.maxHeight) {
          ret = fit;
        } else if (props.height && props.maxHeight) {
          if (props.height === props.maxHeight) ret = props.height;
          else ret = `min(max(${fit}, ${props.height}), ${props.maxHeight})`;
        } else if (props.height) {
          ret = `max(${fit}, ${props.height})`;
        } else if (props.maxHeight) {
          ret = `min(${fit}, ${props.maxHeight})`;
        }
        if (main.style.height === ret) return;
        main.style.height = ret;
      }, delay)
    );
  }

  const input = ref<string>();
  function inputText(v?: string) {
    input.value = v;
    setupHeight();
    emit('input', v);
  }

  function init() {
    input.value = props.value;
    setupHeight();
  }
  onMounted(init);
  watch(() => props.value, init);
  watch(
    () => props.errorMessages,
    (newVal) => {
      setupHeight();
      emit('valid', newVal.length === 0);
    }
  );

  const firstErrorMessage = computed(() => props.errorMessages[0]);
  return { input, inputText, textareaAutoGrow, firstErrorMessage, setupHeight };
}
