















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import {
  BaseDialogFullScreenValue,
  PropsBaseDialogFullScreen,
  useBaseDialogFullScreen,
} from './BaseDialogFullScreenComposable';
import BaseDialogOk from './BaseDialogOk.vue';
import BaseToast from './BaseToast.vue';
import UpdateStatusIcon from './UpdateStatusIcon.vue';

type Props = PropsBaseDialogFullScreen;

export default defineComponent({
  name: 'BaseBaseDialogFullScreen',
  components: { BaseButtonIcon, UpdateStatusIcon, BaseToast, BaseDialogOk },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<BaseDialogFullScreenValue>, required: true },
    delay: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: undefined },
    subtitle: { type: String, default: undefined },
  },
  emits: ['change', 'closed'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseDialogFullScreen(props, emit);
  },
});
