































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { useBaseDialogConfirm } from './BaseDialogConfirmComposable';

export default defineComponent({
  name: 'BaseBaseDialogConfirm',
  components: { BaseButton, BaseMarkdown },
  inheritAttrs: false,
  props: { loading: { type: Boolean, default: false } },
  emits: ['ok', 'cancel', 'click-anchor'],
  setup(_, { emit }: SetupContext) {
    return useBaseDialogConfirm(emit);
  },
});
