import { Location, Route } from 'vue-router';

import { Optional } from '@/base/types';
import { useRoute, useRouter } from '@/utils/VueUtils';

/**
 * フルスクリーンダイアログのキー.
 * valueが重複しないのように設定する
 */
export const DialogName = {
  ACCOUNT_USER_TAGS: 'userTags',
  BASE_SETTINGS: 'settings',
  BASE_USER_AVATAR: 'userAvatar',
  BASE_NOTIFICATIONS: 'notifications',
  BASE_QUESTIONNAIRE: 'questionnaire',
  BASE_QUESTIONNAIRE_ANS: 'questionnaireAns',
  CONTENTS_CONTENT_DESCRIPTION: 'contentDsc',
  CONTENTS_CONTENT_LIST: 'contents',
  CONTENTS_COURSE_BACKGROUND: 'courseBg',
  CONTENTS_EXAM_BODY: 'examBody',
  CONTENTS_HEADER: 'contentHeader',
  CONTENTS_PROBLEM_LIST: 'problems',
  CONTENTS_TEXT_BODY: 'textBody',
  CONTENTS_WORKBOOK_PROBLEM: 'workbook',
  REPORT_USER_USAGE_GROUP_COURSES: 'userUsages#1',
  REPORT_USER_USAGE_GROUP_EXAMS: 'userUsages#2',
  REPORT_USER_USAGE_TRAINEE_PROGRESS: 'userUsages#3',
  REPORT_USER_USAGE_TRAINEE_EXAM: 'userUsages#4',
  TRAINING_CONTENT_MEMO: 'contentMemo',
  TRAINING_CONTENT_QUESTION: 'contentQuestion',
  TRAINING_CONTENT_QUESTION_SRC: 'contentQuestionSrc',
  TRAINING_COURSE_LIST: 'trainingCourses',
  TRAINING_GROUP_EXAM: 'groupExam',
  TRAINING_GROUP_EXAM_OPEN: 'groupExamOpen',
  TRAINING_SCHEDULE: 'schedule',
  TRAINING_SCHEDULE_TAGS: 'scheduleTags',
} as const;

export type DialogName = typeof DialogName[keyof typeof DialogName];

export type DialogQuery = {
  to: DialogName;
  params?: Record<string, string>;
};

function toDialogName(x: unknown): DialogName | undefined {
  if (!x || typeof x !== 'string') return undefined;
  if (!(Object.values(DialogName) as string[]).includes(x)) return undefined;
  return x as DialogName;
}

function toLocation(q: DialogQuery, route: Route, saveFrom = true): Location {
  // 遷移元がダイアログの場合、元のクエリをf(from)に格納
  const f = 'd' in route.query && saveFrom ? route.query.d : undefined;
  // ダイアログのパラメータ t(to), p(params), f(from)
  const d = JSON.stringify({ t: q.to, p: q.params, f });
  const query = { ...route.query, d };
  return {
    path: route.path,
    params: route.params,
    hash: route.hash,
    query,
  };
}

export function clearDialogQuery(route: Route, hash?: string): Optional<Location> {
  const query = { ...route.query };
  if ('d' in query) {
    let d: string | undefined;
    if (typeof query.d === 'string') {
      try {
        const { f } = JSON.parse(query.d);
        // 遷移元がダイアログの場合、f(from)をクエリに復元
        if (typeof f === 'string') d = f;
      } catch (e) {
        // no action
      }
    }
    if (d) query.d = d;
    else delete query.d;
    return {
      name: route.name ?? undefined,
      path: route.path,
      params: route.params,
      hash: hash ?? route.hash,
      query,
    };
  }
  return undefined;
}

function includeDialog(target: string, dialogs: (DialogName | string)[]) {
  const n = toDialogName(target);
  if (!n) return false;
  return dialogs.some((d) => d === n);
}

function parseQuery(x: unknown, dialogs?: (DialogName | string)[], checkFrom = false) {
  if (x === undefined || typeof x !== 'string') return undefined;
  const { t: to, p: params, f: from } = JSON.parse(x);
  if (dialogs && !includeDialog(to, dialogs)) {
    if (checkFrom) return parseQuery(from, dialogs, checkFrom);
    return undefined;
  }
  return { to, params };
}

function isDialogQuery(
  route: Route,
  dialogs?: DialogName[],
  checkFrom = false
): Optional<DialogQuery> {
  if (!route.query || !('d' in route.query)) return undefined;
  try {
    return parseQuery(route.query.d, dialogs, checkFrom);
  } catch (e) {
    return undefined;
  }
}

export function isDialogQueryString(route: Route): Optional<string> {
  const ret = isDialogQuery(route);
  if (ret) return route.query.d as 'string';
  return undefined;
}

export function useDialogQuery(name: DialogName) {
  const route = useRoute();
  const router = useRouter();
  function getQuery(): Optional<DialogQuery> {
    return isDialogQuery(route, [name]);
  }
  function getLocation(params?: Record<string, string>, saveFrom?: boolean) {
    return toLocation({ to: name, params }, route, saveFrom);
  }
  function moveTo(params?: Record<string, string>, saveFrom = true) {
    router.push(getLocation(params, saveFrom));
  }
  function existsQuery() {
    const q = isDialogQuery(route, [name], true);
    return q !== undefined;
  }
  return { getQuery, getLocation, moveTo, existsQuery };
}
