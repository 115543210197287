
















































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import {
  BaseSelectSearchItem,
  BaseSelectSearchValue,
  PropsBaseSelectSearchSelect,
  useBaseSelectSearch,
} from './BaseSelectSearchComposable';

type Props = PropsBaseSelectSearchSelect<{ id: string }>;

export default defineComponent({
  name: 'BaseSelectSearch',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Array] as PropType<BaseSelectSearchValue>, default: undefined },
    items: { type: Array as PropType<BaseSelectSearchItem<{ id: string }>[]>, default: () => [] },
    multiple: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    menuAttrs: { type: Object, default: () => ({ 'offset-y': true, 'nudge-bottom': 0 }) },
    activatorAttrs: { type: Object, default: () => ({ height: 39 }) },
    listAttrs: { type: Object, default: () => ({ 'max-height': 'calc(80vh - 100px)' }) },
    dataCy: { type: String, default: undefined },
  },
  emits: ['change', 'search', 'close'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseSelectSearch(props, emit);
  },
});
