























































import { defineComponent } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseDialogConfirm from '../molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '../molecules/BaseDialogFullScreen.vue';
import QuestionnaireConfirmFinishDialog from '../molecules/QuestionnaireConfirmFinishDialog.vue';
import QuestionnaireForm from '../molecules/QuestionnaireForm.vue';
import QuestionnaireProgress from '../molecules/QuestionnaireProgress.vue';
import DialogAnchorConfirm from './DialogAnchorConfirm.vue';
import { useDialogQuestionnaire } from './DialogQuestionnaireComposable';
import Loading from './Loading.vue';

export default defineComponent({
  name: 'BaseDialogQuestionnaire',
  components: {
    BaseDialogFullScreen,
    BaseButton,
    BaseDialogConfirm,
    Loading,
    QuestionnaireProgress,
    QuestionnaireForm,
    DialogAnchorConfirm,
    QuestionnaireConfirmFinishDialog,
  },
  setup() {
    return useDialogQuestionnaire();
  },
});
