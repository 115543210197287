








































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GlobalStoreGroupUser } from '../../store/types';
import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseText from '../atoms/BaseText.vue';
import {
  PropsQuestionnaireForm,
  QuestionnaireFormValue,
  useQuestionnaireForm,
} from './QuestionnaireFormComposable';
import TextEditor from './TextEditor.vue';
import UserSelect from './UserSelect.vue';

type Props = PropsQuestionnaireForm;

export default defineComponent({
  name: 'BaseQuestionnaireForm',
  components: {
    BaseText,
    TextEditor,
    BaseButtonIcon,
    UserSelect,
  },
  inheritAttrs: false,
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<QuestionnaireFormValue>, required: true },
    users: { type: Array as PropType<GlobalStoreGroupUser[]>, default: () => [] },
  },
  emits: ['submit', 'toast'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionnaireForm(props, emit);
  },
});
