import { computed, ComputedRef, onMounted, ref, watch } from '@vue/composition-api';

import { Optional } from '@/base/types';
import { UploadFileType } from '@/base/usecases';
import { config } from '@/config';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../../Messages';
import { GlobalStoreGroup, GlobalStoreUser } from '../../store/types';
import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownClickMentionPayload,
} from '../atoms/BaseMarkdownComposable';
import { useTextEditorFileUpload } from './TextEditorFileUpload';
import { useTextEditorMention } from './TextEditorMention';
import {
  Mention,
  TextEditorMentionsDialog,
  TextEditorMentionsDialogSelectPayload,
} from './TextEditorMentionsDialogComposable';

function useMentionsDialog(priorities: ComputedRef<Mention[]>, mentions: ComputedRef<Mention[]>) {
  const dialog = ref<TextEditorMentionsDialog>();
  function open() {
    assertIsDefined(dialog.value);
    dialog.value.open({ priorities: priorities.value, mentions: mentions.value });
  }
  return { dialog, open };
}

export type TextEditorValue = Optional<string>;

export type TextEditorClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export type TextEditorClickUserPayload = BaseMarkdownClickMentionPayload & {
  groupId?: string;
};

export type PropsTextEditor = {
  value?: string;
  noDataText?: string;
  uploadFileType: UploadFileType;
  disabled: boolean;
  hidePreview: boolean;
  hideUpload: boolean;
  group?: GlobalStoreGroup;
  priorityMentions?: string[];
  findUser?: (id: string) => Optional<GlobalStoreUser>;
};

export function useTextEditor(
  props: PropsTextEditor,
  emit: (
    name: string,
    args: TextEditorValue | TextEditorClickAnchorPayload | TextEditorClickUserPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.textEditor' });

  const input = ref<string>();
  const valid = ref();

  const textEditor = ref<HTMLDivElement>();
  function getTextArea() {
    if (!textEditor.value) return undefined;
    const [area] = textEditor.value.getElementsByTagName('textarea');
    return area;
  }

  function getListMentions() {
    const [el] = document.getElementsByClassName('base-text-editor-mentions');
    return el;
  }

  const { uploading, uploadItems, prepareUpload, cancelUpload, clearUpload } =
    useTextEditorFileUpload(
      { disabled: props.hideUpload, type: props.uploadFileType, input, getTextArea },
      emit
    );

  const fileSelector = ref<HTMLInputElement>();
  function openFileSelector() {
    assertIsDefined(fileSelector.value, 'fileSelector');
    fileSelector.value.click();
  }

  function selectFile() {
    assertIsDefined(fileSelector.value, 'fileSelector');
    const { files } = fileSelector.value;
    if (!files || files.length === 0) return;
    prepareUpload(Array.from(files), false);
    fileSelector.value.value = '';
  }

  const {
    forceShowDialog,
    mentionMenu,
    mentionEnabled,
    selectableMentions,
    mentions,
    priorities,
    findMention,
    savePosition,
    changeMentionMenu,
    selectMention,
    addMentionBy,
    onInputMention,
    onKeyDownMention,
    toMentionLabel,
    toMentionId,
    clearMention,
  } = useTextEditorMention(
    {
      group: props.group,
      priorityMentions: computed(() => props.priorityMentions),
      findUser: props.findUser,
      input,
      getTextArea,
      getListMentions,
    },
    emit
  );

  function init() {
    input.value = toMentionLabel(props.value);
  }
  onMounted(init);
  watch(() => props.value, init);

  const { dialog, open: openDialog } = useMentionsDialog(priorities, mentions);
  function selectMentionId(payload: TextEditorMentionsDialogSelectPayload) {
    addMentionBy(payload.id);
  }

  const preview = ref(false);
  function togglePreview() {
    preview.value = !preview.value;
  }

  function openMentions(n?: number) {
    let pos = n;
    if (pos === undefined) {
      const area = getTextArea();
      if (!area) return;
      pos = area.selectionEnd;
    }
    savePosition(pos);
    openDialog();
  }

  function onInput(v?: string) {
    if (onInputMention(v, openMentions)) {
      input.value = v;
    }
  }

  function onChange(v?: string) {
    emit('change', toMentionId(v));
  }

  function onPaste(evt: ClipboardEvent) {
    if (props.hideUpload) return;
    const files = evt.clipboardData?.files;
    if (!files || files.length === 0) return;
    evt.stopPropagation();
    evt.preventDefault();
    prepareUpload(Array.from(files), true);
  }

  const dragging = ref(false);
  function onDragOver(evt: Event) {
    if (props.hideUpload) return;
    evt.preventDefault();
    dragging.value = true;
  }
  function onDragLeave(evt: Event) {
    if (props.hideUpload) return;
    evt.preventDefault();
    dragging.value = false;
  }
  function onDrop(evt: DragEvent) {
    if (props.hideUpload) return;
    evt.stopPropagation();
    evt.preventDefault();
    dragging.value = false;
    const files = evt.dataTransfer?.files;
    if (!files || files.length === 0) return;
    prepareUpload(Array.from(files), true);
  }

  function onKeyDown(evt: KeyboardEvent) {
    onKeyDownMention(evt);
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickUser(payload: BaseMarkdownClickMentionPayload) {
    emit('click-user', { ...payload, groupId: props.group?.id });
  }

  function reset() {
    clearMention();
    clearUpload();
  }

  const on = computed(() => {
    if (props.disabled) return undefined;
    const handlers: Record<string, unknown> = {};
    if (!props.hideUpload) {
      handlers.paste = onPaste;
      handlers.dragover = onDragOver;
      handlers.dragleave = onDragLeave;
      handlers.drop = onDrop;
    }
    if (mentionEnabled.value) {
      handlers.keydown = onKeyDown;
    }
    if (Object.keys(handlers).length === 0) return undefined;
    return handlers;
  });

  const labelNoData = computed(() => props.noDataText || msgs.of('noData').value);
  return {
    input,
    valid,
    textEditor,
    fileSelector,
    forceShowDialog,
    mentionEnabled,
    mentionMenu,
    mentions: selectableMentions,
    findMention,
    uploading,
    uploadItems,
    dragging,
    dialog,
    preview,
    labelNoData,
    labelMention: msgs.of('mention'),
    labelUpload: msgs.of('upload'),
    labelEdit: msgs.of('edit'),
    labelPreview: msgs.of('preview'),
    labelTutorial: msgs.of('tutorial'),
    hrefTutorial: config().app.editorTutorialUrl,
    uploadInProgressMessage: msgs.of('uploadInProgress'),
    on,
    openFileSelector,
    selectFile,
    changeMentionMenu,
    selectMention,
    cancelUpload,
    openMentions,
    selectMentionId,
    togglePreview,
    onInput,
    onChange,
    clickAnchor,
    clickUser,
    reset,
  };
}

export type TextEditor = ReturnType<typeof useTextEditor>;
