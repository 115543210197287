import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { Questionnaire } from '@/base/domains';

import { toQuestionnaireOptions } from '../../utils/QuestionnaireUtils';
import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';
import { QuestionnaireRespondentTableItem } from './QuestionnaireRespondentTableComposable';

export type QuestionnaireProgressRespondent = QuestionnaireRespondentTableItem;

export type PropsQuestionnaireProgress = {
  questionnaire: Questionnaire;
  respondent: QuestionnaireProgressRespondent[];
};

export type QuestionnaireProgressClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useQuestionnaireProgress(
  props: PropsQuestionnaireProgress,
  emit: (name: string, arg: QuestionnaireProgressClickAnchorPayload) => void
) {
  const isQuestionnaireOpen = ref(false);

  const options = computed(() => toQuestionnaireOptions(props.questionnaire.options));

  const isNoTextAndOptions = computed(() => {
    const [first] = props.questionnaire.options;
    return !first && !props.questionnaire.text;
  });

  const noOptions = computed(() => props.questionnaire.options.every((o) => o !== ''));

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'base.molecules.questionnaireProgress' });
  return {
    options,
    noOptions,
    isQuestionnaireOpen,
    isNoTextAndOptions,
    labelQuestionnaire: msgs.of('questionnaire'),
    labelNoTextAndOptions: msgs.of('noTextAndOptions'),
    clickAnchor,
  };
}
