export type PropsMenuWrapper = {
  value: boolean;
};

export type MenuWrapperChangePayload = boolean;

export function useMenuWrapper(
  props: PropsMenuWrapper,
  emit: (name: string, arg: MenuWrapperChangePayload) => void
) {
  function toggle() {
    emit('change', !props.value);
  }
  return { toggle };
}
