



















import { computed, defineComponent, PropType } from '@vue/composition-api';

import {
  SlackAppType,
  SlackGroupExtensionConfig,
  SlackUserExtensionConfig,
} from '@/base/domains/extensions/slack';

import { useMessages } from '../../Messages';
import BaseButtonVertical from '../atoms/BaseButtonVertical.vue';

type Props = {
  group?: SlackGroupExtensionConfig;
  user?: SlackUserExtensionConfig;
};

type Control = {
  label: string;
  icon: string;
  href?: string;
};

function hrefUser(
  appType?: SlackAppType,
  group?: SlackGroupExtensionConfig,
  user?: SlackUserExtensionConfig
) {
  if (!appType || !group || !user) return undefined;
  switch (appType) {
    case 'native':
      return `slack://user?team=${group.teamId}&id=${user.userId}`;
    case 'web':
      return `https://app.slack.com/client/${group.teamId}/${group.channelIds[0]}/user_profile/${user.userId}`;
    default:
      return undefined;
  }
}

function hrefChannel(appType?: SlackAppType, group?: SlackGroupExtensionConfig) {
  if (!appType || !group) return undefined;
  switch (appType) {
    case 'native':
      return `slack://channel?team=${group.teamId}&id=${group.channelIds[0]}`;
    case 'web':
      return `https://app.slack.com/client/${group.teamId}/${group.channelIds[0]}`;
    default:
      return undefined;
  }
}

export default defineComponent({
  name: 'BaseUserExtensionSlack',
  components: { BaseButtonVertical },
  props: {
    group: { type: Object as PropType<SlackGroupExtensionConfig>, default: undefined },
    user: { type: Object as PropType<SlackUserExtensionConfig>, default: undefined },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.molecules.userExtensionSlack' });

    const appType = computed(() => props.user?.appType || props.group?.appType);
    const controls = computed(() => {
      const ret: Control[] = [];

      ret.push({
        label: msgs.of('personal').value,
        icon: 'mdi-chat',
        href: hrefUser(appType.value, props.group, props.user),
      });
      ret.push({
        label: msgs.of('channel').value,
        icon: 'mdi-account-group',
        href: hrefChannel(appType.value, props.group),
      });

      return ret;
    });
    return { appType, controls };
  },
});
