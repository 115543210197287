























import { computed, defineComponent } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import { useMessages } from '../../Messages';

type Props = {
  groupRole?: GroupRole;
  adminMode: boolean;
};

export default defineComponent({
  name: 'BaseGroupIconBelong',
  props: {
    groupRole: { type: String, default: undefined },
    adminMode: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.atoms.groupIconBelong' });
    const label = computed(() => {
      if (props.adminMode) return msgs.of('adminMode').value;
      if (props.groupRole) return msgs.of(props.groupRole).value;
      return msgs.of('notBelongToGroup').value;
    });
    return { label };
  },
});
