import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { useRafFn } from '@vueuse/core';

import { NotificationReference } from '@/base/domains';
import {
  AddCommentToQuestionNotification,
  AddGroupUserNotification,
  AskQuestionNotification,
  CreateGroupMemoNotification,
  CreateQuestionnaireNotification,
  EditCommentOnQuestionNotification,
  ExamReminderNotification,
  ResolveQuestionNotification,
  StartExamNotification,
  UpdateGroupMemoNotification,
} from '@/base/NotificationTypes';
import { Optional } from '@/base/types';

import { NotificationBarExamClickPayload } from '../atoms/NotificationBarExamComposable';
import { NotificationBarMemoClickPayload } from '../atoms/NotificationBarMemoComposable';
import { NotificationBarOnGooglePlay } from '../atoms/NotificationBarOnGooglePlayComposable';
import { NotificationBarQuestionClickPayload } from '../atoms/NotificationBarQuestionComposable';
import { NotificationBarQuestionnaireClickPayload } from '../atoms/NotificationBarQuestionnaireComposable';
import { UserAvatar } from '../atoms/UserComposable';

function useTimeout(props: { timeout?: number }, done: () => void) {
  let startedAt: Optional<number>;

  const progress = ref<number>();

  const { start, stop } = useRafFn(
    () => {
      if (props.timeout === undefined || startedAt === undefined) {
        stop();
        return;
      }
      const total = props.timeout * 1000;
      const remain = total - (Date.now() - startedAt);
      progress.value = (remain / total) * 100;
      if (progress.value <= 0) {
        done();
        stop();
      }
    },
    { startNow: false }
  );

  function init() {
    if (props.timeout === undefined) return;
    startedAt = Date.now();
    start();
  }
  onMounted(init);
  onUnmounted(stop);

  return { progress };
}

type NotificationLimitOver = {
  id: string;
  type: 'SYSTEM_LIMIT_OVER';
  payload: { body: string };
};

export type NotificationBar =
  | NotificationReference
  | NotificationLimitOver
  | NotificationBarOnGooglePlay;

export type NotificationBarTimeoutPayload = {
  id: string;
};
export type NotificationBarClickPayload = Pick<NotificationBar, 'id' | 'type'>;
export type NotificationBarMovePayload =
  | NotificationBarMemoClickPayload
  | NotificationBarQuestionClickPayload
  | NotificationBarQuestionnaireClickPayload
  | NotificationBarExamClickPayload;

export type PropsNotificationBar = {
  notification: NotificationBar;
  sender: UserAvatar;
  timeout?: number;
};

export function useNotificationBar(
  props: PropsNotificationBar,
  emit: (
    name: string,
    args: NotificationBarTimeoutPayload | NotificationBarMovePayload | NotificationBarClickPayload
  ) => void
) {
  const unread = computed(
    () => 'read' in props.notification && props.notification.read === 'unread'
  );

  const bodyComponent = computed(() => {
    switch (props.notification.type) {
      case AskQuestionNotification:
      case AddCommentToQuestionNotification:
      case EditCommentOnQuestionNotification:
      case ResolveQuestionNotification:
        return 'notification-bar-question';
      case CreateQuestionnaireNotification:
        return 'notification-bar-questionnaire';
      case CreateGroupMemoNotification:
      case UpdateGroupMemoNotification:
        return 'notification-bar-memo';
      case StartExamNotification:
      case ExamReminderNotification:
        return 'notification-bar-exam';
      case AddGroupUserNotification:
        return 'notification-bar-group-user';
      case 'SYSTEM_ON_GOOGLE_PLAY':
        return 'notification-bar-on-google-play';
      default:
    }
    return undefined;
  });

  function click() {
    emit('click', { id: props.notification.id, type: props.notification.type });
  }

  function clickLink(
    payload:
      | NotificationBarQuestionClickPayload
      | NotificationBarQuestionnaireClickPayload
      | NotificationBarMemoClickPayload
      | NotificationBarExamClickPayload
  ) {
    emit('move', payload);
  }

  function timeout() {
    emit('timeout', { id: props.notification.id });
  }

  return { unread, bodyComponent, click, clickLink, ...useTimeout(props, timeout) };
}
