

































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GlobalStoreUser } from '../../store/types';
import BaseButton from '../atoms/BaseButton.vue';
import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseButtonVertical from '../atoms/BaseButtonVertical.vue';
import User from '../atoms/User.vue';
import {
  PropsFrameAccountMenuDialog,
  useFrameAccountMenuDialog,
} from './FrameAccountMenuDialogComposable';

type Props = PropsFrameAccountMenuDialog;

export default defineComponent({
  name: 'BaseFrameAccountMenuDialog',
  components: { User, BaseButton, BaseButtonIcon, BaseButtonVertical },
  inheritAttrs: false,
  props: {
    user: { type: Object as PropType<GlobalStoreUser>, required: true },
    frameMode: { type: String, required: true },
    admin: { type: Boolean, default: false },
    width: { type: String, default: '300px' },
  },
  emits: ['toggle-frame-mode', 'toggle-admin-control', 'sign-out'],
  setup(props: Props, { emit }: SetupContext) {
    return useFrameAccountMenuDialog(props, emit);
  },
});
