import { computed } from '@vue/composition-api';
import { Location } from 'vue-router';

import { NotificationReference } from '@/base/domains';
import {
  CreateQuestionnaireNotification,
  CreateQuestionnaireNotificationPayload,
} from '@/base/NotificationTypes';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../..';
import { DialogName, useDialogQuery } from '../../utils/DialogQueryUtils';

export type NotificationBarQuestionnaireClickPayload = {
  id: string;
  to: Location;
};

export type PropsNotificationBarQuestionnaire = {
  notification: NotificationReference;
  omit: boolean;
};

export function useNotificationBarQuestionnaire(
  props: PropsNotificationBarQuestionnaire,
  emit: (name: string, args: NotificationBarQuestionnaireClickPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarQuestionnaire' });
  const { getLocation } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE_ANS);

  function parseCreateQuestionnaire(n: NotificationReference) {
    const { payload } = n.asTypeOf<CreateQuestionnaireNotificationPayload>(n.type);
    const description = msgs.of('active', { title: payload.title }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.title,
      to: getLocation({ id: payload.questionnaireId }),
    };
    return { description, group, link };
  }

  const data = computed(() => {
    const n = props.notification;
    switch (n.type) {
      case CreateQuestionnaireNotification:
        return parseCreateQuestionnaire(n);
      default:
    }
    return undefined;
  });

  function click(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    assertIsDefined(data.value?.link.to, 'to');
    emit('click', { id: props.notification.id, to: data.value.link.to });
  }

  return { data, click };
}
