





















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { PropsBaseToast, useBaseToast } from './BaseToastComposable';

type Props = PropsBaseToast;

export default defineComponent({
  name: 'BaseBaseToast',
  components: { BaseMarkdown },
  props: {
    absolute: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false },
    top: { type: String, default: undefined },
    bottom: { type: String, default: undefined },
    right: { type: String, default: undefined },
    left: { type: String, default: undefined },
    error: { type: Boolean, default: false },
  },
  emits: ['show', 'hide'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseToast({ ...props, emit });
  },
});
