import { computed, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { Optional } from '@/base/types';

import { useMessages } from '../../Messages';

export type UpdateStatusIcon = Optional<'updated' | 'updating' | 'changed'>;

export type UpdateStatusIconChangePayload = UpdateStatusIcon;

export type PropsUpdateStatusIcon = {
  status: UpdateStatusIcon;
  readonly: boolean;
};

export function useUpdateStatusIcon(
  props: PropsUpdateStatusIcon,
  emit: (name: string, arg: UpdateStatusIconChangePayload) => void
) {
  function clear(delay = 3000) {
    if (props.status !== 'updated') return;
    useTimeoutFn(() => {
      if (props.status === 'updated') emit('change', undefined);
    }, delay);
  }
  watch(
    () => props.status,
    () => clear()
  );

  const msgs = useMessages({ prefix: 'base.molecules.updateStatusIcon' });
  const labelSaved = computed(() => (props.status === 'updated' ? msgs.of('saved').value : ''));
  return { labelSaved, labelUnEditable: msgs.of('unEditable'), labelEditing: msgs.of('editing') };
}
