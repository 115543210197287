import { ref } from '@vue/composition-api';

import { useMessages } from '../..';

export function useFrameMainMenuDialog() {
  const msgs = useMessages({ prefix: 'base.molecules.frameMainMenuDialog' });

  const dialog = ref(false);
  function close() {
    dialog.value = false;
  }

  return {
    dialog,
    labelClose: msgs.of('close'),
    close,
  };
}
