import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { uuid } from '@/utils/UniqueIdGenerator';

import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import {
  NotificationItem,
  NotificationsClickPayload,
  NotificationsTimeoutPayload,
} from './NotificationsComposable';

const INTERVAL = 300;
const DEFAULT_TIMEOUT = 5;

export type PropsToastNotifications = {
  limit: number;
  hide: boolean;
};

export function useToastNotifications(
  props: PropsToastNotifications,
  emit: (name: string) => void
) {
  const msgs = useMessages({ prefix: 'base.organisms.toastNotifications' });
  const { notifications, notificationTimer } = useGlobalStore();

  const cached = ref<NotificationItem[]>([]);
  function exists(id: string) {
    return cached.value.find((item) => item.notification.id === id);
  }

  function append() {
    if (window.$androidWebAppBridge) return false;
    const items = notifications.value.filter((n) => !exists(n.id));
    if (props.limit > 0 && items.length > props.limit) {
      items.forEach((item) => notificationTimer.clear(item.id));
      cached.value = [
        ...cached.value,
        ...items.map((item) => ({ notification: item, hide: true })),
        {
          notification: {
            id: uuid(),
            type: 'SYSTEM_LIMIT_OVER',
            payload: { body: msgs.of('limitOver', { size: items.length }).value },
          },
          timeout: DEFAULT_TIMEOUT,
          hide: false,
        },
      ];
      return false;
    }
    const first = items.shift();
    if (!first) return false;
    cached.value = [
      ...cached.value,
      { notification: first, timeout: DEFAULT_TIMEOUT, hide: false },
    ];
    return items.length > 0;
  }
  const { start, stop } = useTimeoutFn(() => {
    const next = append();
    if (next) start();
  }, INTERVAL);

  onMounted(() => {
    if (props.hide) return;
    notificationTimer.start();
    start();
  });
  onUnmounted(() => {
    stop();
    notificationTimer.stop();
  });

  watch(
    () => props.hide,
    (newValue) => {
      if (newValue) {
        stop();
        notificationTimer.stop();
      } else {
        notificationTimer.start();
        start();
      }
    }
  );

  watch(notifications, () => {
    if (notifications.value.length === 0) return;
    start();
  });

  function timeout(payload: NotificationsTimeoutPayload) {
    const item = exists(payload.id);
    if (item) {
      item.hide = true;
      if (item.notification.type !== 'SYSTEM_LIMIT_OVER') notificationTimer.clear(payload.id);
    }
  }

  function click(payload: NotificationsClickPayload) {
    timeout(payload);
    if (typeof payload.type !== 'string' || payload.type === 'SYSTEM_LIMIT_OVER') {
      emit('open-notifications');
    }
  }

  function notice(item: Omit<NotificationItem, 'hide'>) {
    cached.value = [...cached.value, { ...item, hide: false }];
  }

  return { items: cached, click, timeout, notice };
}

export type ToastNotifications = ReturnType<typeof useToastNotifications>;
