import { computed } from '@vue/composition-api';

import {
  AddCommentToQuestionNotification,
  AddCommentToQuestionNotificationPayload,
  AddGroupUserNotification,
  AddGroupUserNotificationPayload,
  AskQuestionNotification,
  AskQuestionNotificationPayload,
  CreateGroupMemoNotification,
  CreateGroupMemoPayload,
  CreateQuestionnaireNotification,
  CreateQuestionnaireNotificationPayload,
  EditCommentOnQuestionNotification,
  EditCommentOnQuestionNotificationPayload,
  ResolveQuestionNotification,
  ResolveQuestionNotificationPayload,
  StartExamNotification,
  StartExamNotificationPayload,
  UpdateGroupMemoNotification,
  UpdateGroupMemoPayload,
} from '@/base/NotificationTypes';

import { useMessages } from '../..';
import { useGlobalStore } from '../../store';
import { createUserAvatar } from '../atoms/UserComposable';
import {
  NotificationBar,
  NotificationBarClickPayload,
  NotificationBarMovePayload,
  NotificationBarTimeoutPayload,
} from '../molecules/NotificationBarComposable';
import { MentionMarkdownClickAnchorPayload } from './MentionMarkdownComposable';

export type NotificationsClickPayload = NotificationBarClickPayload;
export type NotificationsMovePayload = NotificationBarMovePayload;
export type NotificationsClickAnchorPayload = MentionMarkdownClickAnchorPayload;
export type NotificationsTimeoutPayload = NotificationBarTimeoutPayload;

export type NotificationItem = {
  notification: NotificationBar;
  timeout?: number;
  hide?: boolean;
};

export type PropsNotifications = {
  notifications: NotificationItem[];
  omit: boolean;
};

export function useNotifications(
  props: PropsNotifications,
  emit: (
    name: string,
    args:
      | NotificationsMovePayload
      | NotificationsClickPayload
      | NotificationsClickAnchorPayload
      | NotificationsTimeoutPayload
  ) => void
) {
  const { findUser, findGroup } = useGlobalStore();

  function toSender(n: NotificationBar) {
    let id = 'system';
    switch (n.type) {
      case AskQuestionNotification:
        id = n.asTypeOf<AskQuestionNotificationPayload>(n.type).payload.createdBy;
        break;
      case AddCommentToQuestionNotification:
        id = n.asTypeOf<AddCommentToQuestionNotificationPayload>(n.type).payload.commentCreatedBy;
        break;
      case EditCommentOnQuestionNotification:
        id = n.asTypeOf<EditCommentOnQuestionNotificationPayload>(n.type).payload.editedBy;
        break;
      case CreateQuestionnaireNotification:
        id = n.asTypeOf<CreateQuestionnaireNotificationPayload>(n.type).payload.createdBy;
        break;
      case CreateGroupMemoNotification:
        id = n.asTypeOf<CreateGroupMemoPayload>(n.type).payload.createdBy;
        break;
      case UpdateGroupMemoNotification:
        id = n.asTypeOf<UpdateGroupMemoPayload>(n.type).payload.updatedBy;
        break;
      case StartExamNotification:
        id = n.asTypeOf<StartExamNotificationPayload>(n.type).payload.createdBy;
        break;
      case ResolveQuestionNotification:
        id = n.asTypeOf<ResolveQuestionNotificationPayload>(n.type).payload.resolvedBy;
        break;
      case AddGroupUserNotification:
        id = n.asTypeOf<AddGroupUserNotificationPayload>(n.type).payload.addedBy;
        break;
      default:
    }
    return createUserAvatar(id, findUser);
  }

  function toGroupName(id: string) {
    return findGroup(id)?.name;
  }

  const items = computed(() =>
    props.notifications.map((n) => {
      return {
        ...n,
        sender: toSender(n.notification),
      };
    })
  );

  function move(payload: NotificationBarMovePayload) {
    emit('move', payload);
  }

  function click(payload: NotificationBarClickPayload) {
    emit('click', payload);
  }

  function clickAnchor(payload: MentionMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function onTimeout(payload: NotificationBarTimeoutPayload) {
    emit('timeout', payload);
  }

  const msgs = useMessages({ prefix: 'base.organisms.notifications' });
  return {
    items,
    labelNoData: msgs.of('noData'),
    toGroupName,
    move,
    click,
    clickAnchor,
    onTimeout,
  };
}
