






import { computed, defineComponent } from '@vue/composition-api';

import { googlePlayUrl } from '@/config/env';

import { useMessages } from '../../Messages';

export default defineComponent({
  name: 'BaseOnGooglePlay',
  props: { width: { type: [Number, String], default: 150 } },
  setup() {
    const to = computed(() => googlePlayUrl);
    const msgs = useMessages({ prefix: 'base.atoms.onGooglePlay' });
    return { to, alt: msgs.of('getIt'), locale: msgs.locale };
  },
});
