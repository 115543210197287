import { computed, nextTick, ref } from '@vue/composition-api';

import { Optional } from '@/base/types';

import { useGlobalStore } from '../../store';
import { waitTransition } from '../../utils/TransitionUtils';
import { useVuetify } from '../../utils/VuetifyUtils';
import {
  FrameMainMenuListGroup,
  PropsFrameMainMenuList,
} from '../molecules/FrameMainMenuListComposable';

export type PropsFrameSide = {
  admin: boolean;
};

export function useFrameSide(props: PropsFrameSide, emit: (name: string, arg: boolean) => void) {
  const {
    tenantName,
    group,
    groupRole,
    version,
    user,
    userAssignedGroups,
    fetch,
    onReady,
    getGroupMenusAvailable,
    getTenantMenusAvailable,
  } = useGlobalStore();

  const menuProps = computed<PropsFrameMainMenuList>(() => {
    const activeGroup: Optional<FrameMainMenuListGroup> = group.value
      ? {
          id: group.value.id,
          name: group.value.name,
          role: groupRole.value,
          menusAvailable: getGroupMenusAvailable(props.admin, group.value.id),
        }
      : undefined;
    return {
      tenantName: tenantName.value ?? '',
      version: version.value,
      menusAvailable: getTenantMenusAvailable(),
      role: user.value?.role ?? 'general',
      group: activeGroup,
      adminMode: props.admin,
      assignedGroups: userAssignedGroups.value.map((g) => ({
        id: g.id,
        name: g.name,
        groupRole: g.groupRole,
      })),
    };
  });

  function changeSide(value: boolean) {
    emit('change', value);
  }

  const { mobile } = useVuetify();
  onReady(() => {
    if (mobile.value) nextTick(() => changeSide(false));
  });

  const loading = ref(false);
  async function refresh() {
    loading.value = true;
    fetch();
    waitTransition(() => {
      loading.value = false;
    });
  }

  return {
    menuProps,
    loading,
    changeSide,
    refresh,
  };
}
