import { computed, ref } from '@vue/composition-api';
import { DataOptions, DataTableHeader } from 'vuetify';

import { useMessages } from '../../Messages';
import { useTableOption } from '../../utils/TableUtils';
import { useVuetify } from '../../utils/VuetifyUtils';

export type BaseTableActionMenu = {
  label: string;
  value: string;
  required?: boolean;
  single?: boolean;
};

export type BaseTableActionPayload = {
  event: string;
  selected: string[];
};

export type PropsBaseTable = {
  headers: DataTableHeader[];
  items: Record<string, unknown>[];
  itemKey: string;
  menus: BaseTableActionMenu[];
  showMenus: boolean;
  showSearch: boolean;
  showDockWindow: boolean;
  height: string;
  slotHeight: string;
  storeOptionId: string;
};

export function useBaseTable(
  props: PropsBaseTable,
  emit: (name: string, args: BaseTableActionPayload) => void
) {
  const { mobileXS, breakpoint } = useVuetify();
  const { options, updateOptions } = useTableOption(props.storeOptionId);

  const checked = ref<Record<string, unknown>[]>([]);
  function clearSelection() {
    checked.value = [];
  }
  const selectedKeys = computed<string[]>(() =>
    props.items
      .filter(
        (item) =>
          props.itemKey in item &&
          checked.value.some(
            (check) => props.itemKey in check && check[props.itemKey] === item[props.itemKey]
          )
      )
      .map((item) => item[props.itemKey] as string)
  );

  const menu = ref(false);
  const required = computed(() => {
    if (selectedKeys.value.length === 0) return false;
    return selectedKeys.value.length > 0;
  });
  const single = computed(() => selectedKeys.value.length === 1);

  const search = ref();
  const tableHeight = computed(() => {
    if (mobileXS.value) return undefined;
    if (!props.showMenus && breakpoint.value?.smAndDown)
      return `calc(${props.height} + 40px - ${props.slotHeight})`;
    return props.height;
  });

  function selectMenu(event: string) {
    emit('action', { event, selected: selectedKeys.value });
  }

  function selectRow(item: Record<string, unknown>) {
    if (props.itemKey in item) {
      emit('action', { event: 'show', selected: [item[props.itemKey] as string] });
    }
  }

  const on = computed(() => {
    const handlers = {};
    if (props.showDockWindow)
      handlers['click:row'] = (row: Record<string, unknown>) => selectRow(row);
    handlers['update:options'] = (opts: DataOptions) => updateOptions(opts);
    return handlers;
  });

  const msgs = useMessages({ prefix: 'base.molecules.baseTable' });
  return {
    checked,
    selectedKeys,
    menu,
    required,
    single,
    search,
    tableHeight,
    options,
    on,
    labelAction: msgs.of('action'),
    labelSearch: msgs.of('search'),
    clearSelection,
    selectMenu,
    selectRow,
    updateOptions,
  };
}

export type BaseTable = ReturnType<typeof useBaseTable>;
