

























import { defineComponent } from '@vue/composition-api';

import UserExtensionSlack from '../molecules/UserExtensionSlack.vue';
import { useDialogUserDetail } from './DialogUserDetailComposable';
import Loading from './Loading.vue';

export default defineComponent({
  name: 'BaseDialogUserDetail',
  components: { Loading, UserExtensionSlack },
  setup() {
    return useDialogUserDetail();
  },
});
