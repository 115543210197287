




























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import {
  PropsBaseTextareaAutoGrow,
  useBaseTextareaAutoGrow,
} from './BaseTextareaAutoGrowComposable';

type Props = PropsBaseTextareaAutoGrow;

/*
 * [Bug Report] Textarea with auto-grow scrolls to top when pressing space bar
 * https://github.com/vuetifyjs/vuetify/issues/5314
 */
export default defineComponent({
  name: 'BaseBaseTextareaAutoGrow',
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    height: { type: String, default: undefined },
    maxHeight: { type: String, default: undefined },
    errorMessages: { type: Array as PropType<string[]>, default: () => [] },
    hideDetails: { type: [String, Boolean], default: undefined },
    overflowDetails: { type: Boolean, default: false },
    dataCy: { type: String, default: undefined },
    on: { type: Object, default: () => ({}) },
  },
  emits: ['change', 'input', 'valid'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseTextareaAutoGrow(props, emit);
  },
});
