import { computed } from '@vue/composition-api';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import {
  getGradationColor,
  getGradationColors,
  getPrimaryOrDefaultColor,
  KNOWTE_COLORS_DARK,
  KNOWTE_COLORS_LIGHT,
} from '@/base/app/utils/ColorUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  DoughnutController,
  BarElement,
  ArcElement,
  Filler,
  Legend,
  Title,
  Tooltip
);

const FONT_FAMILY = '"Kosugi Maru", Roboto, sans-serif';

export function useChartConfig() {
  const { theme } = useVuetify();

  /**
   * KNOWTEのプライマリーカラーから生成
   * グラデーション6色
   */
  const primaryColors = computed(() => {
    const start = theme.value?.dark ? 0 : 2;
    const primary = getPrimaryOrDefaultColor();
    return getGradationColors(primary, 6 + start).slice(start);
  });

  /**
   * KNOWTEのテーマカラー(7色)から生成
   */
  const backgroundColors = computed(() => {
    const colors = theme.value?.dark ? KNOWTE_COLORS_DARK : KNOWTE_COLORS_LIGHT;
    return colors.map((c) => getGradationColor(c.color, 5, 1));
  });

  const backgroundGrey = computed(() => (theme.value?.dark ? '#bbbbbb' : '#cccccc'));
  const axisColor = computed(() => (theme.value?.dark ? '#dcdcdc' : '#cccccc'));
  const fontColor = computed(() => (theme.value?.dark ? '#ffffff' : '#000000'));

  function getChartColors(dataCount: number) {
    if (dataCount <= 0) return [];
    const len = primaryColors.value.length;
    const q = Math.floor((dataCount - 1) / len);
    const r = (dataCount - 1) % len;
    const ret = [...Array(q)].map(() => primaryColors.value).flat();
    ret.push(...primaryColors.value.slice(0, r));
    ret.push(backgroundGrey.value);
    return ret;
  }

  return {
    primaryColors,
    backgroundColors,
    backgroundGrey,
    axisColor,
    fontColor,
    fontStyle: FONT_FAMILY,
    getChartColors,
  };
}
