import { computed, ref } from '@vue/composition-api';

import { useRoute, useRouter } from '@/utils/VueUtils';

import { useMessages } from '../..';
import { GlobalStoreUser } from '../../store/types';
import { DialogName, useDialogQuery } from '../../utils/DialogQueryUtils';

export type PropsFrameAccountMenuDialog = {
  user: GlobalStoreUser;
  admin: boolean;
};

export function useFrameAccountMenuDialog(
  props: PropsFrameAccountMenuDialog,
  emit: (name: string) => void
) {
  const route = useRoute();
  const router = useRouter();
  const { moveTo: moveToSettings } = useDialogQuery(DialogName.BASE_SETTINGS);
  const { moveTo: moveToNotifications } = useDialogQuery(DialogName.BASE_NOTIFICATIONS);

  const dialog = ref(false);

  function close() {
    dialog.value = false;
  }

  function openUserSetting() {
    moveToSettings();
    close();
  }

  function openNotifications() {
    moveToNotifications();
    close();
  }

  function toAboutThis() {
    if (route.name !== 'aboutThis') {
      router.push({ name: 'aboutThis' });
    }
    close();
  }

  function toggleFrameMode() {
    emit('toggle-frame-mode');
    close();
  }

  function toggleAdminControl() {
    emit('toggle-admin-control');
    close();
  }

  function signOut() {
    emit('sign-out');
    close();
  }

  const msgs = useMessages({ prefix: 'base.molecules.frameAccountMenuDialog' });
  const displayAdminControl = computed(
    () =>
      !window.$androidWebAppBridge &&
      (props.user.role === 'supervisor' || props.user.role === 'admin')
  );
  const labelRole = computed(() => msgs.of(props.user.role).value);

  return {
    dialog,
    displayAdminControl,
    labelRole,
    labelUserSetting: msgs.of('userSetting'),
    labelNotifications: msgs.of('notifications'),
    labelAboutThis: msgs.of('aboutThis'),
    labelSignOut: msgs.of('signOut'),
    labelClose: msgs.of('close'),
    labelDesktop: msgs.of('switchDesktopView'),
    labelMobile: msgs.of('switchMobileView'),
    labelAdminMode: msgs.of('switchAdminMode'),
    labelGeneralMode: msgs.of('switchGeneralMode'),
    close,
    openUserSetting,
    openNotifications,
    toAboutThis,
    toggleFrameMode,
    toggleAdminControl,
    signOut,
  };
}
