import { computed } from '@vue/composition-api';

import { NotificationReference } from '@/base/domains';
import {
  AddGroupUserNotification,
  AddGroupUserNotificationPayload,
} from '@/base/NotificationTypes';

import { useMessages } from '../..';

export type PropsNotificationBarGroupUser = {
  notification: NotificationReference;
  omit: boolean;
};

export function useNotificationBarGroupUser(props: PropsNotificationBarGroupUser) {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarGroupUser' });

  function parseAddGroupUser(n: NotificationReference) {
    const { payload } = n.asTypeOf<AddGroupUserNotificationPayload>(n.type);
    const description = msgs.of('added', { groupName: payload.groupName }).value;
    const group = { id: payload.groupId };
    return { description, group, groupName: payload.groupName };
  }

  const data = computed(() => {
    const n = props.notification;
    switch (n.type) {
      case AddGroupUserNotification:
        return parseAddGroupUser(n);
      default:
    }
    return undefined;
  });

  return { data };
}
