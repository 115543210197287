import { computed } from '@vue/composition-api';

import { Optional } from '@/base/types';

import { useMessages } from '..';
import { useVuetify } from './VuetifyUtils';

export const KNOWTE_COLORS_DARK = [
  { no: '1', value: 'indigo', color: '#7986cb' },
  { no: '2', value: 'blue', color: '#4dd0e1' },
  { no: '3', value: 'green', color: '#81c784' },
  { no: '4', value: 'light-green', color: '#aed581' },
  { no: '5', value: 'lime', color: '#dce775' },
  { no: '6', value: 'red', color: '#e57373' },
  { no: '7', value: 'pink', color: '#f06292' },
];

export const KNOWTE_COLORS_LIGHT = [
  { no: '1', value: 'indigo', color: '#6600cc' },
  { no: '2', value: 'blue', color: '#0066cc' },
  { no: '3', value: 'green', color: '#00cc66' },
  { no: '4', value: 'light-green', color: '#66cc00' },
  { no: '5', value: 'lime', color: '#cccc00' },
  { no: '6', value: 'red', color: '#cc0000' },
  { no: '7', value: 'pink', color: '#cc0066' },
];

const KNOWTE_THEME_LIGHT = {
  error: '#e74c3c',
  info: '#3498db',
  success: '#26b99a',
  warning: '#f39c12',
};

const KNOWTE_THEME_DARK = {
  error: '#ff1744',
  info: '#40c4ff',
  success: '#00e676',
  warning: '#ffff00',
};

export const KNOWTE_MARKER_COLORS = ['success', 'info', 'warning', 'error'];

export const KNOWTE_COURSE_BACKGROUND_COLORS = [
  'default',
  'black',
  'gray',
  'white',
  'blue-spiky',
  'blue-great',
  'blue',
  'purple',
  'purple-lily',
  'pink-passionate',
  'red-love',
  'orange-happy',
  'green-grown',
  'green-crystalline',
  'green-dusty',
  'green-soft',
  'orange-cheerful',
  'orange-light',
  'red-warm',
  'pink-spring',
  'gray-marble',
  'purple-light',
  'blue-party',
  'blue-arielle',
  'blue-light',
];

const HEX_DEFAULT = [221, 221, 221]; // #ddd;
const HEX_COLOR_REGEXP = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

function hexToIntArray(hex: Optional<string>) {
  if (!hex) return HEX_DEFAULT;
  const ret = HEX_COLOR_REGEXP.exec(hex);
  if (!ret) return HEX_DEFAULT;
  return ret.slice(1).map((s) => parseInt(s, 16));
}

function hexToRGBA(hex: Optional<string>, alpha: number) {
  const rgb = hexToIntArray(hex);
  return `rgba(${rgb.join(',')},${alpha})`;
}

export function getDefaultColor(dark?: boolean) {
  if (dark) return KNOWTE_COLORS_DARK[0].color;
  return KNOWTE_COLORS_LIGHT[0].color;
}

export function getPrimaryOrDefaultColor() {
  const { theme } = useVuetify();
  const p = theme.value?.currentTheme.primary?.toString();
  return p ?? getDefaultColor(theme.value?.dark);
}

export function getMarkerBackground(name = 'success') {
  const { theme } = useVuetify();
  const colors = theme.value?.dark ? KNOWTE_THEME_DARK : KNOWTE_THEME_LIGHT;
  const hex = colors[name];
  const background = `linear-gradient(transparent 70%,${hexToRGBA(hex, 0.4)} 70%)`;
  return background;
}

export function getGradationColors(color: string, size: number) {
  const base = 255;
  const target = hexToIntArray(color);
  const amount = target.map((pi) => (base - pi) / size);
  const ret = [...Array(size)].map((_, i) => {
    const rgb = target.map((pi, j) => pi + amount[j] * i);
    return `rgb(${rgb.join(',')})`;
  });
  return ret;
}

export function getGradationColor(color: string, size: number, index: number) {
  const gradation = getGradationColors(color, size);
  return gradation[index];
}

export function useColor() {
  const msgs = useMessages({ prefix: 'base.utils.colorUtils' });
  const { theme } = useVuetify();

  const dark = computed(() => theme.value?.dark);
  const colors = computed(() =>
    (dark.value ? KNOWTE_COLORS_DARK : KNOWTE_COLORS_LIGHT).map((c) => ({
      ...c,
      label: msgs.of(c.value).value,
    }))
  );

  return { dark, colors };
}

const CLASS_COURSE_PREFIX = 'course-background';

export function useCourseColor(props: {
  courseColor?: string;
  courseImage?: string;
  courseFontColorOnImage?: string;
}) {
  const courseColorClass = computed(() => {
    const ret: string[] = [];
    if (props.courseImage) {
      const imageClass = `${CLASS_COURSE_PREFIX}-image`;
      ret.push(imageClass, `${imageClass}--${props.courseFontColorOnImage ?? 'black'}`);
    } else {
      ret.push(`${CLASS_COURSE_PREFIX}--${props.courseColor ?? 'default'}`);
    }
    return ret.join(' ');
  });
  return { courseColorClass };
}
