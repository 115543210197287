











import { computed, defineComponent } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import LogoButton from '../atoms/LogoButton.vue';

type Props = {
  header?: string;
  body?: string;
};

export default defineComponent({
  name: 'BaseContentNotFound',
  components: { LogoButton, BaseMarkdown },
  props: {
    header: { type: String, default: undefined },
    body: { type: String, default: undefined },
  },
  setup(props: Props) {
    const { group } = useGlobalStore();
    const groupId = computed(() => group.value?.id);
    const msgs = useMessages({ prefix: 'base.organisms.notFound' });
    const labelHeader = computed(() => props.header || msgs.of('noData').value);
    const labelBody = computed(() => props.body || msgs.of('noLink').value);
    return { groupId, labelHeader, labelBody };
  },
});
