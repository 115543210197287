import { computed, nextTick, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import hljs from 'highlight.js';
import mi from 'markdown-it';
import miAnchor from 'markdown-it-anchor';
import miContainer from 'markdown-it-container';
import miEmoji from 'markdown-it-emoji';
import miFootnote from 'markdown-it-footnote';
import miMark from 'markdown-it-mark';
import miRuby from 'markdown-it-ruby';

import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../../Messages';
import { useColor } from '../../utils/ColorUtils';
import { createMarkerSelection, MarkerSelection } from '../../utils/MarkerUtils';
import { useBaseMarkdownContainer } from './BaseMarkdownContainer';
import miLink from './BaseMarkdownLink';
import miMedia from './BaseMarkdownMedia';
import miMention, {
  BaseMarkdownMentionOption,
  BaseMarkdownMentionUser,
} from './BaseMarkdownMention';

const CLASS_PREFIX = 'base-markdown';

export const BASE_MARKDOWN_OPTION = {
  breaks: true,
  linkify: true,
  typographer: false,
};

export type PropsBaseMarkdown = {
  body: string;
  marker: boolean;
  markerPosition?: string | number;
  permalink: boolean;
  findUser?: (userId: string) => Optional<BaseMarkdownMentionUser>;
};

export type BaseMarkdownClickAnchorPayload = {
  event: Event;
};

export type BaseMarkdownClickMentionPayload = {
  userId: string;
  event: Event;
};

export type BaseMarkdownMarkSelection = Optional<MarkerSelection>;

export function useBaseMarkdown(
  props: PropsBaseMarkdown,
  emit: (
    name: string,
    arg:
      | BaseMarkdownClickAnchorPayload
      | BaseMarkdownClickMentionPayload
      | BaseMarkdownMarkSelection
  ) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.baseMarkdown' });
  const baseMarkdown = ref<Element>();
  const anchors: Element[] = [];
  const mentions: Element[] = [];

  function clickAnchor(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.target && evt.target instanceof Element) {
      const e = evt.target as Element;
      if ([...e.classList].includes('header-anchor')) return;
    }
    emit('click-anchor', { event: evt });
  }

  function clickMention(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.target && evt.target instanceof Element) {
      const e = evt.target as Element;
      emit('click-mention', {
        event: evt,
        userId: e.getAttribute('data-id') || 'unknown',
      });
    }
  }

  function mark(event: Event) {
    if (!props.marker) return;
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    if (!range || range.collapsed) {
      emit('mark', undefined);
      return;
    }
    assertIsDefined(baseMarkdown.value);
    event.preventDefault();
    const markerSelection = createMarkerSelection(range, baseMarkdown.value, props.markerPosition);
    if (markerSelection) emit('mark', markerSelection);
    if (selection) selection.removeAllRanges();
  }

  function addListener() {
    anchors.splice(0);
    mentions.splice(0);
    if (!baseMarkdown.value) return;
    anchors.push(...baseMarkdown.value.getElementsByTagName('a'));
    anchors.forEach((a) => a.addEventListener('click', clickAnchor));
    mentions.push(...baseMarkdown.value.getElementsByClassName(`${CLASS_PREFIX}-mention`));
    mentions.forEach((m) => m.addEventListener('click', clickMention));
    baseMarkdown.value.addEventListener('touchend', mark);
    baseMarkdown.value.addEventListener('mouseup', mark);
  }

  function removeListener() {
    anchors.forEach((a) => a.removeEventListener('click', clickAnchor));
    anchors.splice(0);
    mentions.forEach((m) => m.removeEventListener('click', clickMention));
    mentions.splice(0);
    if (baseMarkdown.value) {
      baseMarkdown.value.removeEventListener('touchend', mark);
      baseMarkdown.value.removeEventListener('mouseup', mark);
    }
  }

  function init() {
    removeListener();
    nextTick(addListener);
  }
  onMounted(init);
  onUnmounted(removeListener);

  const permalink = props.permalink
    ? miAnchor.permalink.ariaHidden({ placement: 'after', space: false })
    : undefined;

  const md = mi({
    ...BASE_MARKDOWN_OPTION,
    highlight: (str: string, lang: string) => {
      let code: string;
      if (lang && hljs.getLanguage(lang))
        code = hljs.highlight(str, { language: lang, ignoreIllegals: true }).value;
      else code = md.utils.escapeHtml(str);
      const prefix = `${CLASS_PREFIX}-code`;
      const language = `<div class="${prefix}-lang secondary--text text-caption"><div>${lang}</div></div>`;
      return `<pre class="${prefix} hljs">${language}<code>${code}</code></pre>`;
    },
  })
    .use(miAnchor, { permalink })
    .use(miContainer, 'base-markdown-container', useBaseMarkdownContainer(CLASS_PREFIX))
    .use(miEmoji)
    .use(miFootnote)
    .use(miMark)
    .use(miMedia, { audio: ['mp3'], video: ['mp4'], msgs })
    .use(miRuby)
    .use(miLink);

  if (props.findUser) {
    md.use(miMention, {
      classPrefix: CLASS_PREFIX,
      findUser: props.findUser,
    } as BaseMarkdownMentionOption);
  }

  const { dark } = useColor();
  const markedHtml = computed(() => md.render(props.body, { dark: dark.value }));
  watch(markedHtml, init);

  const divClass = computed(() => {
    if (!props.marker) return undefined;
    return 'base-markdown--mark';
  });

  return { baseMarkdown, markedHtml, divClass };
}
