import { computed, ref } from '@vue/composition-api';

import { GroupRole, UserStatus } from '@/base/domains';

import { or } from '../../utils/FilterUtils';
import {
  BaseSelectSearchItem,
  BaseSelectSearchSearchText,
  BaseSelectSearchValue,
} from '../atoms/BaseSelectSearchComposable';

export type UserSelectValue = BaseSelectSearchValue;

export type UserSelectItem = BaseSelectSearchItem<{
  id: string;
  name: string;
  role: GroupRole;
  status?: UserStatus;
  avatar?: string;
}>;

export type PropsUserSelect = {
  value: UserSelectValue;
  users: UserSelectItem[];
  roles: GroupRole[];
};

export function useUserSelect(props: PropsUserSelect, emit) {
  const text = ref();
  const filtered = computed(() =>
    props.users.filter((u) => or(u.name, text.value)).sort((a, b) => (a.name < b.name ? -1 : 1))
  );

  function findUser(id: string) {
    return props.users.find((item) => item.id === id);
  }

  function search(v: BaseSelectSearchSearchText) {
    text.value = v;
  }

  function change(v: BaseSelectSearchValue) {
    emit('change', v);
  }

  function submit() {
    emit('submit');
  }

  return { text, filtered, findUser, search, change, submit };
}
