



// 理想としては次のように実装したい。工数が足りないので現在の実装になっている。
// - トーストはアプリケーションで1つにする
// - 通知のトーストと統合する
import { defineComponent, ref } from '@vue/composition-api';

import BaseToast from '@/base/app/components/molecules/BaseToast.vue';
import { BaseToast as BaseToastType } from '@/base/app/components/molecules/BaseToastComposable';
import { localEventBus } from '@/base/domains';
import { showErrorToast } from '@/base/domains/LocalEvents';

export default defineComponent({
  name: 'BaseTheErrorToast',
  components: { BaseToast },
  props: {},
  setup() {
    const toast = ref<BaseToastType>();

    const showToast = (messages: string[]) => {
      if (!toast.value) return;
      toast.value.show(messages, 5);
    };

    localEventBus.subscribe(showErrorToast, (event) => {
      const { messages } = event.payload;
      showToast(messages);
    });

    return { toast };
  },
});
