











import { defineComponent, SetupContext } from '@vue/composition-api';

import Notifications from './Notifications.vue';
import { PropsToastNotifications, useToastNotifications } from './ToastNotificationsComposable';

type Props = PropsToastNotifications;

export default defineComponent({
  name: 'BaseToastNotifications',
  components: { Notifications },
  props: {
    limit: { type: Number, default: 3 },
    hide: { type: Boolean, default: false },
  },
  emits: ['open-notifications'],
  setup(props: Props, { emit }: SetupContext) {
    return useToastNotifications(props, emit);
  },
});
