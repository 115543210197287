import { computed, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { useExtensionService } from '@/base/domains/extensions/ExtensionService';
import {
  SlackGroupExtensionConfig,
  SlackUserExtensionConfig,
} from '@/base/domains/extensions/slack';

import { useMessages } from '../../Messages';
import { useGlobalStore } from '../../store';

type ExtensionConfig = {
  group?: SlackGroupExtensionConfig;
  user?: SlackUserExtensionConfig;
};

type User = UserAvatar & {
  role?: string;
};

export type DialogUserDetailTarget = {
  userId: string;
  groupId?: string;
  event: Event;
};

export function useDialogUserDetail() {
  const { groupId, findUser } = useGlobalStore();
  const msgs = useMessages({ prefix: 'base.organisms.dialogUserDetail' });
  const service = useExtensionService();

  const menu = ref(false);
  const pos = ref<{ x: number; y: number }>({ x: 0, y: 0 });
  const user = ref<User>();
  const extension = ref<ExtensionConfig>();
  const loading = ref(false);

  function close() {
    menu.value = false;
    pos.value = { x: 0, y: 0 };
    user.value = undefined;
    extension.value = undefined;
  }
  watch(menu, (newVal) => {
    if (!newVal) close();
  });

  async function fetchServiceConfig(name: string, uid: string, gid?: string) {
    loading.value = true;
    const names = await service.availableExtensionNames();
    if (!names.includes(name)) {
      extension.value = undefined;
      loading.value = false;
      return;
    }

    extension.value = { user: undefined, group: undefined };
    extension.value.user = await service.getUserExtension<SlackUserExtensionConfig>(name, uid);
    if (gid)
      extension.value.group = await service.getGroupExtension<SlackGroupExtensionConfig>(name, gid);
    loading.value = false;
  }

  function findUserDetail(id: string, gid?: string) {
    const u = findUser(id);
    const g = u?.groups.find((group) => group.id === gid);
    return {
      id,
      name: u?.name || msgs.of('unknown', { id }).value,
      avatar: u?.avatar,
      role: g?.role,
    };
  }

  function open(payload: DialogUserDetailTarget) {
    const prevPos = pos.value;
    const el = payload.event.target as Element;
    if (!el) return;
    const { left, bottom } = el.getBoundingClientRect();
    if (prevPos && prevPos.x === left && prevPos.y === bottom + 10) return;
    useTimeoutFn(() => {
      user.value = findUserDetail(payload.userId, payload.groupId || groupId.value);
      pos.value = { x: left, y: bottom + 10 };
      menu.value = true;
      fetchServiceConfig('slack', payload.userId, payload.groupId || groupId.value);
    }, 100);
  }

  const userRole = computed(() => (user.value?.role ? msgs.of(user.value.role).value : ''));
  return { menu, pos, user, userRole, extension, loading, open, close };
}

export type DialogUserDetail = ReturnType<typeof useDialogUserDetail>;
