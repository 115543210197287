


























































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { or } from '../../utils/FilterUtils';
import BaseButton from '../atoms/BaseButton.vue';
import BaseText from '../atoms/BaseText.vue';
import ToggleMask from '../atoms/ToggleMask.vue';
import { BaseTableActionMenu, PropsBaseTable, useBaseTable } from './BaseTableComposable';

type Props = PropsBaseTable;

export default defineComponent({
  name: 'BaseBaseTable',
  components: { BaseText, BaseButton, ToggleMask },
  props: {
    headers: { type: Array as PropType<DataTableHeader[]>, default: () => [] },
    items: { type: Array as PropType<Record<string, unknown>[]>, default: () => [] },
    itemKey: { type: String, default: 'id' },
    menus: { type: Array as PropType<BaseTableActionMenu[]>, default: () => [] },
    showMenus: { type: Boolean, default: false },
    showSearch: { type: Boolean, default: false },
    showDockWindow: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    height: { type: String, default: 'calc(100vh - 200px)' },
    slotHeight: { type: String, default: '0px' },
    storeOptionId: { type: String, required: true },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return { ...useBaseTable(props, emit), or };
  },
});
