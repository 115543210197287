import { ref } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';

export type BaseDialogConfirmClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useBaseDialogConfirm(
  emit: (name: string, arg?: BaseDialogConfirmClickAnchorPayload) => void
) {
  const dialog = ref(false);
  const message = ref<string>();
  let funcOK: (() => void) | undefined;
  let funcCancel: (() => void) | undefined;

  function open(msg?: string, ok?: () => void, cancel?: () => void) {
    dialog.value = true;
    message.value = msg;
    funcOK = ok;
    funcCancel = cancel;
  }

  function close(event: string) {
    dialog.value = false;
    message.value = undefined;
    emit(event);
    if (event === 'ok' && funcOK) funcOK();
    if (event === 'cancel' && funcCancel) funcCancel();
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'base.molecules.baseDialogConfirm' });
  return {
    dialog,
    message,
    labelYes: msgs.of('yes'),
    labelNo: msgs.of('no'),
    open,
    close,
    clickAnchor,
  };
}

export type BaseDialogConfirm = ReturnType<typeof useBaseDialogConfirm>;
