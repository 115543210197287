













































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseText from '../atoms/BaseText.vue';
import { useTextEditorMentionsDialog } from './TextEditorMentionsDialogComposable';

export default defineComponent({
  name: 'BaseTextEditorMentionsDialog',
  components: { BaseText, BaseButtonIcon },
  inheritAttrs: false,
  emits: ['select'],
  setup(_, { emit }: SetupContext) {
    return useTextEditorMentionsDialog(emit);
  },
});
