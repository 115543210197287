






























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import User from '../atoms/User.vue';
import {
  PropsUserSelectList,
  UserSelectListItem,
  useUserSelectList,
} from './UserSelectListComposable';

type Props = PropsUserSelectList;

export default defineComponent({
  name: 'BaseUserSelectList',
  components: { User },
  props: {
    users: { type: Array as PropType<UserSelectListItem[]>, default: () => [] },
    roles: { type: Array as PropType<GroupRole[]>, default: () => [] },
    multiple: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserSelectList(props, emit);
  },
});
