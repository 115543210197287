import { Optional } from '@/base/types';

import { useMessages } from '..';

export type QuestionnaireOption = {
  option: string;
  label: string;
};

const OPTION_LABELS = 'ABCDEFGHIJ';

function toLabel(index: number, defVal = 'Z'): string {
  if (index > 10 || index < 0) return defVal;
  return OPTION_LABELS[index];
}

export function getEmptyOption(index: number): QuestionnaireOption {
  return { option: '', label: toLabel(index) };
}

export function toQuestionnaireOptions(options: string[]): QuestionnaireOption[] {
  return options.map((o, i) => ({ option: o, label: toLabel(i) }));
}

export function toQuestionnaireOption(option: string, index: number): QuestionnaireOption {
  return { option, label: toLabel(index) };
}

export function toOptionLabel(index: Optional<number>) {
  const msgs = useMessages({ prefix: 'base.utils.questionnaireUtils' });
  return index || index === 0 ? toLabel(index) : msgs.of('noAnswer').value;
}

export function getOptionLabels(length: number) {
  return OPTION_LABELS.slice(0, length).split('');
}
