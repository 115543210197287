import mi from 'markdown-it';
import { RenderRule } from 'markdown-it/lib/renderer';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';

import { Optional } from '@/base/types';

export const MENTION_MARK = '@';
export const MENTION_KEYWORDS = ['trainer', 'mentor', 'trainee', 'group'];

const MATCHER =
  process.env.STORYBOOK_MODE === 'mock' || process.env.VUE_APP_MODE === 'mock'
    ? /(@\{\{group\}\})|(@\{\{trainer\}\})|(@\{\{mentor\}\})|(@\{\{trainee\}\})|(@\{\{[0-9a-z-]+\}\})/g
    : /(@\{\{group\}\})|(@\{\{trainer\}\})|(@\{\{mentor\}\})|(@\{\{trainee\}\})|(@\{\{[0-9a-f-]+\}\})/g;

export type BaseMarkdownMentionUser = {
  id: string;
  name: string;
};

export type BaseMarkdownMentionOption = {
  classPrefix: string;
  findUser: (id: string) => Optional<BaseMarkdownMentionUser>;
};

export function findMentions(
  content: string,
  findUser: (id: string) => Optional<BaseMarkdownMentionUser>
) {
  if (content.search(MATCHER) === -1) return [];

  function find(match?: string) {
    if (!match) return undefined;
    const id = match.slice(3, match.length - 2);
    const mu = findUser(id);
    if (mu) return mu;
    if (MENTION_KEYWORDS.includes(id)) return { id, name: id };
    return undefined;
  }

  const matches = Array.from(content.matchAll(MATCHER), (m) => ({
    text: m[0],
    user: find(m[0]),
    start: m.index ?? 0,
    end: (m.index ?? 0) + m[0].length,
  })).filter((item) => item.user) as {
    text: string;
    user: BaseMarkdownMentionUser;
    start: number;
    end: number;
  }[];
  return matches;
}

export default function insertPlugin(md: mi, opts: BaseMarkdownMentionOption) {
  const renderMention: RenderRule = (tokens: Token[], idx: number) => {
    const token = tokens[idx];
    if (!token) return '<span>@noToken</span>';
    const user = token.meta.user as BaseMarkdownMentionUser;
    if (!user) return `<span>${token.meta.text}?not-found</span>`;
    return `<span data-id="${user.id}" class="${opts.classPrefix}-mention">@${user.name}</span>`;
  };

  const parseMention = (state: StateCore) => {
    function textToken(content: string) {
      const token = new Token('text', '', 0);
      token.content = content;
      return token;
    }
    function mentionToken(text: string, user: BaseMarkdownMentionUser) {
      const token = new Token('mention', '', 0);
      token.meta = { text, user };
      return token;
    }

    state.tokens.forEach((block) => {
      if (block.type !== 'inline' || !block.children) return;

      const children = block.children
        .map((token) => {
          if (token.type !== 'text') return [token];

          const { content } = token;
          const matches = findMentions(content, opts.findUser);
          if (matches.length === 0) return [token];

          const newTokens: Token[] = [];
          let lastIndex = 0;
          for (let i = 0; i < matches.length; i += 1) {
            const m = matches[i];
            if (m.start > lastIndex) newTokens.push(textToken(content.slice(lastIndex, m.start)));
            newTokens.push(mentionToken(m.text, m.user));
            lastIndex = m.end;
          }
          if (content.length > lastIndex) newTokens.push(textToken(content.slice(lastIndex)));
          return newTokens;
        })
        .reduce((p, c) => p.concat(...c), []);
      Object.assign(block, { children });
    });

    return false;
  };

  // eslint-disable-next-line no-param-reassign
  md.renderer.rules.mention = renderMention;
  md.core.ruler.after('inline', 'mention', parseMention);
}
