import mi from 'markdown-it';
import ParserInline from 'markdown-it/lib/parser_inline';
import { RenderRule } from 'markdown-it/lib/renderer';
import StateInline from 'markdown-it/lib/rules_inline/state_inline';

import { useMessages } from '../../Messages';

const AUDIO_EXTENSIONS = ['aac', 'm4a', 'mp3', 'oga', 'ogg', 'wav'];
const VIDEO_EXTENSIONS = ['mp4', 'm4v', 'ogv', 'webm', 'mpg', 'mpeg'];
const MEDIA_ATTRIBUTES: Record<string, string> = {
  controls: '',
  controlsList: 'nodownload',
  preload: 'metadata',
  oncontextmenu: 'return false;',
};

const PARSE_RESULT = {
  label: { start: 0, end: 0 },
  href: '',
  type: '' as 'image' | 'video' | 'audio' | 'link' | '',
  title: '',
  width: '',
  end: 0,
  max: 0,
};
type ParseResult = typeof PARSE_RESULT;

function skipEmpty(src: string, start: number, end: number) {
  let pos = start;
  for (; pos < end; pos += 1) {
    const code = src.charCodeAt(pos);
    if (code !== 0x20 && code !== 0x0a) break;
  }
  return pos;
}

function guessMediaType(url: string, opt: BaseMarkdownMediaOption) {
  const ext = url.split(/[#?]/)[0].split('.').pop()?.trim().toLocaleLowerCase();
  if (!!ext && opt.video.includes(ext)) return 'video';
  if (!!ext && opt.audio.includes(ext)) return 'audio';
  if (!!ext && (VIDEO_EXTENSIONS.includes(ext) || AUDIO_EXTENSIONS.includes(ext))) return 'link';
  return 'image';
}

function getImageWidth(src: string, start: number, end: number) {
  let pos = start;
  let code = src.charCodeAt(pos);
  while (pos < end && code >= 0x30 /* 0 */ && code <= 0x39 /* 9 */) {
    pos += 1;
    code = src.charCodeAt(pos);
  }
  return { width: src.slice(start, pos), pos };
}

function parse(state: StateInline, opt: BaseMarkdownMediaOption): ParseResult | undefined {
  const { src, pos: now, posMax: max, md } = state;
  if (src.charCodeAt(now) !== 0x21 /* ! */ || src.charCodeAt(now + 1) !== 0x5b /* [ */)
    return undefined;

  const ret = {
    ...PARSE_RESULT,
    label: { start: now + 2, end: md.helpers.parseLinkLabel(state, now + 1, false) },
    end: now,
    max,
  };
  // parser failed to find ']', so it's not a valid link
  if (ret.label.end < 0) return undefined;

  let pos = ret.label.end + 1;
  if (pos < max && src.charCodeAt(pos) === 0x28 /* ( */) {
    //
    // Inline link
    //

    pos += 1;

    // [link](  <href>  "title"  )
    //        ^^ skipping these spaces
    pos = skipEmpty(src, pos, max);
    if (pos >= max) return undefined;

    // [link](  <href>  "title"  )
    //          ^^^^^^ parsing link destination
    const resHref = md.helpers.parseLinkDestination(src, pos, max);
    if (resHref.ok) {
      ret.href = md.normalizeLink(resHref.str);
      if (md.validateLink(ret.href)) {
        pos = resHref.pos;
        ret.type = guessMediaType(ret.href, opt);
      } else {
        ret.href = '';
      }
    }

    // [link](  <href>  "title"  )
    //                ^^ skipping these spaces
    const posHrefEnd = pos;
    pos = skipEmpty(src, pos, max);

    if (pos < max && pos !== posHrefEnd) {
      // [link](  <href>  "title"  )
      //                  ^^^^^^^ parsing link title
      const res = md.helpers.parseLinkTitle(src, pos, max);
      if (res.ok) {
        ret.title = res.str;
        pos = res.pos;

        // [link](  <href>  "title"  )
        //                         ^^ skipping these spaces
        pos = skipEmpty(src, pos, max);
      }
    }

    // [link](  <href>  "title" wW  )
    //                          ^^ parsing image size
    if (ret.type === 'image' && pos < max) {
      // there must be at least one white spaces
      // between previous field and the size
      if (
        pos - 1 >= 0 &&
        src.charCodeAt(pos - 1) === 0x20 &&
        src.charCodeAt(pos) === 0x77 /* w */
      ) {
        const res = getImageWidth(src, pos + 1, max);
        ret.width = res.width;
        pos = res.pos;

        // [link](  <href>  "title" wW  )
        //                            ^^ skipping these spaces
        pos = skipEmpty(src, pos, max);
      }
    }

    if (pos >= max || state.src.charCodeAt(pos) !== 0x29 /* ) */) {
      state.pos = ret.end;
      return undefined;
    }

    ret.end = pos + 1;
    return ret;
  }

  if (typeof state.env.references === 'undefined') return undefined;

  //
  // Link reference
  //
  // [1]: https://example.com/
  // This is [an example reference-style link][1].
  // or
  // [an example reference-style link]: https://example.com/
  // This is [an example reference-style link].

  // [foo]  [bar]
  //      ^^ optional whitespace (can include newlines)
  pos = skipEmpty(src, pos, max);

  const refLabel = { start: pos + 1, end: 0, label: '' };
  if (pos < max && src.charCodeAt(pos) === 0x5b /* [ */) {
    refLabel.end = md.helpers.parseLinkLabel(state, pos);
    if (refLabel.end >= 0) {
      refLabel.label = src.slice(refLabel.start, refLabel.end);
      pos = refLabel.end + 1;
    } else {
      pos = ret.label.end + 1;
    }
  } else {
    pos = ret.label.end + 1;
  }

  // covers label === '' and label === undefined
  // (collapsed reference link and shortcut reference link respectively)
  if (!refLabel.label) refLabel.label = src.slice(ret.label.start, ret.label.end);

  const ref = state.env.references[md.utils.normalizeReference(refLabel.label)];
  if (!ref) {
    state.pos = ret.end;
    return undefined;
  }

  ret.href = ref.href;
  ret.title = ref.title;
  ret.type = 'link';
  ret.end = pos;

  return ret;
}

function analyze(opt: BaseMarkdownMediaOption): ParserInline.RuleInline {
  return (state, silent) => {
    const ret = parse(state, opt);
    if (!ret) return false;

    if (!silent) {
      const { start, end } = ret.label;
      const label = state.src.slice(start, end) || '';

      if (ret.type === 'image') {
        const span = state.push('span_open', 'span', 1);
        const image = state.push('image_open', 'img', 1);
        state.push('image_close', 'img', -1);
        const caption = state.push('caption', 'span', 0);
        state.push('span_close', 'span', -1);
        // span
        span.attrSet('class', 'base-markdown-media-wrap');
        // image
        image.attrSet('src', ret.href);
        image.attrSet('alt', state.md.utils.escapeHtml(label));
        image.attrSet('loading', 'lazy');
        if (ret.width) image.attrSet('width', ret.width);
        if (ret.title) {
          // caption
          const [content] = state.md.parseInline(ret.title, state.env);
          caption.children = content.children;
          caption.attrSet('class', 'base-markdown-media-caption');
        }
      } else if (ret.type === 'audio' || ret.type === 'video') {
        const span = state.push('span_open', 'span', 1);
        const media = state.push(`${ret.type}_open`, ret.type, 1);
        const text = state.push('text', '', 0);
        state.push(`${ret.type}_close`, ret.type, -1);
        const caption = state.push('caption', 'span', 0);
        state.push('span_close', 'span', -1);
        // span
        span.attrSet('class', 'base-markdown-media-wrap');
        // media
        Object.keys(MEDIA_ATTRIBUTES).forEach((key) => media.attrSet(key, MEDIA_ATTRIBUTES[key]));
        let src = ret.href;
        if (ret.type === 'video') src = `${src}#t=0.001`;
        media.attrSet('src', src);
        text.content = opt.msgs.of('mediaDescription', {
          mediaType: ret.type,
          title: state.md.utils.escapeHtml(ret.title || label || ''),
          src: ret.href,
        }).value;
        if (ret.title) {
          // caption
          const [content] = state.md.parseInline(ret.title, state.env);
          caption.children = content.children;
          caption.attrSet('class', 'base-markdown-media-caption');
        }
      } else if (ret.type === 'link') {
        const token = state.push('link_open', 'a', 1);
        token.attrSet('download', '');
        token.attrSet('href', ret.href);
        if (ret.title) token.attrSet('title', ret.title);
        const text = state.push('text', '', 0);
        text.content = label;
        state.push('link_close', 'a', -1);
      }
    }

    state.pos = ret.end;
    state.posMax = ret.max;

    return true;
  };
}

function renderCaption(md: mi): RenderRule {
  return (
    md.renderer.rules.caption ||
    ((t, i, o, e, s) => {
      const token = t[i];
      if (!token) return '';
      return `<${token.tag} ${
        token.attrs?.map(([k, v]) => `${k}${v ? `="${v}"` : ''}`)?.join(' ') ?? ''
      }>${s.renderInline(token.children ?? [], o, e)}</${token.tag}>`;
    })
  );
}

const PLUGIN_OPTION = {
  audio: [] as string[],
  video: [] as string[],
  msgs: {} as ReturnType<typeof useMessages>,
};
export type BaseMarkdownMediaOption = typeof PLUGIN_OPTION;

export default function insertPlugin(md: mi, opt: BaseMarkdownMediaOption) {
  md.inline.ruler.before('emphasis', 'media', analyze(opt));
  // eslint-disable-next-line no-param-reassign
  md.renderer.rules.caption = renderCaption(md);
}
