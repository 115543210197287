import { ref } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import { BaseMarkdownClickAnchorPayload } from '../atoms/BaseMarkdownComposable';
import { ErrorMessage, ReplaceError } from './ErrorMessagesComposable';

export type BaseDialogOkClickAnchorPayload = BaseMarkdownClickAnchorPayload;

export function useBaseDialogOk(
  emit: (name: string, arg?: BaseDialogOkClickAnchorPayload) => void
) {
  const dialog = ref(false);
  const message = ref<string | undefined>();
  const eMessage = ref<{ errors: ErrorMessage[]; replace?: ReplaceError[] }>();
  let funcOK: (() => void) | undefined;

  function close(event: string) {
    dialog.value = false;
    message.value = undefined;
    eMessage.value = undefined;
    emit(event);
    if (event === 'ok' && funcOK) funcOK();
    funcOK = undefined;
  }

  function open(msg?: string, ok?: () => void) {
    dialog.value = true;
    message.value = msg;
    eMessage.value = undefined;
    funcOK = ok;
  }

  function error(errors: ErrorMessage[], replace?: ReplaceError[], ok?: () => void) {
    dialog.value = true;
    eMessage.value = { errors, replace };
    message.value = undefined;
    funcOK = ok;
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'base.molecules.baseDialogOk' });
  return {
    dialog,
    message,
    eMessage,
    labelClose: msgs.of('close'),
    close,
    open,
    error,
    clickAnchor,
  };
}

export type BaseDialogOk = ReturnType<typeof useBaseDialogOk>;
