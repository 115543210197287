import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { Optional } from '@/base/types';

import { toOptionLabel } from '../../utils/QuestionnaireUtils';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '' },
  { value: 'answer', text: '' },
];

export type QuestionnaireRespondentTableItem = {
  id: string;
  name: string;
  selectedIndex: Optional<number>;
};

export type PropsQuestionnaireRespondentTable = {
  respondent: QuestionnaireRespondentTableItem[];
};

export function useQuestionnaireRespondentTable(props: PropsQuestionnaireRespondentTable) {
  const msgs = useMessages({ prefix: 'base.molecules.questionnaireRespondentTable' });
  const headers = computed(() => {
    const keys = HEADERS.map((item) => ({
      ...item,
      text: item ? msgs.of(item.value).value : '',
    })) as DataTableHeader[];
    return keys;
  });
  const items = computed(() =>
    props.respondent
      .map((r) => ({ ...r, answer: toOptionLabel(r.selectedIndex) }))
      .sort((a, b) => {
        if (a.name === b.name) return a.id < b.id ? -1 : 1;
        return a.name < b.name ? -1 : 1;
      })
  );
  return { headers, items };
}
