import { computed } from '@vue/composition-api';
import { Route } from 'vue-router';

import { Optional } from '@/base/types';
import { useRoute } from '@/utils/VueUtils';

import { useMessages } from '../../Messages';

export type ReturnQuery = {
  path: string;
  query?: Record<string, unknown>;
};

export function toQueryPath(path: string, query?: Record<string, unknown>) {
  try {
    return { r: JSON.stringify({ p: path, q: query }) };
  } catch (e) {
    return undefined;
  }
}

export function toQuery(route: Route, query?: Record<string, unknown>) {
  return toQueryPath(route.path, query);
}

function isReturnQuery(route: Route): Optional<ReturnQuery> {
  if (!('r' in route.query)) return undefined;
  const { r } = route.query;
  if (!r || typeof r !== 'string') return undefined;
  try {
    const o = JSON.parse(r);
    if ('p' in o && typeof o.p === 'string') {
      const ret: ReturnQuery = { path: o.p };
      if ('q' in o && typeof o.q === 'object') {
        ret.query = o.q;
      }
      return ret;
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
}

export function useReturnButton() {
  const route = useRoute();
  const to = computed(() => isReturnQuery(route));
  const msgs = useMessages({ prefix: 'base.atoms.returnButton' });
  const pages = msgs.listOf('pages');
  const label = computed(() => {
    if (!to.value?.path) return msgs.of('back').value;
    const key = to.value.path.split('/').pop();
    const page = pages.value.find((item) => item.value === key);
    if (page) return page.text;
    return msgs.of('back').value;
  });
  return { to, label };
}

export type ReturnButton = ReturnType<typeof useReturnButton>;
