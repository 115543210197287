





































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import GroupUserStatus from './GroupUserStatus.vue';
import { PropsUser, UserAvatar, useUser } from './UserComposable';

type Props = PropsUser;

export default defineComponent({
  name: 'BaseUser',
  components: { GroupUserStatus },
  props: {
    user: { type: Object as PropType<UserAvatar>, default: () => ({ id: 'unknown' }) },
    handStatus: { type: String, default: undefined },
    xSmall: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    tooltipAttrs: { type: Object, default: () => ({ top: true, 'min-width': '5em' }) },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useUser(props, emit);
  },
});
