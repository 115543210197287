import { computed, ref, watch } from '@vue/composition-api';

import { HandStatus, UserStatus } from '@/base/domains';
import { Optional } from '@/base/types';

import { useMessages } from '../../Messages';
import { GlobalStoreUser } from '../../store/types';
import { useColor } from '../../utils/ColorUtils';

export type UserAvatar = {
  id: 'system' | 'unknown' | string;
  name?: string;
  avatar?: string;
  status?: UserStatus;
};

export function createUserAvatar(
  id: string,
  findUser: (id: string) => Optional<GlobalStoreUser>
): UserAvatar {
  const u = findUser(id);
  if (u) return { id: u.id, name: u.name, avatar: u.avatar, status: undefined };
  return { id };
}

export type UserClickPayload = {
  userId: string;
  event: Event;
};

export type PropsUser = {
  user: UserAvatar;
  handStatus?: HandStatus;
  disabled: boolean;
  small: boolean;
  xSmall: boolean;
};

export function useUser(props: PropsUser, emit: (name: string, arg: UserClickPayload) => void) {
  const { dark } = useColor();
  const msgs = useMessages({ prefix: 'base.atoms.user' });

  const error = ref(false);
  function onLoadError() {
    error.value = true;
  }
  watch(
    () => props.user,
    () => {
      error.value = false;
    }
  );

  const name = computed(() => {
    if (props.user.id === 'system') return msgs.of('system').value;
    if (props.user.name) return props.user.name;
    return msgs.of('unknown', { id: props.user.id }).value;
  });

  const abbr = computed(() => {
    if (props.user.id === 'system') return undefined;
    if (props.user.name)
      return props.small || props.xSmall ? props.user.name.charAt(0) : props.user.name.slice(0, 4);
    return '*';
  });

  const active = computed(() => props.user.status === 'active');

  const size = computed(() => {
    if (props.xSmall) return 20;
    if (props.small) return 32;
    return undefined;
  });

  const enabledClick = computed(() => {
    if (props.disabled) return false;
    if (props.user.id === 'unknown' || props.user.id === 'system') return false;
    return false; // #651
  });
  function onClick(event: Event) {
    if (!enabledClick.value) return;
    event.stopPropagation();
    emit('click', { event, userId: props.user.id });
  }

  return { name, abbr, active, size, dark, error, onLoadError, enabledClick, onClick };
}
