import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { UserId } from '../../../domains';
import { useMessages } from '../..';
import {
  getEmptyOption,
  QuestionnaireOption,
  toQuestionnaireOption,
} from '../../utils/QuestionnaireUtils';

export type QuestionnaireFormValue = {
  title: string;
  text: string;
  userIds: UserId[];
  options: string[];
};

export type PropsQuestionnaireForm = {
  value: QuestionnaireFormValue;
};

export type QuestionnaireFormToastValue = string[];

function validateOptions(options: QuestionnaireOption[]) {
  const hasInput = options.some((item) => !!item.option);
  if (hasInput && options.some((item) => !item.option)) return ['errorOptionsRequired'];
  return true;
}

export function useQuestionnaireForm(
  props: PropsQuestionnaireForm,
  emit: (name: string, arg: QuestionnaireFormValue | QuestionnaireFormToastValue) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.questionnaireForm' });
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<Omit<QuestionnaireFormValue, 'options'>>({
    title: '',
    text: '',
    userIds: [],
  });
  const inputOptions = ref<QuestionnaireOption[]>([]);

  function init() {
    input.value = {
      title: props.value.title,
      text: props.value.text,
      userIds: props.value.userIds,
    };
    inputOptions.value = props.value.options.map((o, i) => toQuestionnaireOption(o, i));
  }
  onMounted(init);
  watch(() => props.value, init);

  function addOption() {
    inputOptions.value = [...inputOptions.value, getEmptyOption(inputOptions.value.length)];
  }

  function removeOption(index: number) {
    inputOptions.value = inputOptions.value
      .filter((_, i) => i !== index)
      .map((item, i) => toQuestionnaireOption(item.option, i));
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    const errors = validateOptions(inputOptions.value);
    if (errors !== true) {
      emit(
        'toast',
        errors.map((key) => msgs.of(key).value)
      );
      return;
    }
    emit('submit', { ...input.value, options: inputOptions.value.map((item) => item.option) });
  }

  const disableAddOption = computed(() => inputOptions.value.length >= 10);
  const disableRemoveOption = computed(() => inputOptions.value.length <= 2);

  return {
    observer,
    input,
    inputOptions,
    disableAddOption,
    disableRemoveOption,
    labelRespondent: msgs.of('respondent'),
    labelTitle: msgs.of('title'),
    placeholderQuestionText: msgs.of('questionText'),
    placeholderOption: msgs.of('option'),
    labelAddOption: msgs.of('addOption'),
    labelRemoveOption: msgs.of('removeOption'),
    addOption,
    removeOption,
    submit,
  };
}
