






import { defineComponent, PropType } from '@vue/composition-api';

import {
  NotificationBarOnGooglePlay,
  useNotificationBarOnGooglePlay,
} from './NotificationBarOnGooglePlayComposable';
import OnGooglePlay from './OnGooglePlay.vue';

export default defineComponent({
  name: 'BaseNotificationBarOnGooglePlay',
  components: { OnGooglePlay },
  props: {
    notification: { type: Object as PropType<NotificationBarOnGooglePlay>, required: true },
    omit: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
  },
  setup() {
    return useNotificationBarOnGooglePlay();
  },
});
