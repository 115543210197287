


















import { defineComponent, PropType } from '@vue/composition-api';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import { useFrameMainMenuDialog } from './FrameMainMenuDialogComposable';
import FrameMainMenuList from './FrameMainMenuList.vue';
import { PropsFrameMainMenuList } from './FrameMainMenuListComposable';

export default defineComponent({
  name: 'BaseFrameMainMenuDialog',
  components: { BaseButtonIcon, FrameMainMenuList },
  inheritAttrs: false,
  props: { menuProps: { type: Object as PropType<PropsFrameMainMenuList>, required: true } },
  setup() {
    return useFrameMainMenuDialog();
  },
});
