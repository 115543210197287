



















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import NotificationBarExam from '../atoms/NotificationBarExam.vue';
import NotificationBarGroupUser from '../atoms/NotificationBarGroupUser.vue';
import NotificationBarMemo from '../atoms/NotificationBarMemo.vue';
import NotificationBarOnGooglePlay from '../atoms/NotificationBarOnGooglePlay.vue';
import NotificationBarQuestion from '../atoms/NotificationBarQuestion.vue';
import NotificationBarQuestionnaire from '../atoms/NotificationBarQuestionnaire.vue';
import User from '../atoms/User.vue';
import { UserAvatar } from '../atoms/UserComposable';
import DateTimeNow from './DateTimeNow.vue';
import {
  NotificationBar,
  PropsNotificationBar,
  useNotificationBar,
} from './NotificationBarComposable';

type Props = PropsNotificationBar;

export default defineComponent({
  name: 'BaseNotificationBar',
  components: {
    User,
    DateTimeNow,
    NotificationBarMemo,
    NotificationBarQuestion,
    NotificationBarQuestionnaire,
    NotificationBarExam,
    NotificationBarGroupUser,
    NotificationBarOnGooglePlay,
  },
  inheritAttrs: false,
  props: {
    notification: { type: Object as PropType<NotificationBar>, required: true },
    sender: { type: Object as PropType<UserAvatar>, required: true },
    omit: { type: Boolean, default: false },
    timeout: { type: Number, default: undefined },
    width: { type: String, default: 'min(400px, 80vw)' },
  },
  emits: ['click', 'move', 'timeout'],
  setup(props: Props, { emit }: SetupContext) {
    return useNotificationBar(props, emit);
  },
});
