import { computed } from '@vue/composition-api';
import { Location } from 'vue-router';

import { NotificationReference } from '@/base/domains';
import {
  AddCommentToQuestionNotification,
  AddCommentToQuestionNotificationPayload,
  AskQuestionNotification,
  AskQuestionNotificationPayload,
  EditCommentOnQuestionNotification,
  EditCommentOnQuestionNotificationPayload,
  ResolveQuestionNotification,
  ResolveQuestionNotificationPayload,
} from '@/base/NotificationTypes';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../..';

export type NotificationBarQuestionClickPayload = {
  id: string;
  to: Location;
};

export type PropsNotificationBarQuestion = {
  notification: NotificationReference;
  omit: boolean;
};

export function useNotificationBarQuestion(
  props: PropsNotificationBarQuestion,
  emit: (name: string, args: NotificationBarQuestionClickPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarQuestion' });

  function parseAskQuestion(n: NotificationReference) {
    const { payload } = n.asTypeOf<AskQuestionNotificationPayload>(n.type);
    const description = msgs.of('created', { title: payload.title }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.title,
      icon: 'mdi-comment-question-outline',
      to: {
        name: 'groupQuestion',
        params: { id: payload.groupId, questionId: payload.questionId },
      },
    };
    return { description, body: payload.commentBody, group, link };
  }

  function parseAddCommentToQuestionNotification(n: NotificationReference) {
    const { payload } = n.asTypeOf<AddCommentToQuestionNotificationPayload>(n.type);
    const description = msgs.of('commented', { title: payload.title }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.title,
      icon: 'mdi-comment-question-outline',
      to: {
        name: 'groupQuestion',
        params: { id: payload.groupId, questionId: payload.questionId },
        hash: payload.commentId,
      },
    };
    return { description, body: payload.commentBody, group, link };
  }

  function parseEditCommentOnQuestionNotification(n: NotificationReference) {
    const { payload } = n.asTypeOf<EditCommentOnQuestionNotificationPayload>(n.type);
    const description = msgs.of('commentUpdated', { title: payload.title }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.title,
      icon: 'mdi-comment-question-outline',
      to: {
        name: 'groupQuestion',
        params: { id: payload.groupId, questionId: payload.questionId },
        hash: payload.commentId,
      },
    };
    return { description, body: payload.commentBody, group, link };
  }

  function parseResolveQuestionNotification(n: NotificationReference) {
    const { payload } = n.asTypeOf<ResolveQuestionNotificationPayload>(n.type);
    const description = msgs.of('resolved', { title: payload.title }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.title,
      icon: 'mdi-comment-check-outline',
      to: {
        name: 'groupQuestion',
        params: { id: payload.groupId, questionId: payload.questionId },
      },
    };
    return { description, group, link };
  }

  const data = computed(() => {
    const n = props.notification;
    switch (n.type) {
      case AskQuestionNotification:
        return parseAskQuestion(n);
      case AddCommentToQuestionNotification:
        return parseAddCommentToQuestionNotification(n);
      case EditCommentOnQuestionNotification:
        return parseEditCommentOnQuestionNotification(n);
      case ResolveQuestionNotification:
        return parseResolveQuestionNotification(n);
      default:
    }
    return undefined;
  });

  function click(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    assertIsDefined(data.value?.link.to, 'to');
    emit('click', { id: props.notification.id, to: data.value.link.to });
  }

  return { data, click };
}
