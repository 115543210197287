





























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { useQuestionnaireConfirmFinishDialog } from './QuestionnaireConfirmFinishDialogComposable';

export default defineComponent({
  name: 'BaseQuestionnaireConfirmFinishDialog',
  components: { BaseButton, BaseMarkdown },
  inheritAttrs: false,
  props: {
    loading: { type: Boolean, default: false },
  },
  emits: ['finish'],
  setup(_, { emit }: SetupContext) {
    return useQuestionnaireConfirmFinishDialog(emit);
  },
});
