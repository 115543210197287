






import { defineComponent } from '@vue/composition-api';

import Loading from './Loading.vue';
import NotFound from './NotFound.vue';

export default defineComponent({
  name: 'BaseFrameOverlay',
  components: { Loading, NotFound },
  props: {
    loading: { type: Boolean, default: false },
    notFound: { type: Boolean, default: false },
  },
});
