import { computed, watch } from '@vue/composition-api';
import { useWindowScroll } from '@vueuse/core';
import { locale as changeLocaleMoment } from 'moment';
import { localize as changeLocaleVeeValidate } from 'vee-validate';

import { Optional } from '@/base/types';
import { useRoute } from '@/utils/VueUtils';

import { useGlobalStore } from '../../store';
import { useVuetify } from '../../utils/VuetifyUtils';

const SIDE_WIDTH = 260;
const TOP_HEIGHT = 64;
const TOP_HEIGHT_PROMINENT = 220;
const TOP_HEIGHT_EXTENDED = 48;
const TOP_SHRINK_LIMIT = 16;

function useDisplaySettings() {
  const { isSignedIn, locale, displaySettings, onReady } = useGlobalStore();
  const { changeLocale: changeLocaleVuetify, changeDark, changePrimary } = useVuetify();

  function setupLocale() {
    const l = locale.value;
    changeLocaleMoment(l);
    changeLocaleVeeValidate(l);
    changeLocaleVuetify(l);
  }

  function setupDisplaySettings() {
    if (!isSignedIn.value) return;
    const { theme, color } = displaySettings.value;
    changeDark(theme === 'dark');
    changePrimary(color);
  }

  onReady(() => {
    setupLocale();
    setupDisplaySettings();
  });
  watch(locale, setupLocale);
  watch(displaySettings, setupDisplaySettings);

  return { locale };
}

type FrameRootSide = Optional<boolean>;
type FrameRootAdminMode = boolean;
export type FrameRootDisplayMode = Optional<'desktop' | 'mobile'>;

export type FrameRootValue = {
  side: FrameRootSide;
  mode: FrameRootDisplayMode;
  admin: FrameRootAdminMode;
};

export type FrameRootChangePayload = FrameRootValue & {
  keepRoute: boolean;
};

export type PropsFrameRoot = {
  frame: FrameRootValue;
  showProminentExtension: boolean;
  hideSide: boolean;
  hideTop: boolean;
  hideReturn: boolean;
  hideNotifications: boolean;
  appBarImage?: string;
  appBarClass?: string;
};

export function useFrameRoot(
  props: PropsFrameRoot,
  emit: (name: string, args: FrameRootValue | FrameRootChangePayload) => void
) {
  const { isSignedIn, groupId, group: storeGroup } = useGlobalStore();
  const group = computed(() => {
    if (groupId.value && storeGroup.value?.id === groupId.value) return storeGroup.value;
    return undefined;
  });

  const route = useRoute();
  const isHome = computed(() => route.name === 'home');
  const frameMode = computed(() => props.frame.mode);
  const frameAdmin = computed(() => props.frame.admin);

  function changeSide(side: FrameRootSide) {
    emit('change-frame', { ...props.frame, side });
  }

  function toggleMode(keepRoute = false) {
    const mode = frameMode.value === 'desktop' ? 'mobile' : 'desktop';
    emit('change-frame', { ...props.frame, mode, keepRoute });
  }

  function changeAdmin(admin: FrameRootAdminMode) {
    if (admin === frameAdmin.value) return;
    emit('change-frame', { ...props.frame, admin });
  }

  function toggleAdmin() {
    const admin = !frameAdmin.value;
    emit('change-frame', { ...props.frame, admin });
  }

  const { y } = useWindowScroll();
  const { breakpoint } = useVuetify();

  const frameProps = computed(() => {
    const isMobile = frameMode.value === 'mobile';
    const data: {
      topHeight: number;
      shrinkLimit?: number;
      showTop?: boolean;
      showProminent?: boolean;
      showExtension?: boolean;
      src?: string;
    } = {
      topHeight: TOP_HEIGHT,
      showTop: !props.hideTop,
    };
    const dataClasses: string[] = [];
    const side = isMobile ? false : props.frame.side;
    if (props.hideSide || !side) {
      dataClasses.push('frame-root-top-prominent--no-side');
      if (props.showProminentExtension)
        dataClasses.push('frame-root-top-prominent--no-side-content');
    }
    if (isMobile) {
      data.src = props.appBarImage;
      if (props.appBarClass) dataClasses.push(props.appBarClass);
      if (props.showProminentExtension) {
        data.shrinkLimit = TOP_SHRINK_LIMIT;
        data.showExtension = true;
        data.showTop = y.value <= TOP_SHRINK_LIMIT;
        data.showProminent = y.value <= TOP_SHRINK_LIMIT;
        data.topHeight = data.showProminent ? TOP_HEIGHT_PROMINENT : TOP_HEIGHT_EXTENDED;
        dataClasses.push('frame-root-top-prominent-with-extension');
      } else {
        data.showTop = true;
        data.showExtension = false;
        data.showProminent = false;
      }
    }
    return {
      ...data,
      side,
      sideWidth: isMobile ? 0 : SIDE_WIDTH,
      sideOverlay: breakpoint.value?.mobile,
      hideSide: props.hideSide,
      hideTop: props.hideTop,
      hideReturn: props.hideReturn,
      mode: props.frame.mode,
      admin: props.frame.admin,
      class: dataClasses.join(' '),
    };
  });

  return {
    isSignedIn,
    isHome,
    group,
    frameMode,
    frameAdmin,
    frameProps,
    changeSide,
    toggleMode,
    changeAdmin,
    toggleAdmin,
    ...useDisplaySettings(),
  };
}
