import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { Optional } from '@/base/types';

import { useMessages } from '../..';
import { useColor } from '../../utils/ColorUtils';

export type BaseSelectSearchSearchText = Optional<string>;

export type BaseSelectSearchValue = Optional<string | string[]>;

export type BaseSelectSearchItem<T extends { id: string }> = T;

export type BaseSelectSearchItemSelection<T extends { id: string }> = {
  item: BaseSelectSearchItem<T>;
  selected: boolean;
};

export type PropsBaseSelectSearchSelect<T extends { id: string }> = {
  value: BaseSelectSearchValue;
  multiple: boolean;
  items: BaseSelectSearchItem<T>[];
  label?: string;
  required: boolean;
  disabled: boolean;
};

export function useBaseSelectSearch<T extends { id: string }>(
  props: PropsBaseSelectSearchSelect<T>,
  emit: (name: string, args: void | BaseSelectSearchValue | BaseSelectSearchSearchText) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.baseSelectSearch' });

  const menu = ref(false);
  watch(menu, (newVal) => {
    if (newVal) return;
    emit('close');
  });

  const input = ref<string[]>([]);
  const itemSelections = computed<BaseSelectSearchItemSelection<T>[]>(() =>
    props.items.map((item) => ({ item, selected: input.value.includes(item.id) }))
  );

  function toggle(v: string) {
    const exists = input.value.includes(v);
    if (exists && !props.multiple) return;
    if (!props.multiple) {
      emit('change', v);
      return;
    }
    if (exists) {
      const ids = input.value.filter((id) => id !== v);
      emit('change', ids);
      return;
    }
    emit('change', [...input.value, v]);
  }

  function clear() {
    emit('change', undefined);
  }

  function init() {
    if (!props.value) {
      input.value = [];
      return;
    }
    if (typeof props.value === 'string') {
      input.value = [props.value];
      return;
    }
    if (props.value.length === 0) {
      input.value = [];
      return;
    }
    if (props.multiple) {
      input.value = [...props.value];
      return;
    }
    input.value = [props.value[0]];
  }

  onMounted(init);
  watch(() => props.value, init);

  const searchText = ref<BaseSelectSearchSearchText>();
  watch(searchText, (newVal) => emit('search', newVal));
  watch(menu, (newVal) => {
    if (newVal) return;
    searchText.value = undefined;
  });

  const veeRules = computed(() => {
    if (!props.required) return undefined;
    return 'required';
  });

  const veeLabel = computed(() => {
    if (props.label) return props.label;
    return msgs.of('defaultLabel').value;
  });

  const formatLabel = computed(() => {
    if (!props.label) return undefined;
    if (props.required) return `${props.label}*`;
    return props.label;
  });

  const labelNoData = computed(() =>
    props.items.length === 0 ? msgs.of('noData').value : undefined
  );

  const { dark } = useColor();
  const themeClass = computed(() => ({ 'theme--light': !dark.value, 'theme--dark': dark.value }));

  return {
    menu,
    input,
    itemSelections,
    searchText,
    veeRules,
    veeLabel,
    formatLabel,
    labelNoData,
    labelSearch: msgs.of('search'),
    themeClass,
    toggle,
    clear,
  };
}
