






import { defineComponent } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import BaseButtonIcon from './BaseButtonIcon.vue';

export default defineComponent({
  name: 'BaseRefreshButton',
  components: { BaseButtonIcon },
  inheritAttrs: false,
  emits: ['click'],
  setup() {
    const msgs = useMessages({ prefix: 'base.atoms.refreshButton' });
    return { label: msgs.of('refresh') };
  },
});
