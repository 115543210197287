









import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import { useMentionMarkdown } from './MentionMarkdownComposable';

export default defineComponent({
  name: 'BaseMentionMarkdown',
  components: { BaseMarkdown },
  inheritAttrs: false,
  emits: ['click-anchor', 'click-mention', 'mark'],
  setup(_, { emit }: SetupContext) {
    return useMentionMarkdown(emit);
  },
});
