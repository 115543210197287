import { computed, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { VueEmit } from '@/base/types';

export type PropsBaseToast = {
  absolute: boolean;
  fixed: boolean;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  error: boolean;
  emit: VueEmit;
};

export function useBaseToast(props: PropsBaseToast) {
  const display = ref(false);
  const messages = ref<string[]>([]);
  let timer: { stop: () => void } | undefined;

  function hide() {
    if (timer) {
      timer.stop();
      timer = undefined;
    }
    display.value = false;
    messages.value = [];
    props.emit('hide');
  }

  function show(msgs?: string[], timeout?: number) {
    messages.value = msgs ?? [];
    display.value = true;
    if (timer) {
      timer.stop();
      timer = undefined;
    }
    if (timeout !== undefined) {
      timer = useTimeoutFn(hide, 1000 * timeout);
    }
    props.emit('show');
  }

  const backgroundColor = computed(() => (props.error ? 'error' : undefined));
  const backgroundStyle = computed(() => {
    const position = (() => {
      if (props.absolute) return 'absolute';
      if (props.fixed) return 'fixed';
      return undefined;
    })();
    return {
      position,
      top: props.top,
      bottom: props.bottom,
      left: props.left,
      right: props.right,
    };
  });
  const buttonColor = computed(() => (props.error ? 'white' : undefined));
  const textColor = computed(() => (props.error ? 'white--text' : undefined));
  return {
    display,
    messages,
    backgroundColor,
    backgroundStyle,
    buttonColor,
    textColor,
    show,
    hide,
  };
}

export type BaseToast = ReturnType<typeof useBaseToast>;
