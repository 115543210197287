






































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Questionnaire } from '@/base/domains';

import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import MenuWrapper from '../atoms/MenuWrapper.vue';
import QuestionnaireAnswerBreakdownDoughnutChart from './QuestionnaireAnswerBreakdownDoughnutChart.vue';
import {
  PropsQuestionnaireProgress,
  QuestionnaireProgressRespondent,
  useQuestionnaireProgress,
} from './QuestionnaireProgressComposable';
import QuestionnaireRespondentTable from './QuestionnaireRespondentTable.vue';

type Props = PropsQuestionnaireProgress;

export default defineComponent({
  name: 'BaseQuestionnaireProgress',
  components: {
    BaseMarkdown,
    MenuWrapper,
    QuestionnaireRespondentTable,
    QuestionnaireAnswerBreakdownDoughnutChart,
  },
  inheritAttrs: false,
  props: {
    questionnaire: { type: Object as PropType<Questionnaire>, required: true },
    respondent: { type: Array as PropType<QuestionnaireProgressRespondent[]>, default: () => [] },
    tableStoreOptionId: { type: String, default: undefined },
    tableHeight: { type: String, default: 'calc(100vh - 200px)' },
  },
  emits: ['click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionnaireProgress(props, emit);
  },
});
