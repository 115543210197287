













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseBaseLink',
  inheritAttrs: false,
  props: {
    label: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    href: { type: String, default: undefined },
  },
  emits: ['click'],
});
