



import { computed, defineComponent } from '@vue/composition-api';

const HAND_STATUS_EMOJI = {
  yes: '🙋',
  no: '🙅‍♂️',
};

type Props = {
  status: 'yes' | 'no' | 'none';
};

export default defineComponent({
  name: 'BaseGroupUserStatus',
  props: { status: { type: String, default: undefined } },
  setup(props: Props) {
    const emoji = computed(() => {
      if (!props.status) return undefined;
      const keys = Object.keys(HAND_STATUS_EMOJI);
      if (keys.includes(props.status)) return HAND_STATUS_EMOJI[props.status];
      return undefined;
    });
    return { emoji };
  },
});
