import { computed } from '@vue/composition-api';

import { ApplicationError } from '@/base/error';
import { Optional } from '@/base/types';

import { useMessages } from '../../Messages';

export type ErrorMessage = string | ApplicationError;

function isAE(item: ErrorMessage): item is ApplicationError {
  return typeof item !== 'string';
}

export type ReplaceError = {
  from: string;
  to: {
    prefix: string;
    key: string;
    values?: (e: ApplicationError[]) => Optional<Record<string, string | number>>;
  };
};

export type PropsErrorMessages = {
  errors: ErrorMessage[];
  replace: ReplaceError[];
};

type MessageAE = { code: string; errors: ApplicationError[] };
type MessageItem = string | MessageAE;

function isMessageAE(item: MessageItem): item is MessageAE {
  return typeof item !== 'string';
}

export function useErrorMessages(props: PropsErrorMessages) {
  const msgs = useMessages({ prefix: 'applicationError' });
  const message = computed(() => {
    const list = props.errors.reduce((p, c) => {
      if (isAE(c)) {
        const mae = p.find((item) => isMessageAE(item) && item.code === c.code);
        if (mae && isMessageAE(mae)) mae.errors.push(c);
        else p.push({ code: c.code, errors: [c] });
        return p;
      }
      return p.concat(c);
    }, [] as MessageItem[]);
    return list
      .map((item) => {
        if (isMessageAE(item)) {
          const re = props.replace.find((r) => r.from === item.code);
          if (!re) return msgs.of(item.code).value;
          return useMessages({ prefix: re.to.prefix }).of(
            re.to.key,
            re.to.values ? re.to.values(item.errors) : undefined
          ).value;
        }
        return item;
      })
      .join();
  });
  return { message };
}
