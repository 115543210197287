














import { defineComponent, PropType } from '@vue/composition-api';

import { NotificationReference } from '@/base/domains';

import {
  PropsNotificationBarGroupUser,
  useNotificationBarGroupUser,
} from './NotificationBarGroupUserComposable';

type Props = PropsNotificationBarGroupUser;

export default defineComponent({
  name: 'BaseNotificationBarGroupUser',
  components: {},
  props: {
    notification: { type: Object as PropType<NotificationReference>, required: true },
    omit: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useNotificationBarGroupUser(props);
  },
});
