import { useMessages } from '../../Messages';

export type NotificationBarOnGooglePlay = {
  id: string;
  type: 'SYSTEM_ON_GOOGLE_PLAY';
};

export type PropsNotificationBarOnGooglePlay = {
  notification: NotificationBarOnGooglePlay;
};

export function useNotificationBarOnGooglePlay() {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarOnGooglePlay' });
  return { body: msgs.of('getIt') };
}
