





















import { defineComponent, SetupContext } from '@vue/composition-api';

import DialogNotifications from './DialogNotifications.vue';
import DialogQuestionnaire from './DialogQuestionnaire.vue';
import DialogQuestionnaireAnswer from './DialogQuestionnaireAnswer.vue';
import DialogSettings from './DialogSettings.vue';
import DialogUserAvatar from './DialogUserAvatar.vue';
import DialogUserDetail from './DialogUserDetail.vue';
import { useFrameDialogs } from './FrameDialogsComposable';
import ToastNotifications from './ToastNotifications.vue';

export default defineComponent({
  name: 'BaseFrameDialogs',
  components: {
    DialogNotifications,
    DialogQuestionnaire,
    DialogQuestionnaireAnswer,
    DialogSettings,
    DialogUserAvatar,
    DialogUserDetail,
    ToastNotifications,
  },
  props: {
    adminMode: { type: Boolean, default: false },
    disabledNotifications: { type: Boolean, default: false },
  },
  emits: ['change-admin-mode'],
  setup(_, { emit }: SetupContext) {
    return useFrameDialogs(emit);
  },
});
