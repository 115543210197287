import { computed, reactive, ref } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import { or } from '../../utils/FilterUtils';
import { BaseMarkdownMentionUser, MENTION_MARK } from '../atoms/BaseMarkdownMention';

export type Mention = BaseMarkdownMentionUser & {
  description?: string;
};

type Target = {
  priorities: Mention[];
  mentions: Mention[];
};

export type TextEditorMentionsDialogSelectPayload = {
  id: string;
};

export function useTextEditorMentionsDialog(
  emit: (name: 'select', args: TextEditorMentionsDialogSelectPayload) => void
) {
  const dialog = ref(false);
  const search = ref<string>();
  const target = reactive<Target>({ priorities: [], mentions: [] });

  function close() {
    dialog.value = false;
    search.value = undefined;
    target.priorities = [];
    target.mentions = [];
  }

  function open(payload: Target) {
    search.value = undefined;
    target.priorities = payload.priorities;
    target.mentions = payload.mentions;
    dialog.value = true;
  }

  function select(id: string) {
    emit('select', { id });
    close();
  }

  const items = computed(() => {
    const priorities = target.priorities.map((item) => ({ ...item, priority: true }));
    const mentions = target.mentions
      .filter((item) => !target.priorities.some((p) => p.id === item.id))
      .map((item) => ({ ...item, priority: false }));
    return [...priorities, ...mentions]
      .map((item) => ({ ...item, name: `${MENTION_MARK}${item.name}` }))
      .filter((item) => or(item.name, search.value));
  });
  const divideCount = computed(() => items.value.filter((item) => item.priority).length);

  const msgs = useMessages({ prefix: 'base.molecules.textEditorMentionsDialog' });
  return {
    dialog,
    search,
    items,
    divideCount,
    labelSearch: msgs.of('search'),
    labelClose: msgs.of('close'),
    labelNoData: msgs.of('noData'),
    labelLatest: msgs.of('latest'),
    close,
    open,
    select,
  };
}

export type TextEditorMentionsDialog = ReturnType<typeof useTextEditorMentionsDialog>;
