






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseBaseButton',
  inheritAttrs: false,
  props: { outlined: { type: Boolean, default: true } },
  emits: ['click'],
});
