



import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  PropsQuestionnaireRespondentTable,
  QuestionnaireRespondentTableItem,
  useQuestionnaireRespondentTable,
} from './QuestionnaireRespondentTableComposable';

type Props = PropsQuestionnaireRespondentTable;

export default defineComponent({
  name: 'BaseQuestionnaireRespondentTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    respondent: { type: Array as PropType<QuestionnaireRespondentTableItem[]>, default: () => [] },
  },
  setup(props: Props) {
    return useQuestionnaireRespondentTable(props);
  },
});
