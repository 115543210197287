import { ref } from '@vue/composition-api';

import { useMessages } from '../../Messages';

export type QuestionnaireConfirmFinishDialogSubmitPayload = { value: boolean; done: () => void };

export function useQuestionnaireConfirmFinishDialog(
  emit: (name: string, args: QuestionnaireConfirmFinishDialogSubmitPayload) => void
) {
  const dialog = ref(false);
  const saveQuestionnaire = ref(true);

  function open() {
    dialog.value = true;
  }

  function close() {
    dialog.value = false;
  }

  function ok() {
    emit('finish', { value: saveQuestionnaire.value, done: close });
  }

  const msgs = useMessages({ prefix: 'base.molecules.questionnaireConfirmFinishDialog' });
  return {
    dialog,
    saveQuestionnaire,
    messageConfirm: msgs.of('messageConfirm'),
    labelSaveQuestionnaire: msgs.of('saveQuestionnaire'),
    labelYes: msgs.of('yes'),
    labelNo: msgs.of('no'),
    ok,
    open,
    close,
  };
}

export type QuestionnaireConfirmFinishDialog = ReturnType<
  typeof useQuestionnaireConfirmFinishDialog
>;
