




























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import ErrorMessages from './ErrorMessages.vue';
import {
  PropsTextEditorFileUploadItem,
  TextEditorFileUploadItem,
  useTextEditorFileUploadItem,
} from './TextEditorFileUploadItemComposable';

type Props = PropsTextEditorFileUploadItem;

export default defineComponent({
  name: 'BaseTextEditorUploadItem',
  components: { ErrorMessages },
  props: { item: { type: Object as PropType<TextEditorFileUploadItem>, required: true } },
  emits: ['close'],
  setup(props: Props, { emit }: SetupContext) {
    return useTextEditorFileUploadItem(props, emit);
  },
});
