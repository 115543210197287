








import { computed, defineComponent, PropType } from '@vue/composition-api';

import { LocalDateTime } from '@/base/types';

type Props = {
  value?: LocalDateTime;
  prepend: string;
  append: string;
};

export default defineComponent({
  name: 'BaseDateTimeNow',
  props: {
    value: { type: Object as PropType<LocalDateTime>, default: undefined },
    prepend: { type: String, default: '(' },
    append: { type: String, default: ')' },
  },
  setup(props: Props) {
    const formNow = computed(
      () => `${props.prepend}${props.value?.fromNow() || '---'}${props.append}`
    );
    const formatted = computed(() => props.value?.format('Y/M/D HH:mm') || '---');
    return { formNow, formatted };
  },
});
