








import { computed, defineComponent } from '@vue/composition-api';

import { useMessages } from '../../Messages';

type Props = {
  value: boolean;
  label?: string;
  hideLabel: boolean;
};

export default defineComponent({
  name: 'BaseLoading',
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    hideLabel: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.organisms.loading' });
    const message = computed(() => props.label || msgs.of('loading').value);
    return { message };
  },
});
