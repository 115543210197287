import { computed } from '@vue/composition-api';

import { GroupId, GroupName, GroupRole, Role } from '../../../domains';
import { useMessages } from '../..';
import { GlobalStoreLimitationName } from '../../store/types';

type GroupMenuRole = {
  eqAdminMode: boolean;
  roles: (GroupRole | Role)[];
};

const GROUP_MENUS: {
  value: string;
  icon: string;
  limitation?: GlobalStoreLimitationName;
  inRoles?: GroupMenuRole[];
}[] = [
  {
    value: 'groupCourses',
    icon: 'mdi-bookshelf',
  },
  {
    value: 'groupQuestions',
    icon: 'mdi-forum',
    limitation: 'question',
  },
  {
    value: 'trainerGroupQuestionnaires',
    icon: 'mdi-chart-donut',
    limitation: 'questionnaire',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: ['admin', 'supervisor'] },
    ],
  },
  {
    value: 'groupQuestionnaires',
    icon: 'mdi-order-bool-descending',
    limitation: 'questionnaire',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor', 'trainee'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
  {
    value: 'groupSchedules',
    icon: 'mdi-calendar',
    limitation: 'schedule',
  },
  {
    value: 'groupExamResultHistory',
    icon: 'mdi-text-box-check-outline',
    inRoles: [
      { eqAdminMode: false, roles: ['trainee'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
  {
    value: 'trainerGroupExamResultHistory',
    icon: 'mdi-text-box-check-outline',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: ['admin', 'supervisor'] },
    ],
  },
  {
    value: 'groupUsers',
    icon: 'mdi-account',
  },
  {
    value: 'trainerGroupCourses',
    icon: 'mdi-cog',
    inRoles: [
      { eqAdminMode: false, roles: ['trainer', 'mentor'] },
      { eqAdminMode: true, roles: [] },
    ],
  },
];

type Menu = {
  /**
   * 値
   * @remarks
   * ルーティングの名前として利用される。
   */
  value: string;
  /** アイコン */
  icon: string;
  /** メニューを利用するのに必要なグループ制限 */
  limitation?: GlobalStoreLimitationName;
  /** 管理者モードでのみ表示する */
  eqAdminMode?: boolean;
  /** 特定のロールで表示する */
  inRoles?: Role[];
  /** 何れかのグループでグループロールが割り当てられている場合に表示する */
  anyGroupRole?: GroupRole[];
};

const MENUS: Menu[] = [
  { value: 'home', icon: 'mdi-home' },
  {
    value: 'userReport',
    icon: 'mdi-chart-donut',
    eqAdminMode: false,
    anyGroupRole: ['mentor', 'trainer'],
  },
  {
    value: 'adminGroups',
    icon: 'mdi-account-group',
    eqAdminMode: true,
    inRoles: ['admin', 'supervisor'],
  },
  {
    value: 'adminCourses',
    icon: 'mdi-bookshelf',
    eqAdminMode: true,
    inRoles: ['admin', 'supervisor'],
  },
  {
    value: 'adminAccounts',
    icon: 'mdi-account',
    eqAdminMode: true,
    inRoles: ['admin', 'supervisor'],
  },
  {
    value: 'adminUserReport',
    icon: 'mdi-chart-donut',
    eqAdminMode: true,
    inRoles: ['admin', 'supervisor'],
  },
  {
    value: 'adminTenantOwnTermsOfService',
    icon: 'mdi-file-document-edit-outline',
    limitation: 'tenantOwnTermsOfService',
    eqAdminMode: true,
    inRoles: ['supervisor'],
  },
];

export type FrameMainMenuListGroup = {
  id: string;
  name: string;
  role?: GroupRole;
  menusAvailable: GlobalStoreLimitationName[];
};

export type FrameMainMenuListAssignedGroup = {
  id: GroupId;
  name: GroupName;
  groupRole: GroupRole;
};

export type PropsFrameMainMenuList = {
  tenantName: string;
  version: string;
  menusAvailable: GlobalStoreLimitationName[];
  role: Role;
  group?: FrameMainMenuListGroup;
  adminMode: boolean;
  assignedGroups: FrameMainMenuListAssignedGroup[];
};

export function useFrameMainMenuList(props: PropsFrameMainMenuList) {
  const msgs = useMessages({ prefix: 'base.molecules.frameMainMenuList' });

  const title = computed(() => {
    if (props.group) return props.group.name;
    return props.tenantName;
  });

  const subtitle = computed(() => {
    if (props.adminMode && props.group) return msgs.of('adminMode').value;
    if (props.group) return msgs.of(props.group.role ?? 'notBelong').value;
    return `kNowte ${props.version}`;
  });

  const groupMenus = computed(() => {
    if (!props.group) return [];
    const { adminMode, role } = props;
    const { menusAvailable: available, role: gRole } = props.group;
    return GROUP_MENUS.filter(
      (item) =>
        (!item.limitation || available.includes(item.limitation)) &&
        (!item.inRoles ||
          item.inRoles.some(
            ({ eqAdminMode, roles }) =>
              eqAdminMode === adminMode &&
              (roles.includes(role) || (gRole && roles.includes(gRole)))
          ))
    ).map((item) => ({
      value: item.value,
      icon: item.icon,
      label: msgs.of(item.value).value,
      to: { name: item.value, params: { id: props.group?.id } },
    }));
  });

  const menus = computed(() => {
    const { menusAvailable: available, role, adminMode, assignedGroups } = props;
    const groupRoleSummary = new Set(assignedGroups.map((g) => g.groupRole));
    return MENUS.filter(
      (item) =>
        (!item.limitation || available.includes(item.limitation)) &&
        (item.eqAdminMode === undefined || item.eqAdminMode === adminMode) &&
        (item.inRoles === undefined || item.inRoles.includes(role)) &&
        (item.anyGroupRole === undefined ||
          item.anyGroupRole.find((groupRole) => groupRoleSummary.has(groupRole)))
    ).map((item) => ({
      value: item.value,
      icon: item.icon,
      label: msgs.of(item.value).value,
      to: { name: item.value },
    }));
  });

  return { title, subtitle, groupMenus, menus };
}
