
















































import { defineComponent } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import BaseDialogFullScreen from '../molecules/BaseDialogFullScreen.vue';
import CropperVue from '../molecules/Cropper.vue';
import { useDialogUserAvatar } from './DialogUserAvatarComposable';

export default defineComponent({
  name: 'BaseDialogUserAvatar',
  components: { BaseDialogFullScreen, BaseButton, Cropper: CropperVue },
  props: { maxHeight: { type: String, default: '' } },
  setup() {
    return useDialogUserAvatar();
  },
});
