import { nextTick, Ref, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import { v4 } from 'uuid';

import { Optional } from '@/base/types';
import { isSucceeded, UploadFileType, useUploadFile } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../..';
import { getImageWidth } from '../../utils/FileUtils';
import { ErrorMessage } from './ErrorMessagesComposable';
import {
  TextEditorFileUploadItem,
  TextEditorFileUploadItemClosePayload,
} from './TextEditorFileUploadItemComposable';
import { replaceAt } from './TextEditorUtils';

const MEDIA_FILETYPES = ['image/', 'audio/', 'video/'];
const IMAGE_MAX_WIDTH = 750;

async function toMarkdownText(uri: string, item: TextEditorFileUploadItem) {
  const isMedia = MEDIA_FILETYPES.some((v) => item.file.type.startsWith(v));
  if (isMedia) {
    const imgWidth = await getImageWidth(item.file).then((w) => {
      if (w === undefined) return '';
      if (w > IMAGE_MAX_WIDTH) return ` w${IMAGE_MAX_WIDTH}`;
      return ` w${w}`;
    });
    return `![${item.name}](${uri} ""${imgWidth})`;
  }
  return `[${item.name}](${uri})`;
}

export type PropsTextEditorFileUpload = {
  disabled: boolean;
  type: UploadFileType;
  input: Ref<Optional<string>>;
  getTextArea: () => Optional<HTMLTextAreaElement>;
};

export function useTextEditorFileUpload(
  props: PropsTextEditorFileUpload,
  emit: (name: 'change', args: Optional<string>) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.textEditorFileUpload' });

  const uploading = ref(false);
  function showUploading() {
    if (uploading.value) return;
    uploading.value = true;
    useTimeoutFn(() => {
      uploading.value = false;
    }, 3000);
  }

  let selectionStart = 0;
  const items = ref<TextEditorFileUploadItem[]>([]);

  async function onUploaded(id: string, uri: string) {
    const i = items.value.findIndex((item) => item.id === id);
    if (i === -1) return;
    const [item] = items.value.splice(i, 1);
    const text = await toMarkdownText(uri, item);
    const { value, index } = replaceAt(props.input.value, text, selectionStart, selectionStart, {
      space: false,
      enter: true,
    });
    selectionStart = index;
    emit('change', value);
  }

  function onFailed(id: string, errors: ErrorMessage[]) {
    const failedItem = items.value.find((item) => item.id === id);
    if (!failedItem) return;
    failedItem.errors = errors;
    useTimeoutFn(() => {
      const i = items.value.findIndex((item) => item.id === id);
      if (i === -1) return;
      items.value.splice(i, 1);
    }, 3000);
  }

  const uploadFile = useUploadFile();
  function start() {
    const ready = items.value.filter((item) => !item.started);
    if (ready.length === 0) {
      uploading.value = false;
      return;
    }
    const count = items.value.filter((item) => item.started && !item.completed).length;
    if (count > 0) return;
    const item = ready.shift();
    if (!item) return;
    item.started = true;
    uploadFile
      .execute({
        filename: item.name,
        file: item.file,
        type: props.type,
        onProgress: (progress) => Object.assign(item.progress, progress),
      })
      .then((res) => {
        item.completed = true;
        if (isSucceeded(res)) onUploaded(item.id, res.uri);
        else onFailed(item.id, res.errors);
      })
      .finally(start);
  }

  function prepare(files: File[], isDrop: boolean) {
    if (props.disabled) return;
    const count = items.value.filter((item) => item.started && !item.completed).length;
    if (count > 0) {
      showUploading();
      return;
    }
    const area = props.getTextArea();
    assertIsDefined(area, 'textarea');
    selectionStart = area.selectionStart;
    files.forEach((f) => {
      const id = v4();
      const invalid = isDrop && !f.type;
      items.value.push({
        id,
        name: f.name,
        file: f,
        started: invalid,
        completed: invalid,
        progress: { loaded: 0, total: f.size },
      });
      if (invalid) onFailed(id, [msgs.of('invalidFileType').value]);
    });
    nextTick(start);
  }

  function cancel(payload: TextEditorFileUploadItemClosePayload) {
    const i = items.value.findIndex((item) => item.id === payload.id && !item.started);
    if (i === -1) return;
    items.value.splice(i, 1);
  }

  function clear() {
    items.value = [];
    uploading.value = false;
    selectionStart = 0;
  }

  return {
    uploading,
    uploadItems: items,
    prepareUpload: prepare,
    cancelUpload: cancel,
    clearUpload: clear,
  };
}
