import { computed } from '@vue/composition-api';

import { ErrorMessage } from './ErrorMessagesComposable';

export type TextEditorFileUploadItem = {
  id: string;
  name: string;
  file: File;
  started: boolean;
  completed: boolean;
  progress: { total: number; loaded: number };
  errors?: ErrorMessage[];
};

export type TextEditorFileUploadItemClosePayload = {
  id: string;
};

export type PropsTextEditorFileUploadItem = {
  item: TextEditorFileUploadItem;
};

export function useTextEditorFileUploadItem(
  props: PropsTextEditorFileUploadItem,
  emit: (name: 'close', args: TextEditorFileUploadItemClosePayload) => void
) {
  const waiting = computed(() => !props.item.started);
  const uploading = computed(() => props.item.started && !props.item.completed);
  const uploadingValue = computed(
    () => (props.item.progress.loaded / props.item.progress.total) * 100
  );
  const failed = computed(() => !!props.item.errors);

  function close() {
    emit('close', { id: props.item.id });
  }

  return { uploading, uploadingValue, waiting, failed, close };
}
