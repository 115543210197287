






import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from './BaseButtonIcon.vue';
import { useReturnButton } from './ReturnButtonComposable';

export default defineComponent({
  name: 'BaseReturnButton',
  components: { BaseButtonIcon },
  inheritAttrs: false,
  setup() {
    return useReturnButton();
  },
});
