import { computed, ComputedRef } from '@vue/composition-api';

import { Questionnaire } from '@/base/domains';

import { useMessages } from '../..';
import { useChartConfig } from '../../utils/ChartUtils';
import { getOptionLabels } from '../../utils/QuestionnaireUtils';

export type PropsQuestionnaireAnswerBreakdownDoughnutChart = {
  questionnaire: Questionnaire;
};

type QuestionnaireProgressDoughnutResponseData = {
  data: number[];
  labels: string[];
};

export function useQuestionnaireAnswerBreakdownDoughnutChart(
  props: PropsQuestionnaireAnswerBreakdownDoughnutChart
) {
  const msgs = useMessages({ prefix: 'base.molecules.questionnaireAnswerBreakdownDoughnutChart' });
  const { getChartColors } = useChartConfig();

  const responseData: ComputedRef<QuestionnaireProgressDoughnutResponseData> = computed(() => {
    const { respondent, userIds, options } = props.questionnaire;
    const labels = [...getOptionLabels(options.length)];
    const data = labels.map((_, i) => respondent.filter((item) => item.selectedIndex === i).length);

    labels.push(msgs.of('noAnswer').value);
    data.push(userIds.length - respondent.length);

    return { data, labels };
  });

  const data = computed(() => {
    return {
      labels: responseData.value.labels,
      datasets: [
        {
          data: responseData.value.data,
          backgroundColor: getChartColors(props.questionnaire.options.length + 1),
          borderWidth: 0,
        },
      ],
    };
  });

  const options = {
    cutout: '50%',
    maintainAspectRatio: true,
    plugins: {
      legend: { display: true },
    },
  };

  const style = computed(() => ({
    'min-height': '500px',
    width: '500px',
  }));

  return { data, options, style };
}
