






















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { NotificationReference } from '@/base/domains';

import BaseLink from './BaseLink.vue';
import {
  PropsNotificationBarQuestionnaire,
  useNotificationBarQuestionnaire,
} from './NotificationBarQuestionnaireComposable';

type Props = PropsNotificationBarQuestionnaire;

export default defineComponent({
  name: 'BaseNotificationBarQuestionnaire',
  components: { BaseLink },
  props: {
    notification: { type: Object as PropType<NotificationReference>, required: true },
    omit: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useNotificationBarQuestionnaire(props, emit);
  },
});
