
























































import { defineComponent } from '@vue/composition-api';

import Loading from '@/base/app/components/organisms/Loading.vue';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import BaseDialogConfirm from '../molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '../molecules/BaseDialogFullScreen.vue';
import DateTimeNow from '../molecules/DateTimeNow.vue';
import DialogAnchorConfirm from './DialogAnchorConfirm.vue';
import { useDialogQuestionnaireAnswer } from './DialogQuestionnaireAnswerComposable';

export default defineComponent({
  name: 'BaseDialogQuestionnaireAnswer',
  components: {
    BaseMarkdown,
    BaseButton,
    BaseDialogFullScreen,
    BaseDialogConfirm,
    DateTimeNow,
    Loading,
    DialogAnchorConfirm,
  },
  setup() {
    return useDialogQuestionnaireAnswer();
  },
});
