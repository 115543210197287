


























import { computed, defineComponent, PropType } from '@vue/composition-api';

import { useMessages } from '../../Messages';

type RadioItem = {
  value: string;
  text: string;
  color?: string;
};

type Props = {
  value?: string | boolean;
  label?: string;
  items: RadioItem[];
  veeRules?: string;
  veeVid?: string;
  veeLabel?: string;
};

export default defineComponent({
  name: 'BaseBaseRadioGroup',
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Boolean], default: undefined },
    label: { type: String, default: undefined },
    items: { type: Array as PropType<RadioItem[]>, default: () => [] },
    veeRules: { type: String, default: undefined },
    veeVid: { type: String, default: undefined },
    veeLabel: { type: String, default: undefined },
  },
  emits: ['change'],
  setup(props: Props) {
    const required = computed(
      () => props.veeRules && props.veeRules.split('|').includes('required')
    );
    const msgs = useMessages({ prefix: 'base.atoms.baseRadioGroup' });
    const veeName = computed(() => props.veeLabel || props.label || msgs.of('defaultLabel').value);
    return { required, veeName };
  },
});
