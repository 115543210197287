



import { defineComponent } from '@vue/composition-api';

import BaseDialogConfirm from '../molecules/BaseDialogConfirm.vue';
import { useDialogAnchorConfirm } from './DialogAnchorConfirmComposable';

export default defineComponent({
  name: 'BaseDialogAnchorConfirm',
  components: { BaseDialogConfirm },
  inheritAttrs: false,
  setup() {
    return useDialogAnchorConfirm();
  },
});
