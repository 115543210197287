


























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { NotificationReference } from '@/base/domains';

import BaseLink from './BaseLink.vue';
import { PropsNotificationBarExam, useNotificationBarExam } from './NotificationBarExamComposable';

type Props = PropsNotificationBarExam;

export default defineComponent({
  name: 'BaseNotificationBarExam',
  components: { BaseLink },
  props: {
    notification: { type: Object as PropType<NotificationReference>, required: true },
    omit: { type: Boolean, default: false },
    maxWidth: { type: String, default: undefined },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useNotificationBarExam(props, emit);
  },
});
