import { computed } from '@vue/composition-api';

import { Optional } from '@/base/types';

import { useMessages } from '../..';
import { useGlobalStore } from '../../store';
import { DialogName, useDialogQuery } from '../../utils/DialogQueryUtils';
import {
  FrameMainMenuListGroup,
  PropsFrameMainMenuList,
} from '../molecules/FrameMainMenuListComposable';

export type PropsFrameTopProminent = {
  mode: 'desktop' | 'mobile';
  admin: boolean;
  side: boolean;
  sideOverlay: boolean;
  sideWidth: number;
  label?: string;
  hideSide: boolean;
};

export function useFrameTopProminent(
  props: PropsFrameTopProminent,
  emit: (name: string, arg?: boolean) => void
) {
  const {
    existsUnRead,
    user,
    group,
    groupRole,
    tenantName,
    version,
    findUser,
    signOut,
    getGroupMenusAvailable,
    getTenantMenusAvailable,
    userAssignedGroups,
  } = useGlobalStore();
  const { moveTo: moveToNotifications } = useDialogQuery(DialogName.BASE_NOTIFICATIONS);

  const menuProps = computed<PropsFrameMainMenuList>(() => {
    const activeGroup: Optional<FrameMainMenuListGroup> = group.value
      ? {
          id: group.value.id,
          name: group.value.name,
          role: groupRole.value,
          menusAvailable: getGroupMenusAvailable(props.admin, group.value.id),
        }
      : undefined;
    return {
      tenantName: tenantName.value ?? '',
      version: version.value,
      menusAvailable: getTenantMenusAvailable(),
      role: user.value?.role ?? 'general',
      group: activeGroup,
      adminMode: props.admin,
      assignedGroups: userAssignedGroups.value.map((g) => ({
        id: g.id,
        name: g.name,
        groupRole: g.groupRole,
      })),
    };
  });
  const myAccount = computed(() => (user.value ? findUser(user.value.id) : undefined));

  const headerLabel = computed(() => props.label ?? group.value?.name ?? tenantName.value ?? '');
  const displayHeaderIcon = computed(() => !props.label && !!group.value);

  const displayOpenSide = computed(
    () => props.mode === 'desktop' && !props.hideSide && (!props.side || props.sideOverlay)
  );
  const displayNav = computed(() => props.mode === 'mobile' && !props.hideSide);
  const displayAdminControl = computed(
    () =>
      !window.$androidWebAppBridge &&
      (user.value?.role === 'supervisor' || user.value?.role === 'admin')
  );
  const displayMyAccount = computed(() => myAccount.value && !props.hideSide);

  function toggleFrameMode(keepRoute = false) {
    emit('toggle-frame-mode', keepRoute);
  }

  function toggleAdminControl() {
    emit('toggle-frame-admin');
  }

  function openSide() {
    emit('open-side');
  }

  function openNotifications() {
    moveToNotifications();
  }

  const msgs = useMessages({ prefix: 'base.organisms.frameTopProminent' });
  return {
    groupRole,
    menuProps,
    myAccount,
    existsUnRead,
    headerLabel,
    displayHeaderIcon,
    displayOpenSide,
    displayNav,
    displayAdminControl,
    displayMyAccount,
    labelExistsUnRead: msgs.of('existsUnRead'),
    labelNotExistsUnRead: msgs.of('notExistsUnRead'),
    labelDisplayAdmin: msgs.of('displayAdmin'),
    labelDisplayGeneral: msgs.of('displayGeneral'),
    labelSwitchMobileView: msgs.of('switchMobileView'),
    toggleFrameMode,
    toggleAdminControl,
    openSide,
    openNotifications,
    signOut,
  };
}
