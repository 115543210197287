import Token from 'markdown-it/lib/token';

const CONTAINERS = [
  { id: 'try', icon: 'mdi-arm-flex' },
  { id: 'answer', icon: 'mdi-checkbox-marked-circle-outline' },
  { id: 'hint', icon: 'mdi-lightbulb-outline' },
  { id: 'important', icon: 'mdi-alert-circle-outline' },
  { id: 'annotation', icon: 'mdi-lightbulb-on-outline' },
  { id: 'column', icon: 'mdi-comment-outline' },
  { id: 'relax', icon: 'mdi-coffee-outline' },
  { id: 'translation', icon: 'mdi-translate' },
  { id: 'default' },
];

export function useBaseMarkdownContainer(prefix: string) {
  function validate() {
    return true;
  }
  function createIcon(id: string, dark: boolean) {
    const container = CONTAINERS.find((item) => item.id === id);
    if (!container?.icon) return '';
    const theme = dark ? 'theme--dark' : 'theme--light';
    return `<i class="mdi ${container.icon} ${theme} ${prefix}-container-icon"></i>`;
  }
  function render(tokens: Token[], idx: number, _: unknown, env: { dark: boolean }) {
    const { nesting, info } = tokens[idx];
    const id = info || 'default';
    if (nesting === 1) {
      // open
      const theme = env.dark ? 'theme--dark' : 'theme--light';
      const classes = [`${prefix}-container`, `${prefix}-container-${id}`, theme].join(' ');
      const icon = createIcon(id, env.dark);
      return `<div class="${classes}">${icon}<div class="${prefix}-container-body">`;
    }
    // close
    return '</div></div>';
  }
  return { validate, render };
}
