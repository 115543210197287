import { computed } from '@vue/composition-api';
import { Location } from 'vue-router';

import { NotificationReference } from '@/base/domains';
import {
  ExamReminderNotification,
  ExamReminderNotificationPayload,
  StartExamNotification,
  StartExamNotificationPayload,
} from '@/base/NotificationTypes';
import { assertIsDefined } from '@/utils/Asserts';
import { localDateTimeFromString } from '@/utils/DateUtils';

import { useMessages } from '../..';

const LIMIT_TITLE_OMIT = 25;

export type NotificationBarExamClickPayload = {
  id: string;
  to: Location;
};

export type PropsNotificationBarExam = {
  notification: NotificationReference;
  omit: boolean;
};

export function useNotificationBarExam(
  props: PropsNotificationBarExam,
  emit: (name: string, args: NotificationBarExamClickPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarExam' });

  function parseStartExam(n: NotificationReference) {
    const { payload } = n.asTypeOf<StartExamNotificationPayload>(n.type);
    const name = props.omit
      ? `${payload.contentName.slice(0, LIMIT_TITLE_OMIT)}${
          payload.contentName.length > LIMIT_TITLE_OMIT ? '...' : ''
        }`
      : payload.contentName;
    const description = msgs.of('active', { contentName: name }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.contentName,
      to: {
        name: 'groupUserExam',
        params: {
          id: payload.groupId,
          examId: payload.examId,
        },
      },
    };
    return { description, group, course: payload.courseName, link };
  }

  function parseExamReminder(n: NotificationReference) {
    const { payload } = n.asTypeOf<ExamReminderNotificationPayload>(n.type);
    const name = props.omit
      ? `${payload.contentName.slice(0, LIMIT_TITLE_OMIT)}${
          payload.contentName.length > LIMIT_TITLE_OMIT ? '...' : ''
        }`
      : payload.contentName;
    const description = msgs.of('reminder', {
      contentName: name,
      start: localDateTimeFromString(payload.scheduledStart).format('lll'),
    }).value;
    const group = { id: payload.groupId };
    const link = {
      label: payload.contentName,
      to: {
        name: 'groupUserExam',
        params: {
          id: payload.groupId,
          examId: payload.examId,
        },
      },
    };
    return { description, group, course: payload.courseName, link };
  }

  const data = computed(() => {
    const n = props.notification;
    switch (n.type) {
      case StartExamNotification:
        return parseStartExam(n);
      case ExamReminderNotification:
        return parseExamReminder(n);
      default:
    }
    return undefined;
  });

  function click(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    assertIsDefined(data.value?.link?.to, 'to');
    emit('click', { id: props.notification.id, to: data.value.link.to });
  }

  return { data, click };
}
