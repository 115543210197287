import { computed, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { config } from '@/config';

import { useMessages } from '../../Messages';
import { BaseDialogOk } from './BaseDialogOkComposable';
import { BaseToast } from './BaseToastComposable';
import { ErrorMessage, ReplaceError } from './ErrorMessagesComposable';
import { UpdateStatusIcon, UpdateStatusIconChangePayload } from './UpdateStatusIconComposable';

export type BaseDialogFullScreenValue = {
  display: boolean;
  status?: UpdateStatusIcon;
};

export type PropsBaseDialogFullScreen = {
  value: BaseDialogFullScreenValue;
  delay?: number;
  disabled?: boolean;
  title?: string;
  subTitle?: string;
};

function useToast() {
  const toast = ref<BaseToast>();
  function showToast(msgs?: string[]) {
    if (!toast.value) return;
    if (msgs && msgs.length > 0) toast.value.show(msgs);
    else toast.value.hide();
  }
  return { toast, showToast };
}

function useDialog() {
  const dialog = ref<BaseDialogOk>();
  function showDialog(msg: string, ok?: () => void) {
    if (!dialog.value) return;
    dialog.value.open(msg, ok);
  }
  function showErrorDialog(errors: ErrorMessage[], replace?: ReplaceError[], ok?: () => void) {
    if (!dialog.value) return;
    dialog.value.error(errors, replace, ok);
  }
  return { dialog, showDialog, showErrorDialog };
}

export function useBaseDialogFullScreen(
  props: PropsBaseDialogFullScreen,
  emit: (name: string, arg?: BaseDialogFullScreenValue) => void
) {
  const display = computed(() => props.value.display);
  watch(display, (newVal) => {
    if (!newVal) emit('closed');
  });

  const statusValue = computed(() => props.value.status);
  const disabledDialog = computed(() => statusValue.value === 'updating');

  function changeStatus(payload: UpdateStatusIconChangePayload) {
    emit('change', { ...props.value, status: payload });
  }

  function changeDisplay(v: boolean, force = false) {
    if (!force && disabledDialog.value) return false;
    emit('change', { ...props.value, display: v });
    return true;
  }

  function changeDialog(v: boolean) {
    if (disabledDialog.value) {
      changeDisplay(v, true);
      return;
    }
    const d = props.delay ?? 100;
    const a = document.activeElement;
    if (a) {
      (a as HTMLElement).blur();
      useTimeoutFn(() => {
        if (changeDisplay(v)) return;
        useTimeoutFn(() => changeDisplay(v), config().app.transitionDelay * 1000);
      }, d);
    } else {
      changeDisplay(v);
    }
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.code !== 'Escape') return;
    if (!disabledDialog.value) changeDialog(false);
  }

  const msgs = useMessages({ prefix: 'base.molecules.baseDialogFullScreen' });
  return {
    display,
    statusValue,
    disabledDialog,
    labelClose: msgs.of('close'),
    changeStatus,
    changeDialog,
    onKeyDown,
    ...useToast(),
    ...useDialog(),
  };
}

export type BaseDialogFullScreen = ReturnType<typeof useBaseDialogFullScreen>;
