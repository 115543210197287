import { computed } from '@vue/composition-api';
import mi from 'markdown-it';
import miAnchor from 'markdown-it-anchor';
import miEmoji from 'markdown-it-emoji';
import miRuby from 'markdown-it-ruby';
import { Location } from 'vue-router';

import { NotificationReference } from '@/base/domains';
import {
  CreateGroupMemoNotification,
  CreateGroupMemoPayload,
  UpdateGroupMemoNotification,
  UpdateGroupMemoPayload,
} from '@/base/NotificationTypes';
import { Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

import { useMessages } from '../..';
import { BASE_MARKDOWN_OPTION } from './BaseMarkdownComposable';

const EXCLUDE_TOKEN_TYPES = ['image', 'video', 'audio'];
const CAPTION_LIMIT = 100;
const CAPTION_LIMIT_OMIT = 25;

export type NotificationBarMemoClickPayload = {
  id: string;
  to: Location;
};

export type PropsNotificationBarMemo = {
  notification: NotificationReference;
  omit: boolean;
};

export function useNotificationBarMemo(
  props: PropsNotificationBarMemo,
  emit: (name: string, args: NotificationBarMemoClickPayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.notificationBarMemo' });

  const md = mi({ ...BASE_MARKDOWN_OPTION })
    .use(miAnchor)
    .use(miEmoji)
    .use(miRuby);

  function toLabel(body: string, limit: number) {
    let innerHTML: Optional<string>;
    if (body) {
      const [firstLine] = body.split('\n');
      const [inline] = md
        .parse(firstLine, {})
        .filter((item) => item.type === 'inline' && item.children?.some((child) => child.content));
      const tokens = inline?.children?.filter((item) => !EXCLUDE_TOKEN_TYPES.includes(item.type));
      if (tokens && tokens.length > 0) {
        innerHTML = md.renderer.render(tokens, {}, {});
      }
    }
    if (!innerHTML || innerHTML.length < limit) return innerHTML;
    return `${innerHTML.slice(0, limit)}...`;
  }

  function parseCreateGroupMemo(n: NotificationReference) {
    const { payload } = n.asTypeOf<CreateGroupMemoPayload>(n.type);
    const title = toLabel(payload.body, props.omit ? CAPTION_LIMIT_OMIT : CAPTION_LIMIT);
    const description = msgs.of('created', { caption: title ? ` (${title}) ` : '' }).value;
    const group = { id: payload.groupId };
    if (!payload.courseId || !payload.contentId || payload.contentVersion === undefined)
      return { description, group };
    const link = {
      label: payload.contentName,
      to: {
        name: 'groupContentVersion',
        params: {
          id: payload.groupId,
          courseId: payload.courseId,
          contentId: payload.contentId,
          contentVersion: payload.contentVersion.toString(),
        },
        query: { memo: payload.memoId },
      },
    };
    return { description, group, course: payload.courseName, link };
  }

  function parseUpdateGroupMemo(n: NotificationReference) {
    const { payload } = n.asTypeOf<UpdateGroupMemoPayload>(n.type);
    const title = toLabel(payload.body, props.omit ? CAPTION_LIMIT_OMIT : CAPTION_LIMIT);
    const description = msgs.of('updated', { caption: title ? ` (${title}) ` : '' }).value;
    const group = { id: payload.groupId };
    if (!payload.courseId || !payload.contentId || payload.contentVersion === undefined)
      return { description, group };
    const link = {
      label: payload.contentName,
      to: {
        name: 'groupContentVersion',
        params: {
          id: payload.groupId,
          courseId: payload.courseId,
          contentId: payload.contentId,
          contentVersion: payload.contentVersion.toString(),
        },
        query: { memo: payload.memoId },
      },
    };
    return { description, group, course: payload.courseName, link };
  }

  const data = computed(() => {
    const n = props.notification;
    switch (n.type) {
      case CreateGroupMemoNotification:
        return parseCreateGroupMemo(n);
      case UpdateGroupMemoNotification:
        return parseUpdateGroupMemo(n);
      default:
    }
    return undefined;
  });

  function click(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    assertIsDefined(data.value?.link?.to, 'to');
    emit('click', { id: props.notification.id, to: data.value.link.to });
  }

  return { data, click };
}
