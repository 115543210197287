































import { defineComponent, SetupContext } from '@vue/composition-api';

import RefreshButton from '../atoms/RefreshButton.vue';
import BaseDialogConfirm from '../molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '../molecules/BaseDialogFullScreen.vue';
import DialogAnchorConfirm from './DialogAnchorConfirm.vue';
import { PropsDialogNotifications, useDialogNotifications } from './DialogNotificationsComposable';
import Notifications from './Notifications.vue';

type Props = PropsDialogNotifications;

export default defineComponent({
  name: 'BaseDialogNotifications',
  components: {
    BaseDialogFullScreen,
    RefreshButton,
    Notifications,
    BaseDialogConfirm,
    DialogAnchorConfirm,
  },
  props: {
    adminMode: { type: Boolean, default: false },
  },
  emits: ['change-admin-mode'],
  setup(props: Props, { emit }: SetupContext) {
    return useDialogNotifications(props, emit);
  },
});
