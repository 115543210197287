import mi from 'markdown-it';
import Renderer, { RenderRule } from 'markdown-it/lib/renderer';
import Token from 'markdown-it/lib/token';

export default function insertPlugin(md: mi) {
  const defaultRender =
    md.renderer.rules.link_open ||
    function render(
      tokens: Token[],
      idx: number,
      options: mi.Options,
      env: unknown,
      self: Renderer
    ) {
      return self.renderToken(tokens, idx, options);
    };

  const render: RenderRule = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    const href = token.attrGet('href');
    const [first] = href?.split(/[#?]/) ?? [];
    if (first?.indexOf('.') > -1) {
      const ext = first.split('.').pop()?.trim().toLocaleLowerCase();
      if (ext && !ext.endsWith('/')) token.attrJoin('download', '');
    }
    return defaultRender(tokens, idx, options, env, self);
  };

  // eslint-disable-next-line no-param-reassign, @typescript-eslint/camelcase
  md.renderer.rules.link_open = render;
}
