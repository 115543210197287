


























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import BaseSelectSearch from '../atoms/BaseSelectSearch.vue';
import User from '../atoms/User.vue';
import {
  PropsUserSelect,
  UserSelectItem,
  UserSelectValue,
  useUserSelect,
} from './UserSelectComposable';
import UserSelectList from './UserSelectList.vue';

type Props = PropsUserSelect;

export default defineComponent({
  name: 'BaseUserSelect',
  components: { BaseSelectSearch, User, UserSelectList },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Array] as PropType<UserSelectValue>, default: undefined },
    users: { type: Array as PropType<UserSelectItem[]>, default: () => [] },
    roles: {
      type: Array as PropType<GroupRole[]>,
      default: () => ['trainer', 'mentor', 'trainee'],
    },
    multiple: { type: Boolean, default: false },
  },
  emits: ['change', 'submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserSelect(props, emit);
  },
});
