






















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '../atoms/BaseButton.vue';
import { PropsCropper, useCropper } from './CropperComposable';

type Props = PropsCropper;

export default defineComponent({
  name: 'BaseCropper',
  components: { BaseButton },
  props: {
    options: { type: Object as PropType<Cropper.Options<HTMLImageElement>>, required: true },
    maxHeight: { type: String, default: '200px' },
  },
  emits: ['ready'],
  setup(props: Props, { emit }: SetupContext) {
    return useCropper(props, emit);
  },
});
