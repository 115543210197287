






































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import FrameDialogs from './FrameDialogs.vue';
import FrameOverlay from './FrameOverlay.vue';
import { FrameRootValue, PropsFrameRoot, useFrameRoot } from './FrameRootComposable';
import FrameSide from './FrameSide.vue';
import FrameTopProminent from './FrameTopProminent.vue';
import TheErrorToast from './TheErrorToast.vue';

type Props = PropsFrameRoot;

export default defineComponent({
  name: 'BaseFrameRoot',
  components: {
    FrameDialogs,
    FrameOverlay,
    FrameSide,
    FrameTopProminent,
    TheErrorToast,
  },
  props: {
    loading: { type: Boolean, default: false },
    notFound: { type: Boolean, default: false },
    frame: { type: Object as PropType<FrameRootValue>, required: true },
    visible: { type: Boolean, default: false },
    inPreparation: { type: Boolean, default: false },
    label: { type: String, default: undefined },
    showProminentExtension: { type: Boolean, default: false },
    showSwitchMobile: { type: Boolean, default: false },
    hideTop: { type: Boolean, default: false },
    hideSide: { type: Boolean, default: false },
    hideReturn: { type: Boolean, default: false },
    hideNotifications: { type: Boolean, default: false },
    appBarImage: { type: String, default: undefined },
    appBarClass: { type: String, default: undefined },
  },
  emits: ['change-frame'],
  setup(props: Props, { emit }: SetupContext) {
    return useFrameRoot(props, emit);
  },
});
