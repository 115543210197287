import { computed, onUnmounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery, DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupId, Questionnaire, QuestionnaireId, Subscription } from '@/base/domains';
import { Optional } from '@/base/types';
import {
  isFailed,
  useCreateQuestionnaire,
  useFinishQuestionnaire,
  useGetQuestionnaire,
  useRemoveQuestionnaire,
  useSubscribeQuestionnaireStatusChanged,
} from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { BaseDialogConfirm } from '../molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '../molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '../molecules/ErrorMessagesComposable';
import {
  QuestionnaireConfirmFinishDialog,
  QuestionnaireConfirmFinishDialogSubmitPayload,
} from '../molecules/QuestionnaireConfirmFinishDialogComposable';
import { QuestionnaireFormValue } from '../molecules/QuestionnaireFormComposable';
import {
  QuestionnaireProgressClickAnchorPayload,
  QuestionnaireProgressRespondent,
} from '../molecules/QuestionnaireProgressComposable';
import { DialogAnchorConfirm } from './DialogAnchorConfirmComposable';

export type DialogQuestionnaireCreateTarget = {
  groupId: GroupId;
};

export type DialogQuestionnaireReferTarget = {
  id: QuestionnaireId;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function toast(messages?: string[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showToast(messages);
  }
  return { dialogFullScreen, dialog, opened, error, toast };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useQuestionnaireConfirmFinishDialog() {
  const confirmFinishDialog = ref<QuestionnaireConfirmFinishDialog>();
  function open() {
    assertIsDefined(confirmFinishDialog.value);
    confirmFinishDialog.value.open();
  }
  return { confirmFinishDialog, open };
}

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: QuestionnaireProgressClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useSubscriptionQuestionnaire(
  onChange: (id: string) => void,
  onError: (errors: ErrorMessage[]) => void
) {
  let subscription: Optional<Subscription>;

  const subscribeQuestionnaire = useSubscribeQuestionnaireStatusChanged();
  async function subscribe(questionnaireId: QuestionnaireId) {
    const res = await subscribeQuestionnaire.execute({
      questionnaireId,
      onChange: () => onChange(questionnaireId),
    });
    if (isFailed(res)) {
      onError(res.errors);
      return;
    }
    subscription = res.subscription;
  }

  function unsubscribe() {
    if (!subscription) return;
    subscription.unsubscribe();
    subscription = undefined;
  }
  onUnmounted(unsubscribe);

  return { subscribe };
}

export function useDialogQuestionnaire() {
  const msgs = useMessages({ prefix: 'base.organisms.dialogQuestionnaire' });
  const { user, findGroup, findUser, checkRole, checkLimitation } = useGlobalStore();
  const route = useRoute();
  const router = useRouter();
  const { getLocation } = useDialogQuery(DialogName.BASE_QUESTIONNAIRE);

  const { dialogFullScreen, dialog, opened, error, toast } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { confirmFinishDialog, open: confirmFinish } = useQuestionnaireConfirmFinishDialog();

  const input = ref<QuestionnaireFormValue>();
  const questionnaire = ref<Questionnaire>();
  const loading = ref(false);
  const groupId = ref<string>();

  const groupUsers = computed(() => {
    if (!user.value || !groupId.value) return [];
    const { id } = user.value;
    return findGroup(groupId.value)?.users.filter((u) => u.id !== id) ?? [];
  });

  function close(finished = false) {
    dialog.value = { display: false };
    questionnaire.value = undefined;
    groupId.value = undefined;
    input.value = undefined;
    const to = clearDialogQuery(route);
    if (!to) return;
    if (finished && to.name === 'home') Object.assign(to.query, { f: '' });
    router.replace(to);
  }

  const getQuestionnaire = useGetQuestionnaire();
  async function fetch(id: QuestionnaireId) {
    loading.value = true;
    const res = await getQuestionnaire.execute({ id });
    loading.value = false;
    if (isFailed(res) || !res.questionnaire) {
      error([msgs.of('noData').value]);
      close();
      return;
    }
    if (res.questionnaire.status === 'finished') {
      error([msgs.of('finished').value]);
      close(true);
      return;
    }
    questionnaire.value = res.questionnaire;
  }

  function validateLimitation(gId: string) {
    return (
      checkRole(['trainer', 'mentor'], gId) === 'enabled' &&
      checkLimitation('questionnaire', false, gId) === 'enabled'
    );
  }

  const { subscribe } = useSubscriptionQuestionnaire(fetch, error);
  async function openRefer(target: DialogQuestionnaireReferTarget) {
    loading.value = true;
    dialog.value = { display: true };
    await fetch(target.id);
    loading.value = false;
    if (!questionnaire.value) return;
    if (!validateLimitation(questionnaire.value.groupId)) {
      close();
      return;
    }
    subscribe(questionnaire.value.id);
  }

  function openCreate(target: DialogQuestionnaireCreateTarget) {
    if (!validateLimitation(target.groupId)) {
      close();
      return;
    }
    assertIsDefined(user.value, 'user');
    dialog.value = { display: true };
    groupId.value = target.groupId;
    input.value = {
      userIds: groupUsers.value.filter((u) => u.role === 'trainee').map((u) => u.id) ?? [],
      title: msgs.of('defaultTitle', { name: user.value.name }).value,
      text: '',
      options: ['', ''],
    };
  }

  const createQuestionnaire = useCreateQuestionnaire();
  async function submit() {
    const id = groupId.value;
    assertIsDefined(id, 'groupId');
    assertIsDefined(input.value, 'input');
    const { userIds, title, text, options } = input.value;
    confirm(msgs.of('confirmStartQuestionnaire').value, async () => {
      toast();
      loading.value = true;
      const res = await createQuestionnaire.execute({ groupId: id, userIds, title, text, options });
      if (isFailed(res)) {
        loading.value = false;
        error(res.errors);
        close();
        return;
      }
      loading.value = false;
      const { questionnaire: q } = res;
      router.replace(getLocation({ id: q.id }, false));
    });
  }

  const finishQuestionnaire = useFinishQuestionnaire();
  const removeQuestionnaire = useRemoveQuestionnaire();
  async function finish(payload: QuestionnaireConfirmFinishDialogSubmitPayload) {
    const id = questionnaire.value?.id;
    assertIsDefined(id, 'questionnaireId');
    loading.value = true;
    const res = await finishQuestionnaire.execute({ id });
    if (isFailed(res)) {
      payload.done();
      loading.value = false;
      error(res.errors);
      return;
    }
    if (!payload.value) {
      const removeRes = await removeQuestionnaire.execute({ id });
      if (isFailed(removeRes)) {
        payload.done();
        loading.value = false;
        error(removeRes.errors);
        return;
      }
    }
    payload.done();
    loading.value = false;
    close(true);
  }

  const title = computed(() => {
    if (questionnaire.value) return questionnaire.value.title;
    const groupName = user.value?.groups.find((g) => g.id === groupId.value)?.name;
    return groupName ? msgs.of('questionnaire', { groupName }).value : '';
  });
  const subtitle = computed(() =>
    questionnaire.value ? msgs.of('progress').value : msgs.of('create').value
  );

  const respondent = computed<QuestionnaireProgressRespondent[]>(() => {
    if (!questionnaire.value) return [];
    const { userIds, respondent: res } = questionnaire.value;
    return userIds.map((id) => ({
      id,
      name: findUser(id)?.name ?? msgs.of('unknown', { id }).value,
      selectedIndex: res.find((item) => item.userId === id)?.selectedIndex,
    }));
  });

  return {
    dialogFullScreen,
    confirmDialog,
    confirmFinishDialog,
    dialog,
    questionnaire,
    respondent,
    loading,
    groupUsers,
    input,
    title,
    subtitle,
    labelSubmit: msgs.of('submit'),
    labelFinish: msgs.of('finish'),
    openCreate,
    openRefer,
    opened,
    confirmFinish,
    close,
    submit,
    finish,
    toast,
    ...useAnchorDialog(),
  };
}

export type DialogQuestionnaire = ReturnType<typeof useDialogQuestionnaire>;
