import { Optional } from '@/base/types';

export function escapeRegExp(str: string) {
  return str.replace(/[.*+?^=!:${}()|[\]/\\]/g, '\\$&');
}

type ReplaceAtExtra = {
  space: boolean;
  enter: boolean;
};

export function replaceAt(
  base: Optional<string>,
  str: string,
  start = 0,
  end = 0,
  extra: ReplaceAtExtra = { space: true, enter: false }
) {
  const a = base?.slice(0, start) ?? '';
  let addText = '';
  if (extra.space && a && a.match(/\S$/)) addText = ' ';
  if (extra.enter && a && !a.match(/\r?\n$/)) addText = '\n';
  const b = `${a}${addText}${str}`;
  return { value: `${b}${base?.slice(end) ?? ''}`, index: b.length };
}
